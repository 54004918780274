import { PatientCohortMatchWorkflowState } from '@tempus/stateflow-types';
import {
  PATIENT_TRACKER_STATUS_CATEGORIES,
  PATIENT_TRACKER_STATUS_ROLLUP,
} from '@tempus/t-shared/src/constants/patient-tracker';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { PatientTrackingDetails, StoreKeys, TimePatient } from '~/store/patientTrackerCommons/types';
import { creators as v2Creators } from '~/store/patientTrackerV2';
import { creators as v3Creators } from '~/store/patientTrackerV3';

import InactiveMatchesGroup from './InactiveMatchesGroup.tsx';
import useStyles from './InactiveMatchesTab.styles';

interface InactiveMatchesTabProps {
  storeKeys: StoreKeys;
  patient: TimePatient;
  setUpdatingTrialMatch?: (ptd: PatientTrackingDetails) => void;
}

export default ({ storeKeys, patient, setUpdatingTrialMatch }: InactiveMatchesTabProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [groupedMatches, setGroupedMatches] = React.useState({});
  const creators = storeKeys.store === 'patientTrackerV2' ? v2Creators : v3Creators;

  useEffect(() => {
    const matchesToDisplay = patient.patientTrackingDetails.filter(
      (ptd) =>
        [
          ...PATIENT_TRACKER_STATUS_ROLLUP[PATIENT_TRACKER_STATUS_CATEGORIES.INACTIVE],
          ...PATIENT_TRACKER_STATUS_ROLLUP[PATIENT_TRACKER_STATUS_CATEGORIES.ENROLLED],
          PatientCohortMatchWorkflowState.NO_LONGER_A_CANDIDATE,
          PatientCohortMatchWorkflowState.ENROLLED,
        ].includes(ptd.status) && !ptd.editing,
    );

    const updatedGroupedMatches = {};

    for (const match of matchesToDisplay) {
      if (match.status in updatedGroupedMatches) {
        updatedGroupedMatches[match.status].push(match);
      } else {
        updatedGroupedMatches[match.status] = [match];
      }
    }

    setGroupedMatches(updatedGroupedMatches);
  }, [patient.patientTrackingDetails]);

  const editMatch = (workflowId: string) => {
    const timePatientWithEditingFlag = {
      ...patient,
      patientTrackingDetails: [
        ...patient.patientTrackingDetails.filter((ptd) => ptd.workflowId !== workflowId),
        ...patient.patientTrackingDetails
          .filter((ptd) => ptd.workflowId === workflowId)
          .map((ptd) => ({ ...ptd, editing: true })),
      ],
    };

    dispatch(creators.setTimePatientData(storeKeys.stateMapKey, timePatientWithEditingFlag));
    setUpdatingTrialMatch &&
      setUpdatingTrialMatch(patient.patientTrackingDetails.find((ptd) => ptd.workflowId === workflowId)!);
  };

  return (
    <div className={classes.container}>
      {Object.keys(groupedMatches).map((status) => (
        <InactiveMatchesGroup key={status} matches={groupedMatches[status]} editMatch={editMatch} />
      ))}
    </div>
  );
};

import { SingleDatePicker } from '@tcl-boron-prefabs/single-date-picker';
import typography from '@tcl-boron-styles/typography/dist/index.module.scss';
import cn from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import InputFieldHelperText from '~/components/UpdateOverlay/DatePickerHelperText';
import {
  dateRuleNotInFuture,
  FieldStatus,
  getChangedValues,
  getFormattedDateForPicker,
  validateDateByRules,
} from '~/components/UpdateOverlay/utils';
import { TrialMatchUpdates } from '~/store/api/types';
import { SyncedFields } from '~/store/patientTrackerCommons/types';

import useStyles from './styles';

interface PreconsentSectionProps {
  preconsentDate: string;
  syncedFields: SyncedFields;
  setSyncedFields: (syncedFields: SyncedFields) => void;
  handleChanges: (changes: Partial<TrialMatchUpdates>) => void;
  setAreRequiredFieldsMissing: (areRequiredFieldsMissing: boolean) => void;
}

export const PreconsentSection: React.FC<PreconsentSectionProps> = ({
  preconsentDate,
  syncedFields,
  setSyncedFields,
  handleChanges,
  setAreRequiredFieldsMissing,
}) => {
  const classes = useStyles();
  const selectedPreconsentDate = syncedFields.preconsentDate;
  const [showPreconsentFields, setShowPreconsentFields] = useState(Boolean(selectedPreconsentDate));

  const setSelectedPreconsentDate = (date: string) => {
    setSyncedFields({ ...syncedFields, preconsentDate: date });
  };

  const validatePreconsentDate = (date: string) => validateDateByRules(date, [dateRuleNotInFuture]);

  const [preconsentDateValidation, setPreconsentDateValidation] = useState(
    validatePreconsentDate(selectedPreconsentDate || ''),
  );

  const getInitialValues = () => {
    return {
      preconsentDate: preconsentDate ? new Date(preconsentDate).toISOString() : null,
    };
  };

  useEffect(() => {
    const currentValues: Partial<TrialMatchUpdates> = {
      preconsentDate: selectedPreconsentDate ? new Date(selectedPreconsentDate).toISOString() : null,
    };

    const changedValues: Partial<TrialMatchUpdates> = getChangedValues(getInitialValues(), currentValues);
    handleChanges(changedValues);
    const selectedPreconsentDateValidation = validatePreconsentDate(selectedPreconsentDate || '');
    setPreconsentDateValidation(selectedPreconsentDateValidation);
    setAreRequiredFieldsMissing(Boolean(selectedPreconsentDate) && !selectedPreconsentDateValidation.passed);
  }, [selectedPreconsentDate]);

  return (
    <>
      {!showPreconsentFields ? (
        <div className={cn(typography.link, classes.subsequentRow)} onClick={() => setShowPreconsentFields(true)}>
          + Add a pre-screening consent date if applicable
        </div>
      ) : (
        <div className={cn(classes.sectionCard, classes.subsequentRow, classes.twoColumns)}>
          <div>
            <SingleDatePicker
              label="Pre-screening consent date"
              status={selectedPreconsentDate ? preconsentDateValidation.status : FieldStatus.DEFAULT}
              onChange={(date) => setSelectedPreconsentDate(getFormattedDateForPicker(date.dateString))}
              value={{ dateString: moment.utc(selectedPreconsentDate).format('MM/DD/YYYY') }}
            />
            {selectedPreconsentDate ? (
              <InputFieldHelperText
                status={preconsentDateValidation.status}
                message={preconsentDateValidation.message}
              />
            ) : null}
          </div>
        </div>
      )}
    </>
  );
};

export default PreconsentSection;

import typography from '@tcl-boron-styles/typography/dist/index.module.scss';
import { Activity, NewTrialMatch } from '@tempus/t-shared';
import cn from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '~/store';
import { StoreKeys } from '~/store/patientTrackerCommons/types';
import { getArmNameToDisplayInActivity } from '~/utils/arm';
import { getTrialDisplay } from '~/utils/trial';

import useStyles from './ActivityStatus.styles';

export const NewTrialMatchActivity = ({ storeKeys, activity }: { storeKeys: StoreKeys; activity: Activity }) => {
  const classes = useStyles();
  const content = activity.content as NewTrialMatch;

  const ptds = useSelector(
    ({ [storeKeys.store]: patientTracker }: RootState) =>
      patientTracker[storeKeys.stateMapKey].patient.data?.patientTrackingDetails,
  );

  return (
    <>
      <li key={activity.id} className={cn(typography.body, classes.li)}>
        <div className={classes.listItemContainer}>
          <div className={classes.updatesLeft}>
            New match to{' '}
            <strong>
              {getTrialDisplay(content.trialId, ptds || [])}
              {getArmNameToDisplayInActivity(content.armId, ptds || [])}
            </strong>
            {'. '}
            {content.screeningNote ? <br /> : null}
            {content.screeningNote}
          </div>
          <div>{activity.createdAt}</div>
        </div>
      </li>
    </>
  );
};

import { AccentPalette, GrayscalePalette } from '@tcl-boron-colors/colors';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  notesTab: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingTop: '0px',
  },
  notes: {
    flex: 1,
    minHeight: 'calc(100vh - 326px)',
    maxHeight: 'calc(100vh - 326px)',
    backgroundColor: GrayscalePalette[200],
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
    paddingTop: '24px',
    paddingRight: '24px',
    paddingLeft: '24px',
    overflowY: 'auto',
    zIndex: 9999,
  },
  addNoteContainer: {
    paddingTop: '12px',
    height: 152,

    '& textarea': {
      width: '95%',
      height: '50px',
      marginBottom: '10px',
    },
  },
  textAreaResponseRequired: {
    '& textarea': {
      border: `1px solid ${AccentPalette[100]}`,
    },
  },
  addNoteBtnContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  toggleContainer: {
    borderBottom: `1px solid ${GrayscalePalette[400]}`,
    '& label': {
      paddingBottom: '0px',
    },
  },
  warning: {
    display: 'flex',
    alignItems: 'center',
    color: `${AccentPalette[100]}`,
    '& svg': {
      marginRight: '5px',
    },
    '& p': {
      margin: '0px 0px',
    },
  },
  note: {
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    minHeight: '85px',
    padding: '16px 16px 16px 16px',
    marginBottom: '16px',
    backgroundColor: 'white',
    borderRadius: '10px',
    border: `1px solid ${GrayscalePalette[300]}`,
  },
  noteHeader: {
    display: 'flex',
  },
  noteSource: {
    fontWeight: 600,
  },
  noteCreatedDate: {
    margin: '0px 0px',
  },
  headerLeft: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  noteIcon: {
    marginRight: '10px',
  },
  noteText: {
    marginTop: '0px important!',
    flex: 1,
  },
  noteBody: {
    display: 'flex',
    flexDirection: 'column',
  },
  activitiesLoader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

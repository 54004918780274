import { Tab } from '@tcl-boron-prefabs/tab';
import { TabGroup } from '@tcl-boron-prefabs/tab-group';
import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '~/store';
import { PatientTrackerStateId, StoreKeys } from '~/store/patientTrackerCommons/types';

import { useTabGroupStyles } from './styles';

interface PatientTrackerStatusTabGroupProps {
  storeKeys: StoreKeys;
  siteId: string;
  tabs: { title: string; count: number | null }[];
  value: string;
  onChange: (value: string) => void;
}

const PatientTrackerStatusTabGroup: React.FC<PatientTrackerStatusTabGroupProps> = ({
  storeKeys,
  // siteId,
  tabs,
  value,
  onChange,
}) => {
  const tabGroupStyles = useTabGroupStyles();
  const stateId = storeKeys.stateMapKey;
  const fetchingRecords = useSelector(
    ({ [storeKeys.store]: patientTracker }: RootState) => patientTracker[stateId].fetchingRecords,
  );

  return (
    <>
      <div className={tabGroupStyles.tabGroupWrapper}>
        {!fetchingRecords && (
          <TabGroup value={value} onChange={onChange}>
            {tabs.map(({ title, count }) => {
              return (
                <Tab key={title} value={title}>
                  {title} {typeof count === 'number' && !isNaN(count) ? `(${count})` : ''}
                </Tab>
              );
            })}
          </TabGroup>
        )}
      </div>

      {stateId !== PatientTrackerStateId.TRIAL_DOCUMENTS_PAGE_PATIENTS && (
        <div className={tabGroupStyles.bottomBorder} />
      )}
    </>
  );
};

export default PatientTrackerStatusTabGroup;

import typography from '@tcl-boron-styles/typography/dist/index.module.scss';
import { Activity, PatientVisit } from '@tempus/t-shared';
import cn from 'classnames';
import { lowerCase } from 'lodash';
import React from 'react';

import { VisitType } from '~/store/patientTrackerCommons/constants';

import useStyles from './ActivityStatus.styles';

export const PatientVisitActivityOccurred = ({ activity }: { activity: Activity }) => {
  const classes = useStyles();
  const content = activity.content as PatientVisit;
  const visitTypeToDisplay = content.visitType === VisitType.OTHER ? 'Visit' : content.visitType;

  return (
    <>
      <li className={cn(typography.body, classes.li)}>
        <div className={classes.listItemContainer}>
          <div className={classes.updatesLeft}>Patient {lowerCase(visitTypeToDisplay)} scheduled for today.</div>
          <div>{activity.createdAt}</div>
        </div>
      </li>
    </>
  );
};

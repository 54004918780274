import { ActionPalette, GrayscalePalette } from '@tcl-boron-colors/colors';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  blackText: {
    color: GrayscalePalette[700],
  },
  cardContent: {
    padding: '24px',
  },
  cohortContainer: {
    margin: '20px 0',
    marginBottom: '20px',
    '&:last-child': {
      marginBottom: '56px',
    },
  },
  subtextAttribute: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginTop: '5px',
  },
  subtextAttributeKey: {
    marginRight: '8px',
    color: GrayscalePalette[500],
    flex: 1,
  },
  subtextAttributeValue: {
    flex: 2,
  },
  badge: {
    width: 'auto',
    marginTop: '8px',
    marginBottom: '3px',
  },
  footer: {
    position: 'absolute',
    bottom: '0',
    width: '100%',
    backgroundColor: GrayscalePalette[200],
    borderRadius: '0 0 10px 10px',
  },
  footerButtons: {
    display: 'flex',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '16px',
    width: '100%',
  },
  footerButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '64px',
    cursor: 'pointer',
    textAlign: 'center',
    padding: '0 8px',
  },
  halfWidth: {
    flex: '0 0 50%',
    width: '50%',
    boxSizing: 'border-box',
    '&:last-child': {
      borderLeft: `1px solid ${ActionPalette[200]}`,
    },
  },
  fullWidth: {
    flex: '0 0 100%',
    width: '100%',
  },
  trialId: {
    display: 'flex',
    alignItems: 'center',
  },
  trialIdTooltip: {
    paddingLeft: '2px',
  },
});

export const customCard = createUseStyles({
  root: {
    position: 'relative',
    padding: '0',
    color: ActionPalette[600],
    backgroundColor: GrayscalePalette[100],
    width: '30%',
    height: 'auto',
    flexGrow: 1,
    overflowY: 'visible',
    maxWidth: '375px',
  },
  cardContentRoot: {
    overflow: 'unset !important',
  },
  cardContentWrapper: {
    overflowY: 'visible',
  },
});

import { createDocumentSagas, createContactSagas } from '@tempus/t-shared/ui';

import api from './api';
import { sagas as documentEditSagas } from './documentEdit';
import { sagas as enchanceUserSagas } from './enhanceUser';
import { sagas as patientTrackerV2Sagas } from './patientTrackerV2';
import { sagas as patientTrackerV3Sagas } from './patientTrackerV3';
import { sagas as siteSagas } from './site';
import { sagas as trialSagas } from './trial';
import { sagas as trialSearchSagas } from './trialSearch';

const documentSagas = createDocumentSagas({
  getSiteDocuments: api.documents.getSite,
  getTrialDocuments: api.documents.getTrial,
});

const contactSagas = createContactSagas(api.contact);

export default [
  ...trialSearchSagas,
  ...trialSagas,
  ...documentSagas,
  ...siteSagas,
  ...patientTrackerV2Sagas,
  ...patientTrackerV3Sagas,
  ...documentEditSagas,
  ...contactSagas,
  ...enchanceUserSagas,
];

import typography from '@tcl-boron-styles/typography/dist/index.module.scss';
import { Activity, PatientStatusUpdate } from '@tempus/t-shared';
import cn from 'classnames';
import React from 'react';

import useStyles from './ActivityStatus.styles';

export const PatientStatusUpdateActivity = ({ activity }: { activity: Activity }) => {
  const classes = useStyles();

  return (
    <>
      <li key={activity.id} className={cn(typography.body, classes.li)}>
        <div className={classes.listItemContainer}>
          <div className={classes.updatesLeft}>
            Patient status updated to <em>{(activity.content as PatientStatusUpdate).newStatus}</em>
          </div>
          <div>{activity.createdAt}</div>
        </div>
      </li>
    </>
  );
};

import React from 'react';

import trialMatchCardStyles from './TrialMatchCard.styles';

interface NoActiveTrialMatchesCardProps {
  showInactiveMatches: () => void;
}

export const NoActiveTrialMatchesCard: React.FC<NoActiveTrialMatchesCardProps> = ({ showInactiveMatches }) => {
  const classes = trialMatchCardStyles();

  return (
    <div className={classes.noActiveTrialMatches}>
      Patient has no trial matches they are being considered for.{` `}
      <span className={classes.inactiveCardLink} onClick={showInactiveMatches}>
        View inactive matches
      </span>
    </div>
  );
};

export default NoActiveTrialMatchesCard;

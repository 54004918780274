import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  sidebarContainer: {
    fontFamily: 'IBM Plex Sans',
  },
  sectionHeader: {
    fontFamily: 'inherit',
    marginBottom: '16px',
  },
  dropdown: {
    fontSize: 13,
    width: 'max-width',
    marginBottom: '20px',
  },
  filterButtons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  matchStatusFlag: {
    position: 'absolute',
    marginTop: '-20px', // to replace label with subContent of DropdownOption
    width: 'auto',
  },
});

import { SingleSelectTypeahead } from '@tcl-boron-prefabs/single-select-typeahead';
import typography from '@tcl-boron-styles/typography/dist/index.module.scss';
import { storeActions } from '@tempus/t-shared/ui';
import cn from 'classnames';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DropdownOption } from 'tcl-v3/models';

import { RootState } from '~/store';
import api from '~/store/api';
import { StoreKeys, TimePatient } from '~/store/patientTrackerCommons/types';

import useStyles from './PatientDetailsTab.styles';

interface PatientDetailsTabProps {
  storeKeys: StoreKeys;
  timePatient: TimePatient;
  onPhysicianUpdate: () => void;
}
export default ({ storeKeys: { stateMapKey }, timePatient, onPhysicianUpdate }: PatientDetailsTabProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const patientTempusId = timePatient.patient.id;
  const billingId = timePatient.billingId;

  const { currentInstitutionPhysicians } = useSelector((state: RootState) => state.patientTrackerV3[stateMapKey]);
  const physicianOpts = currentInstitutionPhysicians?.map(({ id, name }) => ({ label: name, value: id })) || [];

  const [filteredPhysicianOptions, setFilteredPhysicianOptions] = useState(physicianOpts);
  const [physicianInput, setPhysicianInput] = useState('');
  const filterOptions = (
    input: string,
    options: DropdownOption[],
    setFilteredOptions: React.Dispatch<React.SetStateAction<DropdownOption[]>>,
  ) => {
    setFilteredOptions(options.filter((option) => option.label.toLowerCase().includes(input.toLowerCase())));
  };

  const selectedPhysician = timePatient.physician;

  const [physician, setPhysician] = React.useState<DropdownOption | null>(
    selectedPhysician ? { label: selectedPhysician.name, value: selectedPhysician.id } : null,
  );

  const updatePhysician = async (physicianOpt: DropdownOption | null) => {
    setPhysician(physicianOpt);

    try {
      await api.timePatient.updateTimePatientV2(timePatient.id, {
        physicianId: physicianOpt ? physicianOpt.value : null,
      });
      dispatch(storeActions.notification.showSuccessMessage('Physician updated'));
      onPhysicianUpdate();
    } catch (error) {
      dispatch(storeActions.notification.showErrorMessage('Failed to update physician'));
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.containerInner}>
        {patientTempusId && (
          <div className={classes.detailGroup}>
            <div>
              <div className={classes.label}>Tempus ID</div>
              <div>{patientTempusId}</div>
            </div>
          </div>
        )}
        {billingId && (
          <div className={classes.detailGroup}>
            <div>
              <div className={classes.label}>Billing ID</div>
              <div>{billingId}</div>
            </div>
          </div>
        )}
        <div className={classes.physicianContainer}>
          <div className={classes.physicianDropdown}>
            <SingleSelectTypeahead
              label="Physicians"
              data-testid="update-overlay-physician"
              onChange={updatePhysician}
              options={filteredPhysicianOptions}
              value={physician}
              inputValue={physicianInput}
              onInputValueChange={(input) => setPhysicianInput(input)}
              onDebounceChange={(input: string) => filterOptions(input, physicianOpts, setFilteredPhysicianOptions)}
              clearable={true}
            />
          </div>

          <div
            className={cn(typography.link, classes.clearPhysician)}
            onClick={() => {
              updatePhysician(null);
            }}>
            Clear
          </div>
        </div>
      </div>
    </div>
  );
};

import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Information } from 'tcl-v3/icons';
import { Loader, Tooltip } from 'tcl-v3/prefabs';
import typography from 'tcl-v3/styles/typography.module.scss';

import TitleHeader from '~/components/Header';
import api from '~/store/api';
import { ExternalTrial } from '~/store/trial/types';
import { ctgovUrl } from '~/utils/trial';

import useStyles from './styles';

type HeaderProps = RouteComponentProps & {
  trialId: string;
  trialData?: {
    isLoading?: boolean;
    error?: Error | null;
    data?: ExternalTrial;
  };
};

const Header: React.FC<HeaderProps> = ({ trialId, trialData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { isLoading, error, data: trial } = !trialData
    ? useQuery({
        queryKey: ['trial', trialId],
        queryFn: () => api.trials.getTrial(trialId),
      })
    : trialData;
  const classes = useStyles();

  if (isLoading) {
    return <Loader />;
  }
  if (error || !trial) {
    return null;
  }

  return (
    <>
      <div className={`${classes.headerContainer}`}>
        <TitleHeader headerTitle={trial.shortName || trial.title || ''} />
        <div className={`${typography.sectionHeader}`}>
          {trial.nctId ? (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={ctgovUrl(trial.nctId)}
              data-pendo-id="document-header-nct-id"
              style={{ color: 'inherit', fontWeight: 'bold' }}
              className={`${typography.supportingBody}`}>
              {trial.nctId}
            </a>
          ) : (
            <div className={classes.tempusTrialId}>
              <p className={typography.body}>{trial.tempusTrialId}</p>

              <Tooltip isOpen={isOpen} position="below-center">
                {{
                  tooltip: `This trial has a temporary Tempus ID
                  until an NCTID is available`,
                  base: <Information onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)} />,
                }}
              </Tooltip>
            </div>
          )}
          {Boolean(trial.shortName) && (
            <div className={`${typography.supportingBody} ${classes.fullTrialTitle}`}>{trial.title}</div>
          )}{' '}
        </div>
      </div>
    </>
  );
};

export default withRouter(Header);

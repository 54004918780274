import { Button } from '@tcl-boron-prefabs/button';
import { MaxPageWidth, NotAvailable, ScreenEmptyImage } from '@tempus/t-shared/ui';
import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '~/store';
import { PATIENT_TRACKER_V3_TAB } from '~/store/patientTrackerCommons/constants';
import { openPatientTrackerPage } from '~/store/patientTrackerCommons/helpers';
import { PatientTrackerStateId, StoreKeys } from '~/store/patientTrackerCommons/types';

const NoPatients: React.FC<{ storeKeys: StoreKeys; siteId: string }> = ({
  storeKeys: { store, stateMapKey },
  siteId,
}) => {
  const { tab } = useSelector((state: RootState) => state[store][stateMapKey]);
  const title =
    tab === PATIENT_TRACKER_V3_TAB.VISITING_THIS_WEEK ? 'No remaining visits for the week' : 'No patients yet';

  let description;
  switch (tab) {
    case PATIENT_TRACKER_V3_TAB.VISITING_THIS_WEEK:
      description = (
        <Button
          buttonType="secondary"
          small
          onClick={() =>
            openPatientTrackerPage(
              siteId,
              PatientTrackerStateId.ALL_PATIENTS_PAGE,
              'sortOrder=UPCOMING_VISIT_SOONEST_FIRST&tab=All',
            )
          }
          ariaLabel={'View more upcoming visits'}>
          View more upcoming visits
        </Button>
      );
      break;
    default:
      description = (
        <Button
          buttonType="secondary"
          small
          onClick={() => openPatientTrackerPage(siteId, PatientTrackerStateId.ALL_PATIENTS_PAGE, 'tab=All')}
          ariaLabel={'See all my patients'}>
          See all my patients
        </Button>
      );
  }

  return (
    <MaxPageWidth>
      <NotAvailable title={title} Image={ScreenEmptyImage} description={description} />
    </MaxPageWidth>
  );
};

export default NoPatients;

import { matchPath } from 'react-router-dom';

const TRIAL_DOCUMENTS = '/trials/:trialId/documents';
const TRIALS = '/trials';
const TRIAL_SEARCH = '/trials/trialSearch';
const TRIAL_MATERIALS = '/trials/trialMaterials';
const SITE_DOCUMENTS = '/sites/:siteId?/documents';
const PATIENT_TRACKER = '/patient-tracker';
const SITE_PATIENT_TRACKER = '/patient-tracker/sites/:siteId?/:nctId?/:patientFullName?';
const MATCH_REVIEW = '/match-review/:siteId?';
const ALL_PATIENTS = '/all-patients/:siteId?';
const TEMPUS_NURSE = '/tempus-nurse/:siteId?';

const paths = {
  TRIAL_DOCUMENTS,
  TRIALS,
  TRIAL_SEARCH,
  TRIAL_MATERIALS,
  SITE_DOCUMENTS,
  PATIENT_TRACKER,
  SITE_PATIENT_TRACKER,
  MATCH_REVIEW,
  ALL_PATIENTS,
  TEMPUS_NURSE,
};

export const checkPath = (url: string, path: string) =>
  matchPath(url, {
    path,
    exact: true,
    strict: false,
  });

export default paths;

import { ActionPalette, BrandPalette, GrayscalePalette, SuccessPalette } from '@tcl-boron-colors/colors';
import { createUseStyles } from 'react-jss';

export const useCardStyles = createUseStyles({
  // to show the note icon popover fully above the table row
  cardContentRoot: {
    overflow: 'visible',
  },
  cardContentWrapper: {
    overflow: 'visible',
  },
});

export const useStyles = createUseStyles({
  card: {
    marginTop: '16px',
    padding: '0px',
    '& > div > div': {
      padding: '0',
    },
    color: ActionPalette[600],
  },
  twoColumnContainer: {
    display: 'flex',
    height: '100%',
  },
  header: {
    padding: '24px 24px 16px 24px',
    background: 'white',
  },
  chevron: {
    marginTop: '3px',
    marginRight: '10px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  title: {
    maxWidth: 'calc(100% - 24px)',
    wordWrap: 'break-word',
    textTransform: 'uppercase',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: '2px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  status: {
    fontSize: '12px',
    lineHeight: '14px',
    marginLeft: '10px',
    padding: '5px 10px',
    borderRadius: '12px',
  },
  statusTooltip: {
    width: 'auto',
    height: 'auto',
  },
  patientNameContainer: {
    marginRight: '16px',
    display: 'flex',
    flexWrap: 'wrap',
  },
  statusContainer: {
    width: '310px',
    height: '16px',
  },
  right: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  mainSection: {
    flexGrow: 1,
  },
  cardBody: {
    background: GrayscalePalette[200],
    boxShadow: `0 0 1px 0 ${GrayscalePalette[400]}`,
  },
  upcomingVisit: {
    marginRight: '16px',
  },
  updateButton: {
    marginTop: '-6px',

    '&:focus': {
      border: `2px solid ${ActionPalette[600]}`,
    },
  },
  updateDue: {
    marginBottom: '6px',
    width: 'auto !important',
  },
  trialName: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  headerTable: {
    width: '100%',
    padding: '0 24px 16px 24px',
    tableLayout: 'fixed',
    backgroundColor: 'white',
  },
  table: {
    width: '100%',
    padding: '16px 24px 16px 24px',
    tableLayout: 'fixed',
  },
  th: {
    textAlign: 'left',
  },
  td: {
    paddingTop: '16px',
    alignContent: 'start',
  },
  ptDetails: {
    verticalAlign: 'top',
  },
  viewAll: {
    padding: '0 0 16px 24px',
  },
  matchStatus: {
    marginTop: '-4px',
    marginLeft: '4px',
    fontSize: '14px',
    fontFamily: 'IBM Plex Sans',
    lineHeight: '20px',
    fontWeight: 400,
  },
  dot: {
    height: '8px',
    width: '8px',
    borderRadius: '50%',
    display: 'inline-block',
  },
  tooltipContainer: {
    marginRight: '50px',
    marginBottom: '16px',
  },
  noteSection: {
    wordWrap: 'break-word',
    padding: '0 0 0 0',
  },
  noteFromTitle: {
    margin: '12px 0',
  },
  noteContent: {
    margin: 0,
  },
  tooltip: {
    padding: '8px',
    width: 336,
    maxHeight: 336,
    overflow: 'auto',
  },
  noteIcon: {
    marginRight: '20px',
  },
  textArea: {
    marginBottom: '24px',
  },
  sectionHeader: {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
    marginBottom: '8px',
    color: 'rgb(41, 41, 61)',
  },
  sectionText: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 400,
    color: 'black',

    '&:last-child': {
      marginBottom: '24px',
    },
  },
  iconWrapper: {
    position: 'relative',
    display: 'inline-block',
  },
  badge: {
    position: 'absolute',
    top: '-5px',
    right: '15px',
    backgroundColor: SuccessPalette[700],
    color: 'white',
    borderRadius: '50%',
    padding: '2px',
    fontSize: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '10px',
    height: '10px',
  },
  notEditable: {
    marginTop: '2px',
  },
  matchFlagsRow: {
    marginTop: '8px',
  },
  matchStatusFlags: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  matchStatusFlagBadge: {
    minWidth: 'fit-content',
  },
  noActiveMatches: {
    fontWeight: 400,
    fontStyle: 'italic',
    lineHeight: '20px',
    padding: '16px 24px 16px 24px',
    color: `${BrandPalette[400]}`,
    background: GrayscalePalette[200],
    boxShadow: `0 0 1px 0 ${GrayscalePalette[400]}`,
  },
});

export const tooltipComponentClasses = createUseStyles({
  root: { position: 'absolute' },
  tooltip: {
    overflowX: 'hidden',
    overflowY: 'auto',
  },
});

import typography from '@tcl-boron-styles/typography/dist/index.module.scss';
import { ALL_SITES, ROLES } from '@tempus/t-shared';
import React from 'react';
import { useSelector } from 'react-redux';

import DownloadPatients from '~/components/DownloadPatients/v2';
import { RootState } from '~/store';
import { StoreKeys } from '~/store/patientTrackerCommons/types';

import AddPatient from '../AddPatientV2';
import BulkEditMenu from '../AddPatientV2/BulkUpdate';
import { useStyles } from './styles';

interface PatientTrackerHeaderProps {
  storeKeys: StoreKeys;
  title: string;
  siteId: string;
}

const PatientTrackerHeader: React.FC<PatientTrackerHeaderProps> = ({ storeKeys, title }) => {
  const classes = useStyles();
  const { canWritePatientTracking } = useSelector((state: RootState) => state.user);
  const selectedUserSite = useSelector((state: RootState) => state.site.selectedUserSite);
  const canBulkEdit = useSelector((state: RootState) =>
    state.user.effectiveRoles.includes(ROLES.T_PATIENT_TRACKER_BULK_UPLOADER),
  );
  const isAllSitesSelected = selectedUserSite?.name == ALL_SITES;
  return (
    <div className={classes.header}>
      <span className={`${typography.header} ${classes.title}`} data-testid="patient-tracker-heading">
        {title}
      </span>
      <div className={classes.actionButtons}>
        {canWritePatientTracking && !isAllSitesSelected && <AddPatient storeKeys={storeKeys} />}
        <DownloadPatients storeKeys={storeKeys} siteId={selectedUserSite?.id || ALL_SITES} />

        {title === 'Tempus Nurse' && canBulkEdit && <BulkEditMenu />}
      </div>
    </div>
  );
};

export default PatientTrackerHeader;

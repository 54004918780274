import { useQuery } from '@tanstack/react-query';
import { Alert } from '@tcl-boron-prefabs/alert';
import { Badge } from '@tcl-boron-prefabs/badge';
import { Button } from '@tcl-boron-prefabs/button';
import { Tab } from '@tcl-boron-prefabs/tab';
import { TabGroup } from '@tcl-boron-prefabs/tab-group';
import { WarningCircle } from '@tempus/component-library/icons';
import { colors } from '@tempus/component-library/styles';
import {
  DocumentConstants,
  OperationaFlagHoverText,
  OperationalFlags,
  TimeProgramSiteStatus,
  TrialOnHoldDocumentDescription,
} from '@tempus/t-shared';
import { MaxPageWidth, documentActions } from '@tempus/t-shared/ui';
import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useQueryParams, StringParam } from 'use-query-params';

import InternalUsersImpersonateCta from '~/components/InternalUsersImpersonateCta';
import TrialDocumentsHeader from '~/components/PageHeaders/TrialHeader';
import PatientTracker from '~/components/PatientTracker';
import TherapiesDocumentListPage from '~/components/TherapiesDocumentListPage';
import EmailModal from '~/components/UnavailableTrialsDisplay/EmailModal';
import { RootState } from '~/store';
import api from '~/store/api';
import { impersonatedIds } from '~/store/api/axios';
import { PATIENT_TRACKER_V3_TAB } from '~/store/patientTrackerCommons/constants';
import { PatientTrackerFilterField, PatientTrackerStateId } from '~/store/patientTrackerCommons/types';
import { creators as patientTrackerCreators } from '~/store/patientTrackerV3';

import { useStyles } from './styles';

interface RouteMatchParams {
  trialId: string;
}

interface TrialDocumentsParams extends RouteComponentProps<RouteMatchParams>, ConnectedProps<typeof connector> {}

type TrialDocumentsTab = 'Materials' | 'Patients';

const TrialDocuments: React.FC<TrialDocumentsParams> = ({ match, selectedUserSiteId }) => {
  const {
    params: { trialId },
  } = match;
  const { isLoading, error, data: trial } = useQuery({
    queryKey: ['trial', trialId],
    queryFn: () => api.trials.getTrial(trialId),
  });
  const { data: programSite } = useQuery({
    queryKey: ['programSite', trialId, selectedUserSiteId],
    queryFn: () => api.programSites.getProgramSite(selectedUserSiteId as string, trialId),
  });
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();

  const [queryParams, setQueryParams] = useQueryParams({
    documentsPageTab: StringParam,
  });

  const [selectedTab, setSelectedTab] = useState(queryParams.documentsPageTab || 'Materials');
  const [patientCount, setPatientCount] = useState<number | null>(null);
  const siteId = useSelector(({ site }: RootState) => site.selectedUserSite?.id);

  const {
    user: { canImpersonate },
  } = useSelector((state: RootState) => state);

  const { patients, patientsMetadata, categoryCounts } =
    useSelector(({ patientTrackerV3 }: RootState) => patientTrackerV3[PatientTrackerStateId.MATERIALS_PAGE]) || [];

  useEffect(() => {
    dispatch(
      documentActions.getDocuments({
        classification: DocumentConstants.Classification.Trial,
        id: trialId,
        queryParams: { overrideSiteId: selectedUserSiteId },
      }),
    );

    dispatch(
      patientTrackerCreators.setPatientTrackerFilters(PatientTrackerStateId.MATERIALS_PAGE, {
        trialName: { label: '', value: trialId },
      }),
    );

    dispatch(
      patientTrackerCreators.getPatientTrackerCategoryCounts(PatientTrackerStateId.MATERIALS_PAGE, siteId || ''),
    );
  }, [selectedUserSiteId]);

  const updateSelectedTab = (value: TrialDocumentsTab) => {
    setSelectedTab(value);
    setQueryParams({ ...queryParams, documentsPageTab: value });
  };

  useEffect(() => {
    setPatientCount(null);

    if (canImpersonate && !impersonatedIds[0]) {
      return;
    }

    let updatedPatientCount = 0;

    Object.keys(categoryCounts).forEach((key) => {
      if (categoryCounts[key] !== null) {
        updatedPatientCount += categoryCounts[key];
      }
    });

    setPatientCount(updatedPatientCount);
  }, [categoryCounts]);

  const disabledFilters = [
    PatientTrackerFilterField.INDICATIONS,
    PatientTrackerFilterField.TRIAL_STATUS,
    PatientTrackerFilterField.TRIAL_NAME,
    PatientTrackerFilterField.TRIAL_TYPE,
    PatientTrackerFilterField.RN_ASSIGNMENT,
  ];

  const patientTrackerTabs = [
    PATIENT_TRACKER_V3_TAB.VISITING_THIS_WEEK,
    PATIENT_TRACKER_V3_TAB.REVIEW_REQUIRED,
    PATIENT_TRACKER_V3_TAB.CANDIDATE_NOW,
    PATIENT_TRACKER_V3_TAB.TIME_SENSITIVE,
    PATIENT_TRACKER_V3_TAB.ENROLLED,
    PATIENT_TRACKER_V3_TAB.ALL,
  ];

  return (
    <MaxPageWidth>
      <div className={classes.wrapper}>
        <TrialDocumentsHeader trialId={trialId} trialData={{ isLoading, error, data: trial }} />
        {programSite?.status && programSite?.status === TimeProgramSiteStatus.ACTIVATED_OUTSIDE_TIME_DO_NOT_MATCH && (
          <div className={classes.contactContainer}>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <Badge className={classes.badge} text="Active outside of time" badgeType={'secondary'} />
              <Button
                buttonType="secondary"
                onClick={() => setShowModal(true)}
                small
                ariaLabel="Contact your Tempus representative">
                Contact your Tempus representative
              </Button>
              <EmailModal trialId={trialId} isOpen={showModal} closeModal={() => setShowModal(false)} />
            </div>
            <div>Trial matching is not available to your site</div>
          </div>
        )}
        <>
          <TabGroup
            value={selectedTab}
            onChange={(value) => updateSelectedTab(value as TrialDocumentsTab)}
            className={classes.tabGroup}>
            <Tab value={'Materials'}>Materials</Tab>
            <Tab value={'Patients'}>Patients {patientCount !== null && `(${patientCount})`}</Tab>
          </TabGroup>
          <div className={classes.tabGroupBorder} />
          {trial?.TrialPrograms?.[0]?.operationalFlag === OperationalFlags.AMENDMENT && (
            <Alert status="warning" className={classes.overlayWarning}>
              {{
                title: (
                  <>
                    <span className={classes.iconStyles}>
                      <WarningCircle color={colors.orange} />
                    </span>
                    {OperationaFlagHoverText[OperationalFlags.AMENDMENT]}
                  </>
                ),
                content: <div>{TrialOnHoldDocumentDescription[OperationalFlags.AMENDMENT].info}</div>,
              }}
            </Alert>
          )}
        </>
        {selectedTab === 'Materials' && (
          <TherapiesDocumentListPage
            queryParams={{ overrideSiteId: selectedUserSiteId }}
            classificationId={trialId}
            classification={DocumentConstants.Classification.Trial}
          />
        )}
        {selectedTab === 'Patients' && (!canImpersonate || (canImpersonate && impersonatedIds[0])) && (
          <PatientTracker
            storeKeys={{ store: 'patientTrackerV3', stateMapKey: PatientTrackerStateId.MATERIALS_PAGE }}
            siteId={siteId || ''}
            visibleFilters={Object.keys(PatientTrackerFilterField)
              .map((field) => PatientTrackerFilterField[field])
              .filter((field) => !disabledFilters.includes(field))}
            timePatients={patients}
            patientsMetadata={patientsMetadata}
            tabs={patientTrackerTabs}
          />
        )}
        {selectedTab === 'Patients' && canImpersonate && !impersonatedIds[0] && <InternalUsersImpersonateCta />}
      </div>
    </MaxPageWidth>
  );
};
const connector = connect((state: RootState) => {
  return {
    selectedUserSiteId: state.site.selectedUserSite?.id,
  };
}, {});

export default withRouter(connector(TrialDocuments));

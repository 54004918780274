import { OperationalFlags, TimeProgramSiteStatus } from '@tempus/t-shared';

import { GetTrialsBySiteRequest, SearchArm } from '../api/types';

export interface TrialMeta {
  latestDocumentApplicableAt?: string;
}

export interface Trial extends BaseTrial {
  $meta?: TrialMeta;
  updatedAt: string;
  documents?: Document[];
  status: TimeProgramSiteStatus;
}

export interface BaseTrial {
  id: string;
  nctId: string;
  tempusTrialId: string;
  title: string;
  sponsor: string;
  shortName: string | null;
  cohorts: SearchArm[];
}

export interface TrialState {
  allTrials: Trial[];
  trials: Trial[];
  unfilteredCount?: number;
  filteredCount?: number;
  trialOptions: BaseTrial[];
  error: string;
  loading: boolean;
}

export interface ExternalTrial {
  id: string;
  nctId: string;
  tempusTrialId: string;
  title: string | null;
  shortName: string | null;
  TrialPrograms?: { operationalFlag: OperationalFlags.AMENDMENT | null }[];
}

export const GET_ALL_TRIALS = 'GET_ALL_TRIALS';
export const SET_ALL_TRIALS = 'SET_ALL_TRIALS';
export const GET_TRIALS = 'GET_TRIALS';
export const SET_TRIALS = 'SET_TRIALS';
export const SET_TRIALS_META = 'SET_TRIALS_META';
export const GET_TRIALS_FAILED = 'GET_TRIALS_FAILED';

export const GET_TRIAL_OPTIONS = 'GET_TRIAL_OPTIONS';
export const SET_TRIAL_OPTIONS = 'SET_TRIAL_OPTIONS';
export const GET_TRIAL_OPTIONS_FAILED = 'GET_TRIAL_OPTIONS_FAILED';

export const GET_STUDY_GUIDE = 'GET_STUDY_GUIDE';
export const SET_STUDY_GUIDE = 'SET_STUDY_GUIDE';
export const GET_STUDY_GUIDE_FAILED = 'GET_STUDY_GUIDE_FAILED';

interface GetStudyGuideAction {
  type: typeof GET_STUDY_GUIDE;
}
interface GetStudyGuideFailedAction {
  type: typeof GET_STUDY_GUIDE_FAILED;
  error: string;
}
interface SetStudyGuideAction {
  type: typeof SET_STUDY_GUIDE;
  STUDY_GUIDE: string;
}

interface GetAllTrialsAction {
  type: typeof GET_ALL_TRIALS;
}

interface SetAllTrialsAction {
  type: typeof SET_ALL_TRIALS;
  trials: Array<Trial>;
}
interface GetTrialsAction {
  type: typeof GET_TRIALS;
  params?: GetTrialsBySiteRequest;
}
interface GetTrialsFailedAction {
  type: typeof GET_TRIALS_FAILED;
  error: string;
}
interface SetTrialsAction {
  type: typeof SET_TRIALS;
  trials: Array<Trial>;
  unfilteredCount?: number;
  filteredCount?: number;
}
interface SetTrialsMetaAction {
  type: typeof SET_TRIALS_META;
  latestApplicableAtLookup: Record<string, string>;
}

interface GetTrialOptionsAction {
  type: typeof GET_TRIAL_OPTIONS;
}
interface GetTrialOptionsFailedAction {
  type: typeof GET_TRIAL_OPTIONS_FAILED;
  error: string;
}
interface SetTrialOptionsAction {
  type: typeof SET_TRIAL_OPTIONS;
  trialOptions: Array<BaseTrial>;
}

export type TrialActionTypes =
  | GetAllTrialsAction
  | SetAllTrialsAction
  | GetTrialsAction
  | GetTrialsFailedAction
  | SetTrialsAction
  | SetTrialsMetaAction
  | GetTrialOptionsAction
  | GetTrialOptionsFailedAction
  | SetTrialOptionsAction
  | GetStudyGuideAction
  | GetStudyGuideFailedAction
  | SetStudyGuideAction;

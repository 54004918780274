import { getTimeProgramSiteStatusBadgeText, LfsTrialStatus, TimeProgramSiteStatus, Trial } from '@tempus/t-shared';
import { PATIENT_TRACKER_STATUS_CATEGORIES } from '@tempus/t-shared/src/constants/patient-tracker';
import { upperFirst } from 'lodash';
import moment from 'moment';

import { Routes } from '~/routes';

import { GetPatientTrackerRecordsRequestBody } from '../api/types';
import { SiteWithId } from '../site/types';
import { PATIENT_TRACKER_V3_TAB, SORT_OPTION } from './constants';
import { PatientTrackerFilterField, PatientTrackerFilters, PatientTrackerStateId } from './types';

// deprecated
export const getSortDropdownOptionValue = (option: SORT_OPTION): [string, 'ASC' | 'DESC'] => {
  switch (option) {
    case SORT_OPTION.URGENCY:
      return ['urgent', 'ASC'];
    case SORT_OPTION.UPCOMING_VISIT_FURTHEST_FIRST:
      return ['nextVisitDate', 'ASC'];
    case SORT_OPTION.OLDEST:
      return ['createdAt', 'ASC'];
    case SORT_OPTION.NEWEST:
      return ['createdAt', 'DESC'];
    default:
      return ['urgent', 'ASC'];
  }
};

export const parseFilters = (
  filters: Partial<PatientTrackerFilters>,
  customFilterList?: PatientTrackerFilterField[],
) => {
  let orderBy: GetPatientTrackerRecordsRequestBody['orderBy'] = [];
  let filterParams: Partial<Pick<
    GetPatientTrackerRecordsRequestBody,
    | 'trialId'
    | 'physician'
    | 'biomarker'
    | 'patientFullName'
    | 'noteText'
    | 'trialType'
    | 'trialStatus'
    | 'patientId'
    | 'rnAssignment'
  >> = {};

  Object.keys(filters).forEach((key) => {
    if (customFilterList && !customFilterList.includes(key as PatientTrackerFilterField)) {
      return;
    }

    switch (key) {
      case 'trialName':
        filterParams = { ...filterParams, trialId: filters.trialName?.value };
        break;
      case 'trialStatus':
        filterParams = {
          ...filterParams,
          trialStatus: filters.trialStatus?.map((status) => status.value as LfsTrialStatus),
        };
        break;
      case 'trialType':
        filterParams = { ...filterParams, trialType: filters.trialType?.value };
        break;
      case 'physician':
        filterParams = { ...filterParams, physician: filters.physician };
        break;
      // case 'indication':
      //   filterParams = { ...filterParams, indications: filters.indications };
      //   break;
      case 'biomarker':
        filterParams = { ...filterParams, biomarker: filters.biomarker };
        break;
      case 'patientName':
        filterParams = { ...filterParams, patientFullName: filters.patientName };
        break;
      case 'patientId':
        filterParams = { ...filterParams, patientId: filters.patientId };
        break;
      case 'rnAssignment':
        filterParams = { ...filterParams, rnAssignment: filters.rnAssignment };
        break;
      case 'note':
        filterParams = { ...filterParams, noteText: filters.note };
        break;
      case 'sortOrder':
        orderBy = [getSortDropdownOptionValue(filters.sortOrder!.label as SORT_OPTION)];
        break;
      default:
        break;
    }
  });

  return { orderBy, filterParams };
};

const tabConfig = {};

export const getPatientTrackerRecordsRequestOptions = (
  tab: PATIENT_TRACKER_STATUS_CATEGORIES | PATIENT_TRACKER_V3_TAB,
) => ({
  ...tabConfig[tab],
});

export const getTrialNameLabel = (trial?: Pick<Trial, 'shortName' | 'title'>) => {
  if (trial?.shortName) {
    return trial.shortName;
  }

  if (trial?.title && trial?.title?.length > 40) {
    return trial.title.slice(0, 40) + '...';
  }

  return trial?.title || '';
};

export const openTrialMaterials = (trialId: string, site: SiteWithId | null) => {
  if (trialId) {
    // setting in localStorage since Redux gets cleared when opening a new tab
    localStorage.setItem('selectedUserSite', JSON.stringify(site));
    window.open(`${Routes.trialDocuments.path.replace(':trialId', trialId)}`, '_blank');
  }
};

export const getPatientTrackerRoute = (stateId?: PatientTrackerStateId) => {
  switch (stateId) {
    case PatientTrackerStateId.MATCH_REVIEW_PAGE:
      return Routes.matchReviewPage;
    case PatientTrackerStateId.TEMPUS_NURSE_PAGE:
      return Routes.tempusNursePage;
    default:
      return Routes.allPatientsPage;
  }
};

export const openPatientTrackerPage = (siteId: string, patientTracker: PatientTrackerStateId, urlParams?: string) => {
  localStorage.setItem('selectedUserSite', JSON.stringify({ id: siteId }));
  window.open(
    `${getPatientTrackerRoute(patientTracker).path.replace(':siteId', siteId)}stateId=${patientTracker}${
      urlParams ? `&${urlParams}` : ''
    }`,
    '_blank',
  );
};

export const trialStatusToDisplay = (status?: TimeProgramSiteStatus) => {
  if (!status) {
    return '';
  }
  if (
    status === TimeProgramSiteStatus.ACTIVE_TRIAL_SITE ||
    status === TimeProgramSiteStatus.ACTIVATED_OUTSIDE_TIME_MATCH ||
    status === TimeProgramSiteStatus.ACTIVATED_OUTSIDE_TIME_DO_NOT_MATCH
  ) {
    return 'Active at my site';
  }

  return upperFirst(getTimeProgramSiteStatusBadgeText(status).toLowerCase());
};

export const isDatePast = (date: string) => {
  const givenDate = moment(date).startOf('day');
  const currentDate = moment().startOf('day');
  return givenDate.isBefore(currentDate);
};

export const getPatientTrackerV2Tabs = (isInternalUser: boolean) => {
  const categoryTabs = Object.keys(PATIENT_TRACKER_STATUS_CATEGORIES).map(
    (key) => PATIENT_TRACKER_STATUS_CATEGORIES[key],
  );

  if (!isInternalUser) {
    categoryTabs.splice(categoryTabs.indexOf(PATIENT_TRACKER_STATUS_CATEGORIES.SCREENING_QUEUE), 1);
  }

  return categoryTabs;
};

import { createUseStyles } from 'react-jss';

export default createUseStyles({
  scanReminderCard: {
    backgroundColor: 'white',
    color: 'black',
    padding: 16,
    display: 'flex',
    alignItems: 'center',
    borderRadius: 8,
    height: 'fit-content',
    marginBottom: '20px',

    // Card component adds an overflow-y: scroll to this internal div causing a scrollbar area to be visible at all times
    '& > div > div': {
      overflowY: 'hidden',
    },
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: 8,
  },
});

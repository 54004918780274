import { Button } from '@tcl-boron-prefabs/button';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '~/store';
import { StoreKeys } from '~/store/patientTrackerCommons/types';
import { creators as patientTrackerV2Creators } from '~/store/patientTrackerV2';
import { creators as patientTrackerV3Creators } from '~/store/patientTrackerV3';

const DownloadPatients: React.FC<{
  storeKeys: StoreKeys;
  siteId: string;
  small?: boolean;
}> = ({ storeKeys: { store, stateMapKey: stateId }, siteId, small }) => {
  const dispatch = useDispatch();
  const patientTrackerCreators = store === 'patientTrackerV2' ? patientTrackerV2Creators : patientTrackerV3Creators;
  const { fetchingRecords, exports } = useSelector(({ [store]: patientTracker }: RootState) => patientTracker[stateId]);
  const { loading: exporting, data: csvData, fileName } = exports;

  const handleDownload = () => {
    dispatch(patientTrackerCreators.getPatientTrackerRecordsCsv(stateId, siteId, {}));
  };

  useEffect(() => {
    if (csvData && fileName) {
      const data = new Blob([csvData], { type: 'text/csv' });
      const csvURL = URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = csvURL;
      link.download = fileName!;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [csvData, fileName]);

  return (
    <Button
      onClick={() => handleDownload()}
      loading={exporting}
      disabled={fetchingRecords}
      ariaLabel="download"
      small={small}>
      Download
    </Button>
  );
};

export default DownloadPatients;

import { AccentPalette, ErrorPalette, GrayscalePalette, WarningPalette } from '@tcl-boron-colors/colors';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  updateOverlayWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '50%',
  },
  updateOverlayHeader: {
    fontSize: '20px',
    fontWeight: 600,
    color: 'black',
    marginBottom: '6px',
    wordWrap: 'break-word',
  },
  patientDetailsHeader: {
    fontSize: '14px',
    padding: '16px 24px 24px 19px',
    borderBottom: `1px solid ${GrayscalePalette[300]}`,
  },
  updateOverlaySubHeader: {
    fontWeight: 700,
    lineHeight: '20px',
    color: 'black',
  },
  physicianUnknown: {
    color: `${GrayscalePalette[500]}`,
  },
  updateOverlayTwoColumnContainer: {
    display: 'flex',
    gap: '16px',
    alignItems: 'flex-end',

    '& > div': {
      flex: 1,
    },
  },
  visitDetailsContainer: {
    alignItems: 'flex-start',
  },
  dateWarning: {
    marginTop: '4px',
    fontSize: '12px',
    color: `${WarningPalette[700]}`,
    display: 'flex',
    alignItems: 'center',
  },
  warningIcon: {
    height: '12px',
    marginRight: '4px',
  },
  dateError: {
    marginTop: '4px',
    fontSize: '12px',
    color: `${ErrorPalette[700]}`,
    display: 'flex',
    alignItems: 'center',
  },
  errorIcon: {
    height: '12px',
    marginRight: '4px',
  },
  date: {
    boxSizing: 'border-box',
  },
  bottomButtons: {
    display: 'flex',
    padding: '24px',
    justifyContent: 'flex-end',
    gap: '12px',
    marginTop: '0px',
    borderTop: `1px solid ${GrayscalePalette[300]}`,
    position: 'sticky',
    height: '10px',
    alignItems: 'center',
  },
  closeButton: {
    top: '20px',
    right: '20px',
    border: 'none',
    cursor: 'pointer',
    margin: '-5px',
    padding: '12px 14px',
    position: 'absolute',
    zIndex: 1,
  },
  mainBody: {
    height: 'inherit',
    padding: '16px',
    background: `${GrayscalePalette[200]}`,
    overflowY: 'auto',
  },
  patientStatusContainer: {
    paddingBottom: '16px',
  },
  enrolledTrialsLink: {
    cursor: 'pointer',
    color: `${AccentPalette[100]}`,
  },
  paneContainer: {
    display: 'flex',
    flex: 1,
    height: '90%',
    width: '100%',
  },
  updateOverlayContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  patientStatusCard: {
    margin: '16px 0',
    height: 'auto',
    padding: '16px',
    fontSize: '14px',
    lineHeight: '20px',
    backgroundColor: 'white',
    boxShadow: '0px 1px 3px 1px rgba(0, 0, 0, 0.1)',

    '& > div > div': {
      overflowY: 'hidden',
      padding: '0 0 4px 0',
    },
  },
  sectionTitle: {
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 600,
    color: `${GrayscalePalette[600]}`,
  },
});

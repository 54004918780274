import { SingleDatePicker } from '@tcl-boron-prefabs/single-date-picker';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import InputFieldHelperText from '~/components/UpdateOverlay/DatePickerHelperText';
import {
  dateRuleNotInFuture,
  dateRuleRequired,
  getChangedValues,
  getFormattedDateForPicker,
  validateDateByRules,
} from '~/components/UpdateOverlay/utils';
import { TrialMatchUpdates } from '~/store/api/types';
import { SyncedFields } from '~/store/patientTrackerCommons/types';

import useStyles from './styles';

interface EnrolledProps {
  enrolledDate: string;
  syncedFields: SyncedFields;
  setSyncedFields: (syncedFields: SyncedFields) => void;
  handleChanges: (changes: Partial<TrialMatchUpdates>) => void;
  setAreRequiredFieldsMissing: (areRequiredFieldsMissing: boolean) => void;
}

export const Consented: React.FC<EnrolledProps> = ({
  enrolledDate,
  syncedFields,
  setSyncedFields,
  handleChanges,
  setAreRequiredFieldsMissing,
}) => {
  const classes = useStyles();
  const selectedEnrolledDate = syncedFields.enrolledDate;
  const validateEnrolledDate = (date: string) => validateDateByRules(date, [dateRuleRequired, dateRuleNotInFuture]);

  const [enrolledDateValidation, setEnrolledDateValidation] = useState(
    validateEnrolledDate(selectedEnrolledDate || ''),
  );

  const setSelectedEnrolledDate = (date: string | null) => {
    setSyncedFields({ ...syncedFields, enrolledDate: date });
  };

  useEffect(() => {
    setEnrolledDateValidation(validateEnrolledDate(selectedEnrolledDate || ''));
  }, [selectedEnrolledDate]);

  const getInitialValues = () => {
    return {
      firstTreatmentDate: enrolledDate ? new Date(enrolledDate).toISOString() : null,
    };
  };

  useEffect(() => {
    const currentValues: Partial<TrialMatchUpdates> = {
      firstTreatmentDate: selectedEnrolledDate ? new Date(selectedEnrolledDate).toISOString() : null,
    };

    const changedValues: Partial<TrialMatchUpdates> = getChangedValues(getInitialValues(), currentValues);
    handleChanges(changedValues);
    const selectedEnrolledDateValidation = validateEnrolledDate(selectedEnrolledDate || '');
    setEnrolledDateValidation(selectedEnrolledDateValidation);
    setAreRequiredFieldsMissing(!selectedEnrolledDateValidation.passed);
  }, [selectedEnrolledDate]);

  return (
    <div className={classes.twoColumns}>
      <div>
        <SingleDatePicker
          label="Enrolled date"
          status={enrolledDateValidation.status}
          onChange={(date) => setSelectedEnrolledDate(getFormattedDateForPicker(date.dateString))}
          value={{ dateString: moment.utc(selectedEnrolledDate).format('MM/DD/YYYY') }}
        />
        <InputFieldHelperText status={enrolledDateValidation.status} message={enrolledDateValidation.message} />
      </div>
    </div>
  );
};

export default Consented;

import { H4 } from '@tempus/component-library/typography';
import withStyles from '@tempus/component-library/utils/withStyles';
import { DocumentConstants } from '@tempus/t-shared';
import { documentActions, useNoBodyScroll } from '@tempus/t-shared/ui';
import { capitalize } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { grayPalette } from 'tcl-v3/colors';
import { DropdownOption, InputTheme } from 'tcl-v3/models';

import { CreatableSingleSelectCombobox } from '~/components/CreatableSingleSelectCombobox';
import { RootState } from '~/store';
import { Institution } from '~/store/site/types';
import { BaseTrial } from '~/store/trial/types';

import { FileDetails } from './FileDetails';

const StyledH4 = withStyles({
  root: {
    paddingTop: '40px',
    paddingLeft: '30px',
    margin: 0,
    color: grayPalette.gray50,
  },
})(H4);

interface FileListProps {
  showErrors: boolean;
  documentUploading: string | null;
  providedId?: string;
  classification: DocumentConstants.Classification;
  updateClassificationId: (classificationOption: string | null) => void;
}

interface TrialDropdownOption extends DropdownOption {
  sponsor: string;
}

const MemoizedFileDetails = React.memo(FileDetails);

export const FileList: React.FC<FileListProps> = ({
  showErrors,
  documentUploading,
  classification,
  providedId,
  updateClassificationId,
}) => {
  useNoBodyScroll();

  const dispatch = useDispatch();

  const allTrials: BaseTrial[] = useSelector((state: RootState) => state.trial.trialOptions);
  const timeSites: Institution[] = useSelector(({ site }: RootState) => site.timeSites);
  const uploads = useSelector(({ documentEdit }: RootState) => documentEdit.uploads);

  const optionalClassification =
    classification === DocumentConstants.Classification.Trial
      ? DocumentConstants.Classification.Site
      : DocumentConstants.Classification.Trial;

  const allOptions = useMemo(
    () => ({
      trial: allTrials.map(({ shortName, title, id: trialId, nctId, sponsor, tempusTrialId }) => {
        const trialName = shortName ? shortName : title;
        return {
          value: trialId,
          label: `${nctId || tempusTrialId} - ${trialName}`,
          sponsor: sponsor,
        };
      }),
      site: timeSites.map(({ id: siteId, name: siteName, shortName }) => ({
        value: siteId,
        label: shortName || siteName,
      })),
    }),
    [allTrials, timeSites],
  );

  const init: string[] = [];
  const allSponsorOptions: DropdownOption[] = useMemo(
    () =>
      allTrials
        .reduce((sponsors, { sponsor }) => (sponsors.includes(sponsor) ? sponsors : [sponsor, ...sponsors]), init)
        .sort()
        .map((sponsor) => ({ value: sponsor, label: sponsor })),
    [allTrials],
  );

  const [sponsor, setSponsor] = useState<DropdownOption | null>(null);
  const [classificationOption, setClassificationOption] = useState<DropdownOption | null>(null);
  const [sponsorOptions, setSponsorOptions] = useState<DropdownOption[]>(allSponsorOptions);
  const [options, setOptions] = useState<DropdownOption[]>([]);
  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);

  useEffect(() => {
    if (documentUploading && !uploadInProgress) {
      setUploadInProgress(true);
    }
  }, [documentUploading]);

  const updateOptions = (option: TrialDropdownOption | null) => {
    if (!option) {
      if (sponsor) {
        setOptions(allOptions.trial.filter(({ sponsor: trialSponsor }) => trialSponsor === sponsor.value));
      }
      return;
    }

    const { sponsor: sponsorName } = option;
    const trialSponsor = sponsorOptions.find((sponsorOption) => sponsorOption.value === sponsorName);
    if (trialSponsor) {
      setSponsorOptions([trialSponsor]);
      setSponsor(trialSponsor);
    }
  };

  const onChange = useCallback(
    (option: DropdownOption | null | undefined) => {
      setClassificationOption(option || null);
      if (option) {
        dispatch(
          documentActions.getDocuments({ classification, id: option.value, skipReload: true, updateCurrent: true }),
        );
      }

      if (classification === DocumentConstants.Classification.Trial) {
        updateOptions(option as TrialDropdownOption | null);
      }
    },
    [classification],
  );

  useEffect(() => {
    const classificationOptions = allOptions[classification];
    setOptions(classificationOptions);
    if (providedId) {
      onChange(classificationOptions.find(({ value }) => value === providedId) || null);
    }
  }, []);

  useEffect(() => {
    if (!providedId) {
      updateClassificationId(classificationOption ? classificationOption.value : null);
    }
  }, [classificationOption]);

  const updateSponsor = useCallback(
    (option: DropdownOption | null | undefined) => {
      if (!option) {
        setSponsor(null);
        setClassificationOption(null);
        setOptions(allOptions.trial);
        setSponsorOptions(allSponsorOptions);
        return;
      }

      setSponsor(option);
      const { value: sponsorName } = option;
      setOptions(allOptions.trial.filter(({ sponsor: trialSponsor }) => trialSponsor === sponsorName));
    },
    [allOptions, allSponsorOptions],
  );

  const localTags = useMemo(() => {
    return uploads.reduce((acc, file) => {
      file.document.tags.forEach((tag) => {
        acc[tag.key] = acc[tag.key] || [];
        acc[tag.key].push(tag.value);
      });

      return acc;
    }, {} as Record<string, string[]>);
  }, [uploads.map((u) => u.tagChangeCount).join(',')]);

  const optionalClassificationOptions = useMemo(() => {
    return allOptions[optionalClassification];
  }, [optionalClassification, allOptions.trial.length, allOptions.site.length]);

  return (
    <React.Fragment>
      <div className="modal-background">
        <StyledH4>Classification</StyledH4>
        <div className="classification-row">
          {classification === DocumentConstants.Classification.Trial && (
            <div className="classification-dropdown">
              <CreatableSingleSelectCombobox
                createDisabled
                label="Sponsor"
                clearable={true}
                options={sponsorOptions}
                onChange={updateSponsor}
                value={sponsor}
                disabled={uploadInProgress || Boolean(providedId)}
                data-pendo-id="document-upload-sponsor-select"
                theme={showErrors && !sponsor ? InputTheme.Error : InputTheme.Default}
              />
            </div>
          )}
          <div className="classification-dropdown">
            <CreatableSingleSelectCombobox
              createDisabled
              label={capitalize(classification)}
              clearable={true}
              options={options}
              onChange={onChange}
              value={classificationOption}
              disabled={uploadInProgress || Boolean(providedId)}
              data-pendo-id={`document-upload-${classification}-select`}
              theme={showErrors && !classificationOption ? InputTheme.Error : InputTheme.Default}
            />
          </div>
        </div>
        <StyledH4>File details</StyledH4>
        <div className="document-list">
          <div className="sticky-header">
            <div className="document-header">
              <div className="icon-header" data-pendo-id="document-upload-column-icon"></div>
              <div className="name-header" data-pendo-id="document-upload-column-name">
                Name
              </div>
              <div className="dropdown-header" data-pendo-id="document-upload-column-file-type">
                File type
              </div>
              <div className="fixed-header" data-pendo-id="document-upload-column-version">
                Version
              </div>
              <div className="date-header" data-pendo-id="document-upload-column-display-date">
                Display date
              </div>
              <div className="toggle-group-header">
                <div className="toggle-header" data-pendo-id="document-upload-column-active">
                  Active
                </div>
                <div className="toggle-header" data-pendo-id="document-upload-column-external">
                  External Site
                </div>
                <div className="toggle-header" data-pendo-id="document-upload-column-external-sponsor">
                  External Sponsor
                </div>
              </div>
              <div className="dropdown-header" data-pendo-id={`document-upload-column-${optionalClassification}`}>
                {capitalize(optionalClassification)}
              </div>
            </div>
          </div>
          {uploads.map((file) => (
            <MemoizedFileDetails
              key={file.document.id}
              file={file}
              classification={classification}
              optionalClassification={optionalClassification}
              optionalClassificationOptions={optionalClassificationOptions}
              classificationOption={classificationOption}
              showErrors={showErrors}
              documentUploading={documentUploading}
              localTags={localTags}
            />
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

import { AccentPalette, GrayscalePalette, SuccessPalette, WarningPalette } from '@tcl-boron-colors/colors';
import { NotEditable } from '@tcl-boron-icons/icons';
import { Checkmark } from '@tempus/component-library/icons';
import { PatientCohortMatchWorkflowState } from '@tempus/stateflow-types';
import { PATIENT_TRACKER_STATUSES } from '@tempus/t-shared/src/constants/patient-tracker';
import React from 'react';

import {
  getPatientCohortMatchWorkflowStateToDisplay,
  MATCH_STATUS_FLAGS,
} from '~/store/patientTrackerCommons/constants';

import MatchFlags from './MatchFlags';
import { useStyles } from './styles';

export const Circle: React.FC<{ color: string }> = ({ color }) => {
  return <span className={useStyles().dot} style={{ backgroundColor: color }}></span>;
};

interface MatchStatusProps {
  status: PATIENT_TRACKER_STATUSES | PatientCohortMatchWorkflowState;
  additionalStatusText?: string;
  flags?: MATCH_STATUS_FLAGS[];
}

export const MatchStatus: React.FC<MatchStatusProps> = ({ status, additionalStatusText, flags }) => {
  const classes = useStyles();

  const getColor = () => {
    switch (status) {
      case PATIENT_TRACKER_STATUSES.URGENT:
        return WarningPalette[700];
      case PATIENT_TRACKER_STATUSES.PENDING_SITE_REVIEW:
      case PATIENT_TRACKER_STATUSES.PENDING_TEMPUS_REVIEW:
      case PatientCohortMatchWorkflowState.PENDING_SITE_REVIEW:
      case PatientCohortMatchWorkflowState.PENDING_TEMPUS_REVIEW:
        return AccentPalette[100];
      case PATIENT_TRACKER_STATUSES.IMMINENT_MATCH:
      case PATIENT_TRACKER_STATUSES.PURSUING_ACTIVATION:
      case PATIENT_TRACKER_STATUSES.CONSENTED:
      case PatientCohortMatchWorkflowState.IMMINENT_CANDIDATE:
      case PatientCohortMatchWorkflowState.PURSUING_ACTIVATION:
      case PatientCohortMatchWorkflowState.CONSENTED:
        return SuccessPalette[700];
      case PATIENT_TRACKER_STATUSES.WATCHLIST:
      case PATIENT_TRACKER_STATUSES.TRIAL_COHORT_ON_HOLD:
      case PATIENT_TRACKER_STATUSES.SITE_ON_HOLD:
      case PATIENT_TRACKER_STATUSES.INTERNAL_REVIEW:
      case PatientCohortMatchWorkflowState.WATCHLIST:
      case PatientCohortMatchWorkflowState.INTERNAL_REVIEW:
        return GrayscalePalette[500];
      default:
        return 'transparent';
    }
  };

  const displayIcon = () => {
    switch (status) {
      case PATIENT_TRACKER_STATUSES.ENROLLED:
      case PatientCohortMatchWorkflowState.ENROLLED:
        return (
          <span>
            <Checkmark width={8} height={8} color={SuccessPalette[700]} />
          </span>
        );
      case PATIENT_TRACKER_STATUSES.NO_LONGER_A_CANDIDATE:
      case PatientCohortMatchWorkflowState.NO_LONGER_A_CANDIDATE:
        return <NotEditable width={8} height={8} />;
      default:
        return <Circle color={getColor()} />;
    }
  };

  const isStateflowState = Object.values(PatientCohortMatchWorkflowState).includes(
    status as PatientCohortMatchWorkflowState,
  );

  const statusToDisplay = isStateflowState
    ? getPatientCohortMatchWorkflowStateToDisplay(status as PatientCohortMatchWorkflowState)
    : status;

  return (
    <div>
      <span>
        {displayIcon()}
        <span className={classes.matchStatus}>{`${statusToDisplay} ${additionalStatusText || ''}`}</span>
      </span>
      {flags?.length ? (
        <div className={classes.matchFlagsRow}>
          <MatchFlags flags={flags} />
        </div>
      ) : null}
    </div>
  );
};

export default MatchStatus;

import { ErrorPalette, WarningPalette } from '@tcl-boron-colors/colors';
import { Card } from '@tcl-boron-prefabs/card';
import { SingleDatePicker } from '@tcl-boron-prefabs/single-date-picker';
import { SingleSelectDropdown } from '@tcl-boron-prefabs/single-select-dropdown';
import { PatientCohortMatchWorkflowState } from '@tempus/stateflow-types';
import { storeActions } from '@tempus/t-shared/ui';
import cn from 'classnames';
import { every, get, includes } from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Warning, Fail, Close } from 'tcl-v3/icons';
import { DropdownOption } from 'tcl-v3/models';

import { RootState } from '~/store';
import api from '~/store/api';
import { UpdateTimePatientV2 } from '~/store/api/types';
import {
  getPatientStatusDropdownOptions,
  getVisitTypeDropdownOptions,
  PATIENT_OVERLAY_TAB_ID,
} from '~/store/patientTrackerCommons/constants';
import { getPatientTrackerRecordsRequestOptions, isDatePast } from '~/store/patientTrackerCommons/helpers';
import { PatientTrackingDetails, StoreKeys, TimePatient } from '~/store/patientTrackerCommons/types';
import { creators as patientTrackerV2Creators } from '~/store/patientTrackerV2';
import { creators as patientTrackerV3Creators } from '~/store/patientTrackerV3';
import { formatDateForDisplay } from '~/utils/misc';

import { NoActiveTrialMatchesCard } from './NoActiveTrialMatchesCard';
import RightPane from './RightPane';
import TrialMatchCardV2 from './TrialMatchCardV2/TrialMatchCardV2';
import TrialMatchUpdate from './TrialMatchUpdate/TrialMatchUpdate';
import useStyles from './UpdateOverlayContent.styles';
import VisitDatePastBanner from './VisitDatePastBanner';
import { FieldStatus } from './utils';

export interface TimePatientValues {
  status: string;
  visitType: string;
  visitDate: string;
  note: string;
  responseRequired: boolean;
  physicianId?: string;
}
interface UpdateOverlayContentProps {
  storeKeys: StoreKeys;
  timePatient: TimePatient;
  siteId: string;
  onClose: () => void;
  onDataRefresh?: () => void;
  isOpen: boolean;
}

export const UpdateOverlayContentV2: React.FC<UpdateOverlayContentProps> = ({
  storeKeys,
  timePatient,
  siteId,
  onClose = () => {},
  onDataRefresh,
  isOpen,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const matchDetailsContainerRef = useRef<HTMLDivElement | null>(null);
  const { store, stateMapKey: stateId } = storeKeys;
  const patientTrackerTab = useSelector(({ [store]: patientTracker }: RootState) => patientTracker[stateId].tab);
  const { patient, patientTrackingDetails: trackingDetails, physician } = timePatient;

  const initialTimePatientValues: TimePatientValues = {
    status: timePatient?.status || '',
    visitType: timePatient?.timePatientVisits[0]?.visitType || '',
    visitDate: timePatient?.timePatientVisits[0]?.visitDate
      ? moment(timePatient?.timePatientVisits[0]?.visitDate).format('MM/DD/YYYY')
      : '',
    note: '', // Note is always initialized as an empty textbox
    responseRequired: timePatient?.responseRequired,
    physicianId: timePatient?.physician?.id || undefined,
  };

  const [note, setNote] = useState('');
  const [responseRequired, setResponseRequired] = useState(timePatient.responseRequired);
  const [shouldFetchData, setShouldFetchData] = useState(false);

  const [selectedPatientStatus, setSelectedPatientStatus] = useState<DropdownOption | null>({
    label: initialTimePatientValues.status,
    value: initialTimePatientValues.status,
  });
  const updateResponseRequired = (newResponseRequired: boolean) => {
    setResponseRequired(newResponseRequired);
  };
  const updateNote = (newNote: string) => {
    setNote(newNote);
  };
  const [selectedVisitType, setSelectedVisitType] = useState<DropdownOption | null>(null);

  const [selectedDate, setSelectedDate] = useState<{ dateString: string }>({
    dateString: '',
  });

  useEffect(() => {
    const visitType = timePatient.timePatientVisits[0]?.visitType || '';
    setSelectedVisitType({ label: visitType, value: visitType });
    const visitDate = timePatient.timePatientVisits[0]?.visitDate;
    setSelectedDate({ dateString: visitDate ? moment(visitDate).format('MM/DD/YYYY') : '' });
  }, [timePatient]);

  useEffect(() => {
    if (!selectedVisitType) {
      setSelectedDate({ dateString: '' });
    }
  }, [selectedVisitType]);

  const [areThereUnsavedTrialMatchUpdates, setAreThereUnsavedTrialMatchUpdates] = useState(false);
  const [isThereUnsavedNotes, setIsThereUnsavedNotes] = useState(false);
  const [updatingTrialMatch, setUpdatingTrialMatch] = useState<PatientTrackingDetails | null>(null);

  const getDatePickerStatus = (date: string): FieldStatus => {
    if ((!date && selectedVisitType?.value) || (date && isDatePast(date))) {
      return FieldStatus.ERROR;
    }
    if (!date && !selectedVisitType?.value) {
      return FieldStatus.WARNING;
    }

    return FieldStatus.DEFAULT;
  };
  const getTypePickerStatus = (type: string): FieldStatus => {
    if (selectedDate.dateString && !type) {
      return FieldStatus.ERROR;
    }
    if (!selectedDate.dateString && !type) {
      return FieldStatus.WARNING;
    }

    return FieldStatus.DEFAULT;
  };
  const [typePickerStatus, setTypePickerStatus] = useState<FieldStatus>(
    getTypePickerStatus(selectedVisitType?.value as string),
  );
  const [datePickerStatus, setDatePickerStatus] = useState<FieldStatus>(getDatePickerStatus(selectedDate.dateString));

  const [enrolledTrialsLength, setEnrolledTrialsLength] = useState(0);
  const [rightPaneTab, setRightPaneTab] = useState<PATIENT_OVERLAY_TAB_ID>(PATIENT_OVERLAY_TAB_ID.ACTIVITIES);
  const patientTrackerCreators = store === 'patientTrackerV2' ? patientTrackerV2Creators : patientTrackerV3Creators;

  const refreshData = () => {
    dispatch(
      patientTrackerCreators.getPatientTrackerRecords(
        stateId,
        siteId,
        getPatientTrackerRecordsRequestOptions(patientTrackerTab),
      ),
    );
    dispatch(patientTrackerCreators.getPatientTrackerCategoryCounts(stateId, siteId));
    onDataRefresh?.();
  };

  useEffect(() => {
    setEnrolledTrialsLength(
      trackingDetails.filter((ptd) => ptd.status === PatientCohortMatchWorkflowState.ENROLLED).length,
    );
  }, [trackingDetails]);

  useEffect(() => {
    setDatePickerStatus(getDatePickerStatus(selectedDate.dateString));
    setTypePickerStatus(getTypePickerStatus(selectedVisitType?.value as string));
  }, [selectedDate, selectedVisitType]);

  useEffect(() => {
    // on refetching data the whole overlay component gets re-rendered causing the actual overlay to
    // close and the 'update' button to show up, this should re-fetch data when the TimePatient gets updated
    // only after we close the overlay manually.
    if (!isOpen && shouldFetchData) {
      refreshData();
    }
  }, [shouldFetchData, isOpen]);

  const handleClose = () => {
    // TODO: update
    if (areThereUnsavedTrialMatchUpdates || isThereUnsavedNotes) {
      const userConfirmed = window.confirm('You have unsaved changes on this page. Unsaved changes will be discarded.');
      if (userConfirmed) {
        onClose();
      }
      return;
    }
    onClose();
  };

  const OverrideCloseButton = () => (
    <div className={classes.closeButton} onClick={handleClose}>
      <Close />
    </div>
  );

  const updateTimePatient = async (updates: UpdateTimePatientV2) => {
    try {
      await api.timePatient.updateTimePatientV2(timePatient.id, updates);
      dispatch(storeActions.notification.showSuccessMessage('Patient updated.'));
      refreshData();
    } catch (error) {
      dispatch(storeActions.notification.showErrorMessage('Failed to update patient.'));
    }
  };

  const updateStatus = (status: DropdownOption) => {
    setSelectedPatientStatus(status);
    updateTimePatient({ status: status.value });
  };

  const updateVisitType = (type: DropdownOption | null) => {
    setSelectedVisitType(type);
    if ((type && selectedDate.dateString) || !type) {
      updateTimePatient({ visitType: type ? type.value : null, visitDate: type ? selectedDate.dateString : null });
    }
  };

  const updateVisitDate = (date: { dateString: string }) => {
    setSelectedDate(date);
    if (
      getDatePickerStatus(date.dateString) !== FieldStatus.ERROR &&
      ((date.dateString && selectedVisitType?.value) || (!date.dateString && !selectedVisitType?.value))
    ) {
      updateTimePatient({ visitDate: date.dateString, visitType: selectedVisitType?.value });
    }
  };

  useEffect(() => {
    if (!updatingTrialMatch) {
      const timePatientWithoutEditingFlag = {
        ...timePatient,
        patientTrackingDetails: [
          ...timePatient.patientTrackingDetails.filter((ptd) => !ptd.editing),
          ...timePatient.patientTrackingDetails.filter((ptd) => ptd.editing).map((ptd) => ({ ...ptd, editing: false })),
        ],
      };

      dispatch(patientTrackerCreators.setTimePatientData(storeKeys.stateMapKey, timePatientWithoutEditingFlag));
    }
  }, [updatingTrialMatch]);

  return (
    <>
      <div className={classes.updateOverlayContainer}>
        <div className={classes.paneContainer}>
          <div className={classes.updateOverlayWrapper}>
            {/* 
        Need to create an override to the normal overlay close button 
        as that one does not allow us to cancel a close which is required 
        if the user has not saved 
      */}
            <OverrideCloseButton />
            <div className={classes.patientDetailsHeader}>
              <div className={classes.updateOverlayHeader}>
                {patient.firstName} {patient.lastName}
              </div>
              <div className={classes.updateOverlayTwoColumnContainer}>
                <div>
                  <div className={classes.updateOverlaySubHeader}>
                    <span data-testid="update-overlay-DOB">{formatDateForDisplay(patient.dateOfBirth, true)}</span>
                  </div>
                  <div data-testid="update-overlay-indication">{trackingDetails[0]?.patientCancerType}</div>
                </div>
                <div data-testid="update-overlay-Dr.">
                  <div>
                    {physician?.name ? (
                      `Dr. ${physician.name}`
                    ) : (
                      <span className={classes.physicianUnknown}>Physician unknown</span>
                    )}
                  </div>
                  <div>{timePatient.institution.shortName || timePatient.institution.name}</div>
                </div>
              </div>
            </div>
            {!updatingTrialMatch ? (
              <div ref={matchDetailsContainerRef} className={classes.mainBody}>
                <VisitDatePastBanner nextVisit={get(timePatient, 'timePatientVisits[0]')} />
                <div className={classes.sectionTitle}>Patient status</div>
                <Card className={classes.patientStatusCard}>
                  <>
                    <div className={classes.patientStatusContainer}>
                      <SingleSelectDropdown
                        onChange={(opt) => updateStatus(opt!)}
                        value={selectedPatientStatus}
                        label="Step in treatment journey"
                        data-testid="update-overlay-patient-status"
                        options={getPatientStatusDropdownOptions()}
                        data-pendo-id="update_pt-patient_status"
                        escapeClippingArea
                      />
                    </div>
                    <div className={cn(classes.updateOverlayTwoColumnContainer, classes.visitDetailsContainer)}>
                      <div>
                        <SingleSelectDropdown
                          onChange={updateVisitType}
                          value={selectedVisitType}
                          label="Upcoming visit type"
                          data-testid="update-overlay-next-visit-type"
                          options={getVisitTypeDropdownOptions()}
                          status={typePickerStatus}
                          clearable
                          data-pendo-id="update_pt-visit_type"
                        />
                        {typePickerStatus === FieldStatus.WARNING && (
                          <div className={classes.dateWarning}>
                            <div className={classes.warningIcon}>
                              <Warning width={12} height={12} color={WarningPalette[700]} />
                            </div>
                            Type is missing
                          </div>
                        )}
                        {typePickerStatus === FieldStatus.ERROR && (
                          <div className={classes.dateError}>
                            <div className={classes.errorIcon}>
                              <Fail width={12} height={12} color={ErrorPalette[700]} />
                            </div>
                            Visits must have a type
                          </div>
                        )}
                      </div>

                      <div>
                        <SingleDatePicker
                          className={classes.date}
                          label="Upcoming visit date"
                          data-testid="update-overlay-next-visit-date"
                          onChange={updateVisitDate}
                          value={selectedDate}
                          status={datePickerStatus}
                          data-pendo-id="update_pt-visit_date"
                        />
                        {datePickerStatus === FieldStatus.WARNING && (
                          <div className={classes.dateWarning}>
                            <div className={classes.warningIcon}>
                              <Warning width={12} height={12} color={WarningPalette[700]} />
                            </div>
                            Date is missing
                          </div>
                        )}
                        {datePickerStatus === FieldStatus.ERROR && (
                          <div className={classes.dateError}>
                            <div className={classes.errorIcon}>
                              <Fail width={12} height={12} color={ErrorPalette[700]} />
                            </div>
                            {Boolean(selectedDate.dateString) ? 'Date is in the past' : 'Visits must have a date'}
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                </Card>
                <div className={classes.sectionTitle}>Trial matches</div>
                {every(
                  trackingDetails,
                  (ptd) =>
                    includes(
                      [PatientCohortMatchWorkflowState.NO_LONGER_A_CANDIDATE, PatientCohortMatchWorkflowState.ENROLLED],
                      ptd.status,
                    ) && !ptd.editing,
                ) ? (
                  <NoActiveTrialMatchesCard
                    showInactiveMatches={() => {
                      setRightPaneTab(PATIENT_OVERLAY_TAB_ID.INACTIVE_MATCHES);
                    }}
                  />
                ) : (
                  trackingDetails
                    .filter(
                      (ptd) =>
                        !includes(
                          [
                            PatientCohortMatchWorkflowState.NO_LONGER_A_CANDIDATE,
                            PatientCohortMatchWorkflowState.ENROLLED,
                          ],
                          ptd.status,
                        ) || ptd.editing,
                    )
                    .map((ptd) => (
                      <TrialMatchCardV2
                        key={ptd.id}
                        ptd={ptd}
                        setUpdatingTrialMatch={() => setUpdatingTrialMatch(ptd)}
                      />
                    ))
                )}
                {Boolean(enrolledTrialsLength) && (
                  <div
                    className={classes.enrolledTrialsLink}
                    onClick={() =>
                      setRightPaneTab(PATIENT_OVERLAY_TAB_ID.INACTIVE_MATCHES)
                    }>{`Patient has enrolled in (${enrolledTrialsLength}) trial${
                    enrolledTrialsLength > 1 ? 's' : ''
                  }`}</div>
                )}
              </div>
            ) : (
              <TrialMatchUpdate
                stateMapKey={storeKeys.stateMapKey}
                timePatientId={timePatient.id}
                ptd={updatingTrialMatch}
                timePatientVisit={timePatient.timePatientVisits[0]}
                exit={() => setUpdatingTrialMatch(null)}
                areThereUnsavedUpdates={areThereUnsavedTrialMatchUpdates}
                setAreThereUnsavedUpdates={setAreThereUnsavedTrialMatchUpdates}
                refreshData={refreshData}
              />
            )}
          </div>
          <div className={classes.updateOverlayWrapper}>
            <RightPane
              storeKeys={storeKeys}
              timePatient={timePatient}
              setShouldFetchData={setShouldFetchData}
              setIsThereUnsavedNotes={setIsThereUnsavedNotes}
              refreshData={refreshData}
              currentTab={rightPaneTab}
              setCurrentTab={setRightPaneTab}
              setNote={updateNote}
              note={note}
              responseRequired={responseRequired}
              setResponseRequired={updateResponseRequired}
              setUpdatingTrialMatch={setUpdatingTrialMatch}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateOverlayContentV2;

import { ErrorPalette } from '@tcl-boron-colors/colors';
import { Fail, Warning } from '@tcl-boron-icons/icons';
import React from 'react';

import { FieldStatus } from '~/components/UpdateOverlay/utils';

import useStyles from './DatePickerHelperText.styles';

interface InputFieldHelperTextProps {
  status?: FieldStatus;
  message?: string;
}

export const InputFieldHelperText: React.FC<InputFieldHelperTextProps> = ({ status, message }) => {
  const classes = useStyles();

  const statusStyleMap = {
    [FieldStatus.WARNING]: {
      container: classes.warning,
      iconClass: classes.warningIcon,
      icon: <Warning width={12} height={12} color={ErrorPalette[700]} />,
    },
    [FieldStatus.ERROR]: {
      container: classes.dateError,
      iconClass: classes.errorIcon,
      icon: <Fail width={12} height={12} color={ErrorPalette[700]} />,
    },
  };

  if (status === FieldStatus.DEFAULT || !status) {
    return null;
  }

  const styling = statusStyleMap[status];

  return (
    <div className={styling.container}>
      <div className={styling.iconClass}>{styling.icon}</div>
      {message}
    </div>
  );
};

export default InputFieldHelperText;

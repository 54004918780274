import { Badge } from '@tcl-boron-prefabs/badge';
import moment from 'moment';
import React from 'react';

import { isDatePast } from '~/store/patientTrackerCommons/helpers';
import { formatDateForDisplay } from '~/utils/misc';

import { useStyles } from './styles';

interface UpcomingVisitProps {
  date: string;
  type: string;
  doNotShowUpdateDue?: boolean;
}

export const UpcomingVisit: React.FC<UpcomingVisitProps> = ({ date, type, doNotShowUpdateDue }) => {
  const classes = useStyles();

  return (
    <div className={classes.upcomingVisit}>
      {Boolean(date) ? (
        <>
          {!doNotShowUpdateDue && date && isDatePast(date) && (
            <Badge small badgeType="secondary" badgeVariant={8} text="Update due" className={classes.updateDue} />
          )}
          <div>{Boolean(type) && type}</div>
          <div>{formatDateForDisplay(moment(date).toDate())}</div>
        </>
      ) : (
        <i>No known visit date</i>
      )}
    </div>
  );
};

export default UpcomingVisit;

import { createUseStyles } from 'react-jss';

export default createUseStyles({
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
  },

  identificationContainer: {
    height: '487px',
  },

  identificationContent: {
    flexDirection: 'column',

    '& > div': {
      marginBottom: '24px',
      width: '100%',
    },
  },

  matchInformationContainer: {
    height: '760px',
  },

  matchInformationContent: {
    flexDirection: 'column',
    padding: '2px', // allows vertical box-shadow to be seen on the left side of the Comments box

    '& > div': {
      marginBottom: '24px',
      width: '100%',
    },

    '& .comments-input': {
      marginBottom: '24px',

      '& textarea': {
        width: '405px',
        height: '135px',
      },
    },
  },

  patientVisitDetails: {
    display: 'flex',
    marginBottom: '52px',

    '& #patient-next-visit-wrapper': {
      marginRight: '24px',
      width: '206px',
    },

    '& #patient-visit-type-wrapper': {
      flex: 1,
    },
  },

  screenedMatchContainer: {
    height: '235px',
  },

  screenedMatchContent: {
    marginTop: '24px',
  },

  screenedMatchButtonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 'auto',
    height: '54px',
  },

  modalFooter: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 'auto',
    height: '84px',
    margin: '20px 0px 0px 0px',
  },

  modalActions: {
    display: 'flex',
    justifyContent: 'flex-end',

    '& button': {
      height: '50px',
      minWidth: '70px',
    },

    '& .secondary-button': {
      marginRight: '15px',
    },
  },

  errorMessage: {
    color: 'red',
    alignItems: 'center',
    fontSize: '12px',
    marginTop: '3px',
    display: 'flex',
    flexDirection: 'row-reverse',
    '& svg': {
      marginRight: '6px',
    },
  },

  identificationModal: {
    height: '500px',
  },

  matchInformationModal: {
    height: '760px',
  },

  duplicateMatchModal: {
    height: '235px',
  },
  input: {
    padding: '0px 50px 12px 16px',
  },
  buttonContainer: {
    display: 'flex !important',
    alignItems: 'center  !important',
    flexDirection: 'row  !important',
    gap: '8px  !important',
  },
});

export const useBaseModalStyles = createUseStyles({
  root: {
    fontSize: '13px',
    width: '460px !important',
    '& h1': {
      display: 'none',
    },
    top: '50px !important',
  },
});

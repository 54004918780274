import { PATIENT_TRACKER_STATUS_CATEGORIES } from '@tempus/t-shared/src/constants/patient-tracker';

import {
  GetPatientTrackerRecordsOpts,
  PatientRecord,
  PatientTrackerTabCounts,
  PatientTrackerFilters,
  Physician,
  TimePatient,
} from '../patientTrackerCommons/types';

export const GET_PATIENT_TRACKER_V2_RECORDS = 'GET_PATIENT_TRACKER_V2_RECORDS';
export const GET_PATIENT_TRACKER_V2_RECORDS_FAILED = 'GET_PATIENT_TRACKER_V2_RECORDS_FAILED';
export const SET_PATIENT_TRACKER_V2_RECORDS = 'SET_PATIENT_TRACKER_V2_RECORDS';
export const GET_PATIENT_TRACKER_V2_RECORD = 'GET_PATIENT_TRACKER_V2_RECORD';
export const SET_TIME_PATIENT_DATA = 'SET_TIME_PATIENT_DATA';
export const GET_TIME_PATIENT_DATA_FAILED = 'GET_TIME_PATIENT_DATA_FAILED';
export const SET_INSTITUTION_PHYSICIANS = 'SET_INSTITUTION_PHYSICIANS';
export const GET_INSTITUTION_PHYSICIANS_FAILED = 'GET_INSTITUTION_PHYSICIANS_FAILED';
export const CLEAR_PATIENT_TRACKER_V2_RECORD = 'CLEAR_PATIENT_TRACKER_V2_RECORD';
export const GET_PATIENT_TRACKER_V2_RECORDS_CSV = 'GET_PATIENT_TRACKER_V2_RECORDS_CSV';
export const GET_PATIENT_TRACKER_V2_RECORDS_CSV_FAILED = 'GET_PATIENT_TRACKER_V2_RECORDS_CSV_FAILED';
export const SET_PATIENT_TRACKER_V2_RECORDS_CSV = 'SET_PATIENT_TRACKER_V2_RECORDS_CSV';
export const SET_PATIENT_TRACKER_V2_TAB = 'SET_PATIENT_TRACKER_V2_TAB';
export const GET_PATIENT_TRACKER_V2_CATEGORY_COUNTS = 'GET_PATIENT_TRACKER_V2_CATEGORY_COUNTS';
export const SET_PATIENT_TRACKER_V2_CATEGORY_COUNTS = 'SET_PATIENT_TRACKER_V2_CATEGORY_COUNTS';
export const SET_PATIENT_TRACKER_V2_UPDATE_MODAL = 'SET_PATIENT_TRACKER_V2_UPDATE_MODAL';
export const SET_PATIENT_TRACKER_V2_EDIT_OVERLAY = 'SET_PATIENT_TRACKER_V2_EDIT_OVERLAY';
export const SHOW_PATIENT_TRACKER_V2_ERROR_MESSAGE = 'SHOW_PATIENT_TRACKER_V2_ERROR_MESSAGE';
export const SET_PATIENT_TRACKER_V2_FILTERS = 'SET_PATIENT_TRACKER_V2_FILTERS';
export const SET_PATIENT_TRACKER_V2_PAGINATION = 'SET_PATIENT_TRACKER_V2_PAGINATION';

export interface GetPatientTrackerRecords {
  type: typeof GET_PATIENT_TRACKER_V2_RECORDS;
  id: string;
  siteId: string;
  opts?: GetPatientTrackerRecordsOpts;
}

interface SetPatientTrackerRecords {
  type: typeof SET_PATIENT_TRACKER_V2_RECORDS;
  id: string;
  patients: TimePatient[];
}

export interface GetPatientTrackerRecordsFailed {
  type: typeof GET_PATIENT_TRACKER_V2_RECORDS_FAILED;
  id: string;
  error: unknown;
}

export interface GetPatientTrackerRecord {
  type: typeof GET_PATIENT_TRACKER_V2_RECORD;
  id: string;
  siteId: string;
  timePatientId: string;
}

export interface SetTimePatientData {
  type: typeof SET_TIME_PATIENT_DATA;
  id: string;
  patient: TimePatient;
}

export interface GetTimePatientDataFailed {
  type: typeof GET_TIME_PATIENT_DATA_FAILED;
  id: string;
  error: unknown;
}

export interface SetInstitutionPhysicians {
  type: typeof SET_INSTITUTION_PHYSICIANS;
  id: string;
  physicians: Physician[];
}

export interface GetInstitutionPhysiciansFailed {
  type: typeof GET_INSTITUTION_PHYSICIANS_FAILED;
  id: string;
  error: unknown;
}

export interface ClearPatientTrackerRecord {
  type: typeof CLEAR_PATIENT_TRACKER_V2_RECORD;
  id: string;
}

interface SetPatientTrackerTab {
  type: typeof SET_PATIENT_TRACKER_V2_TAB;
  id: string;
  tab: PATIENT_TRACKER_STATUS_CATEGORIES;
}

export interface GetPatientCategoryCounts {
  type: typeof GET_PATIENT_TRACKER_V2_CATEGORY_COUNTS;
  id: string;
  siteId: string;
  currentTab?: PATIENT_TRACKER_STATUS_CATEGORIES;
  opts?: GetPatientTrackerRecordsOpts;
}

interface SetPatientCategoryCounts {
  type: typeof SET_PATIENT_TRACKER_V2_CATEGORY_COUNTS;
  id: string;
  categoryCounts: PatientTrackerTabCounts;
}

interface SetPatientTrackerUpdateModal {
  type: typeof SET_PATIENT_TRACKER_V2_UPDATE_MODAL;
  id: string;
  selectedPatientRecord?: PatientRecord | null;
  isOpen?: boolean;
}

interface SetPatientTrackerEditOverlay {
  type: typeof SET_PATIENT_TRACKER_V2_EDIT_OVERLAY;
  id: string;
  selectedPatientRecord?: PatientRecord | null;
  isOpen?: boolean;
}

// TODO: Temporarily setting all parameters except for type for this action as optional to silent store error
// due to using union action types but no action is actually of type Action<any>.
export interface ShowPatientTrackerErrorMessage {
  type: typeof SHOW_PATIENT_TRACKER_V2_ERROR_MESSAGE;
  id?: string;
  error?: unknown;
  defaultErrorMessage?: string;
}

interface SetPatientTrackerFilters {
  type: typeof SET_PATIENT_TRACKER_V2_FILTERS;
  id: string;
  filters: Partial<PatientTrackerFilters>;
}

interface SetPatientTrackerPagination {
  type: typeof SET_PATIENT_TRACKER_V2_PAGINATION;
  id: string;
  numRecords: number;
  skip: number;
  totalRecords: number;
}

export interface GetPatientTrackerRecordsCsv {
  type: typeof GET_PATIENT_TRACKER_V2_RECORDS_CSV;
  id: string;
  siteId: string;
  opts?: GetPatientTrackerRecordsOpts;
}

interface GetPatientTrackerRecordsCsvFailed {
  type: typeof GET_PATIENT_TRACKER_V2_RECORDS_CSV_FAILED;
  id: string;
  error: unknown;
}

interface SetPatientTrackerRecordsCsv {
  type: typeof SET_PATIENT_TRACKER_V2_RECORDS_CSV;
  id: string;
  data: string;
  fileName: string;
}

export type PatientTrackerActionTypes =
  | GetPatientTrackerRecords
  | GetPatientTrackerRecordsFailed
  | SetPatientTrackerRecords
  | GetPatientTrackerRecord
  | SetTimePatientData
  | GetTimePatientDataFailed
  | SetInstitutionPhysicians
  | GetInstitutionPhysiciansFailed
  | ClearPatientTrackerRecord
  | SetPatientTrackerTab
  | GetPatientCategoryCounts
  | SetPatientCategoryCounts
  | SetPatientTrackerUpdateModal
  | SetPatientTrackerEditOverlay
  | ShowPatientTrackerErrorMessage
  | SetPatientTrackerFilters
  | SetPatientTrackerPagination
  | GetPatientTrackerRecordsCsv
  | GetPatientTrackerRecordsCsvFailed
  | SetPatientTrackerRecordsCsv;

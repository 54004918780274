import { Card } from '@tcl-boron-prefabs/card';
import React from 'react';

import MatchFlags from '~/components/PatientTrackerCard/MatchFlags';
import { PatientTrackingDetails } from '~/store/patientTrackerCommons/types';
import { getMatchFlags } from '~/store/patientTrackerV3/helpers';

import TrialMatchDetails from '../TrialMatchCardV2/TrialMatchDetails';
import useStyles from './styles';

interface MatchOverviewProps {
  ptd: PatientTrackingDetails;
}

export const MatchOverview: React.FC<MatchOverviewProps> = ({ ptd }) => {
  const classes = useStyles();
  const matchFlags = getMatchFlags(ptd);

  return (
    <Card className={classes.trialMatchCard}>
      <>
        <div className={classes.header}>Match overview</div>
        {matchFlags?.length ? (
          <div className={classes.matchOverviewFlags}>
            <MatchFlags flags={matchFlags} />
          </div>
        ) : null}
        <TrialMatchDetails ptd={ptd} />
      </>
    </Card>
  );
};

export default MatchOverview;

import { Pagination } from '@tcl-boron-prefabs/pagination';
import { ALL_SITES, OperationalFlagDisplayName, OperationalFlags, TimeProgramSiteStatus } from '@tempus/t-shared';
import { PATIENT_TRACKER_STATUS_CATEGORIES } from '@tempus/t-shared/src/constants/patient-tracker';
import { DEFAULT_TABLE_PAGE_SIZE, Spinner } from '@tempus/t-shared/ui';
import _ from 'lodash';
import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryParams, StringParam, NumberParam } from 'use-query-params';

import FiltersSidebar from '~/components/FiltersSidebar';
import PatientTrackerCard, { CardColumn } from '~/components/PatientTrackerCard';
import { UpdateOverlay } from '~/components/UpdateOverlay/UpdateOverlay';
import { RootState } from '~/store';
import { PATIENT_TRACKER_V3_TAB, SORT_OPTION } from '~/store/patientTrackerCommons/constants';
import {
  getPatientTrackerRecordsRequestOptions,
  openTrialMaterials,
  trialStatusToDisplay,
} from '~/store/patientTrackerCommons/helpers';
import {
  PatientTrackerFilterField,
  PatientTrackerSingleDropdownFilters,
  PatientTrackerMultiDropdownFilters,
  PatientRecordMetadata,
  PatientTrackingDetailsWithPatientMeta,
  TimePatient,
  StoreKeys,
} from '~/store/patientTrackerCommons/types';
import { creators as patientTrackerV2Creators } from '~/store/patientTrackerV2';
import { defaultFilters as v2DefaultFilters } from '~/store/patientTrackerV2/reducer';
import { creators as patientTrackerV3Creators } from '~/store/patientTrackerV3';
import { getMatchFlags } from '~/store/patientTrackerV3/helpers';
import { defaultFilters as v3DefaultFilters } from '~/store/patientTrackerV3/reducer';
import { creators as trialCreators } from '~/store/trial';
import { Trial } from '~/store/trial/types';
import { useScrollLock } from '~/utils/misc';
import { ArrayParam } from '~/utils/query-params';

import MatchStatus from '../PatientTrackerCard/MatchStatus';
import PatientTrackerStatusTabGroup from '../PatientTrackerStatusTabGroup';
import NoPatients from './NoPatients';
import { useStyles } from './styles';

interface PatientTrackerProps {
  storeKeys: StoreKeys;
  siteId: string;
  patientFullName?: string;
  trialId?: string;
  header?: ReactElement;
  visibleFilters?: PatientTrackerFilterField[];
  timePatients: TimePatient[];
  patientsMetadata: PatientRecordMetadata;
  tabs: (PATIENT_TRACKER_STATUS_CATEGORIES | PATIENT_TRACKER_V3_TAB)[];
}

const PatientTracker: React.FC<PatientTrackerProps> = ({
  storeKeys,
  siteId,
  header,
  visibleFilters,
  timePatients,
  patientsMetadata,
  tabs,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [initialize, setInitialize] = useState(false);
  const [queryParams, setQueryParams] = useQueryParams(
    {
      stateId: StringParam,
      tab: StringParam,
      pageSize: NumberParam,
      page: NumberParam,
      ptdId: StringParam,
      timePatientId: StringParam,
      [PatientTrackerFilterField.SORT_ORDER]: StringParam,
      [PatientTrackerFilterField.TRIAL_NAME]: StringParam,
      [PatientTrackerFilterField.TRIAL_TYPE]: StringParam,
      [PatientTrackerFilterField.TRIAL_STATUS]: ArrayParam,
      [PatientTrackerFilterField.PHYSICIAN]: StringParam,
      [PatientTrackerFilterField.INDICATIONS]: ArrayParam,
      [PatientTrackerFilterField.BIOMARKER]: StringParam,
      [PatientTrackerFilterField.PATIENT_NAME]: StringParam,
      [PatientTrackerFilterField.NOTE]: StringParam,
      [PatientTrackerFilterField.MATCH_STATUS]: ArrayParam,
    },
    { includeAllParams: true },
  );

  // to prevent body from scrolling when patient overlay is open
  useScrollLock(queryParams.timePatientId);

  const store = storeKeys.store;
  const stateId = storeKeys.stateMapKey;

  const { tab, categoryCounts, filters } = useSelector(
    ({ [store]: patientTracker }: RootState) => patientTracker[stateId],
  );
  const fetchingRecords = useSelector(
    ({ [store]: patientTracker }: RootState) => patientTracker[stateId].fetchingRecords,
  );
  const allTrials: Trial[] = useSelector(({ trial }: RootState) => trial.allTrials);

  const visibleTabs = tabs.map((tab) => {
    return {
      title: tab,
      count: _.isNaN(categoryCounts[tab]) ? null : categoryCounts[tab],
    };
  });

  const selectedUserSite = useSelector((state: RootState) => state.site.selectedUserSite);
  const isAllSitesSelected = selectedUserSite?.name == ALL_SITES;
  const columns: CardColumn<PatientTrackingDetailsWithPatientMeta>[] = [
    {
      header: 'Trial',
      customBody: (trackingDetails) => (
        <>
          <div
            onClick={() => openTrialMaterials(trackingDetails.trial?.id, selectedUserSite)}
            className={classes.trialName}
            data-testid="column-trial">
            {trackingDetails.trial?.shortName || trackingDetails.trial?.title}
          </div>
        </>
      ),
      accessor: 'trial',
    },
    {
      header: 'Cohort',
      customBody: (trackingDetails) => {
        const { siteArm, arm } = trackingDetails;
        const isOnHold =
          arm?.operationalFlag &&
          !(
            arm?.operationalFlag === OperationalFlags.NON_ACTIVE_SITES &&
            siteArm?.status === TimeProgramSiteStatus.ACTIVE_TRIAL_SITE
          );
        return (
          <>
            <div data-testid="column-cohort">{arm ? arm?.name : ''}</div>
            <div>
              <i>
                {isOnHold ? OperationalFlagDisplayName : trialStatusToDisplay(siteArm?.status as TimeProgramSiteStatus)}
              </i>
            </div>
          </>
        );
      },
      accessor: 'arm',
    },
    {
      header: 'Match status',
      customBody: (trackingDetails) => (
        <MatchStatus
          status={trackingDetails.status}
          flags={getMatchFlags(trackingDetails)}
          data-testid="column-match-status"
        />
      ),
      accessor: 'status',
    },
    {
      header: '', // Empty column to create a 4-column layout
      customBody: () => <></>,
      accessor: 'id',
    },
  ];

  const paginationState = {
    page: patientsMetadata?.skip + 1,
    pageSize: { label: `${patientsMetadata?.numRecords} rows`, value: patientsMetadata?.numRecords.toString() },
  };
  const pageSize = Math.ceil(patientsMetadata?.totalRecords / patientsMetadata?.numRecords);

  const pageSizeOptions = [15, 30, 50, 100].map((pageSize) => ({
    label: `${pageSize} rows`,
    value: pageSize.toString(),
  }));

  const patientTrackerCreators = store === 'patientTrackerV2' ? patientTrackerV2Creators : patientTrackerV3Creators;

  const refreshRecords = () => {
    dispatch(
      patientTrackerCreators.getPatientTrackerCategoryCounts(
        stateId,
        selectedUserSite?.id || ALL_SITES,
        getPatientTrackerRecordsRequestOptions(tab),
      ),
    );
    dispatch(
      patientTrackerCreators.getPatientTrackerRecords(
        stateId,
        selectedUserSite?.id || ALL_SITES,
        getPatientTrackerRecordsRequestOptions(tab),
      ),
    );
  };

  useEffect(() => {
    refreshRecords();
  }, [selectedUserSite, patientsMetadata.numRecords, patientsMetadata.skip]);

  useEffect(() => {
    dispatch(trialCreators.getAllTrials());
  }, [selectedUserSite]);

  const updatePagination = (nextState) => {
    dispatch(
      patientTrackerCreators.setPatientTrackerPagination(
        stateId,
        parseInt(nextState.pageSize.value),
        0,
        patientsMetadata?.totalRecords,
      ),
    );
  };

  const incrementPage = () => {
    if (patientsMetadata?.skip + 1 === pageSize) {
      return;
    }
    dispatch(
      patientTrackerCreators.setPatientTrackerPagination(
        stateId,
        patientsMetadata.numRecords,
        patientsMetadata?.skip + 1,
        patientsMetadata?.totalRecords,
      ),
    );
  };
  const decrementPage = () => {
    if (patientsMetadata.skip === 0) {
      return;
    }
    dispatch(
      patientTrackerCreators.setPatientTrackerPagination(
        stateId,
        patientsMetadata.numRecords,
        patientsMetadata?.skip - 1,
        patientsMetadata?.totalRecords,
      ),
    );
  };

  useEffect(() => {
    if (!initialize) {
      return;
    }
    dispatch(
      patientTrackerCreators.setPatientTrackerPagination(
        stateId,
        patientsMetadata?.numRecords,
        0,
        patientsMetadata?.totalRecords,
      ),
    );
    refreshRecords();
  }, [tab, initialize]);

  // update the query params when the filters or tab change
  useEffect(() => {
    const filtersToSave = Object.keys(filters).reduce((acc, key) => {
      if (!filters[key]) {
        acc[key] = null;
      } else if (PatientTrackerSingleDropdownFilters.includes(key as PatientTrackerFilterField)) {
        acc[key] = filters[key].value;
      } else if (PatientTrackerMultiDropdownFilters.includes(key as PatientTrackerFilterField)) {
        // deal with the multi dropdown filters
        acc[key] = filters[key].map((filter) => filter.value);
      } else {
        acc[key] = filters[key];
      }
      return acc;
    }, {});

    setQueryParams({
      stateId,
      tab: decodeURIComponent(tab),
      // expand the filters to save
      ...filtersToSave,
    });
  }, [tab, filters]);

  // update the query params when the pagination changes
  useEffect(() => {
    setQueryParams({
      page: paginationState.page - 1,
      pageSize: patientsMetadata?.numRecords,
    });
  }, [paginationState]);

  const defaultFilters = store === 'patientTrackerV2' ? v2DefaultFilters : v3DefaultFilters;

  // load the initial url state from the query params
  useEffect(() => {
    if (queryParams.stateId === stateId) {
      // load the pagination state from the query params
      dispatch(
        patientTrackerCreators.setPatientTrackerPagination(
          stateId,
          queryParams.pageSize || DEFAULT_TABLE_PAGE_SIZE,
          queryParams.page || 0,
          0,
        ),
      );

      if (queryParams.tab) {
        dispatch(
          patientTrackerCreators.setPatientTrackerTab(
            stateId,
            decodeURIComponent(queryParams.tab) as PATIENT_TRACKER_STATUS_CATEGORIES | PATIENT_TRACKER_V3_TAB,
          ),
        );
      }

      // parse the filters from the query params
      const filters = {};
      Object.values(PatientTrackerFilterField).forEach((field) => {
        if (queryParams[field]) {
          if (PatientTrackerSingleDropdownFilters.includes(field as PatientTrackerFilterField)) {
            if (field === PatientTrackerFilterField.TRIAL_NAME) {
              const trial = allTrials.find((trial) => trial.id === queryParams[field]);
              filters[field] = { label: trial?.shortName || trial?.title, value: queryParams[field] };
            } else if (field === PatientTrackerFilterField.SORT_ORDER) {
              filters[field] = { label: SORT_OPTION[queryParams[field] as string], value: queryParams[field] };
            } else {
              filters[field] = { label: _.capitalize(queryParams[field] as string), value: queryParams[field] };
            }
          } else if (PatientTrackerMultiDropdownFilters.includes(field as PatientTrackerFilterField)) {
            filters[field] = (queryParams[field] as string[])
              .filter(Boolean)
              .map((value) => ({ label: _.capitalize(value), value }));
          } else {
            filters[field] = queryParams[field];
          }
        } else {
          filters[field] = defaultFilters[field];
        }
      });

      if (Object.keys(filters).length) {
        dispatch(patientTrackerCreators.setPatientTrackerFilters(stateId, filters));
      }
    }
  }, []);

  useEffect(() => {
    if (fetchingRecords && !initialize) {
      setInitialize(true);
    }
  }, [fetchingRecords]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.twoColumnContainer}>
        <div className={classes.sidebar}>
          <FiltersSidebar
            storeKeys={storeKeys}
            siteId={siteId}
            visibleFilters={visibleFilters}
            patientsMetadata={patientsMetadata}
          />
        </div>
        <div className={classes.mainSection}>
          {header}
          <div>
            <PatientTrackerStatusTabGroup
              storeKeys={storeKeys}
              siteId={siteId}
              tabs={visibleTabs}
              value={tab}
              onChange={(tab) =>
                dispatch(
                  patientTrackerCreators.setPatientTrackerTab(
                    stateId,
                    tab as PATIENT_TRACKER_STATUS_CATEGORIES | PATIENT_TRACKER_V3_TAB,
                  ),
                )
              }
            />
            {fetchingRecords && <Spinner loading={Boolean(fetchingRecords)} transparent relativePosition />}
            {!fetchingRecords &&
              Boolean(timePatients.length) &&
              timePatients.map((timePatient) => (
                <PatientTrackerCard
                  title={`${timePatient.patient.firstName} ${timePatient.patient.lastName}`}
                  status={timePatient?.status || ''}
                  columns={columns}
                  timePatient={timePatient}
                  key={timePatient.id!}
                  siteId={siteId}
                  showSiteName={isAllSitesSelected}
                />
              ))}
            {!fetchingRecords && !timePatients.length && <NoPatients storeKeys={storeKeys} siteId={siteId} />}
          </div>
          {!fetchingRecords && Boolean(timePatients.length) && (
            <Pagination
              className={classes.pagination}
              goToPreviousPage={decrementPage}
              goToNextPage={incrementPage}
              onChange={(nextState) => updatePagination(nextState)}
              totalPages={pageSize}
              value={paginationState}
              pageSizeOptions={pageSizeOptions}
            />
          )}
          <UpdateOverlay storeKeys={storeKeys} siteId={siteId} />
        </div>
      </div>
    </div>
  );
};

export default PatientTracker;

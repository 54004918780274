import { TimeProgramSiteStatus, STProgramSiteStatus } from '@tempus/t-shared';
import moment from 'moment';
import { useEffect, useRef } from 'react';
import YAML from 'yaml';

export const formatDateToYearMonthDay = (dateString: string): string => {
  if (!dateString) {
    return '';
  }

  return moment(dateString).format('YYYY-MM-DD');
};

export const formatDateForDisplay = (dateString?: string | Date | null, isUtc?: boolean): string => {
  if (!dateString) {
    return '';
  }

  const momentDate = isUtc ? moment.utc(dateString) : moment(dateString);
  return momentDate.format('MM-DD-YYYY');
};

export const formatDateToMonthDayYear = (dateString: string | null, isUtc?: boolean): string => {
  if (!dateString) {
    return '';
  }

  const momentDate = isUtc ? moment.utc(dateString) : moment(dateString);
  return momentDate.format('MM/DD/YYYY');
};

export const sentenceCase = (s: string) => (s && s[0].toUpperCase() + s.slice(1).toLowerCase()) || '';

export const convertSnakeToSentenceCase = (s: string) => {
  return sentenceCase(s.replace(/_/g, ' '));
};

export const convertCamelToSentenceCase = (s: string): string => sentenceCase(s.replace(/([A-Z])/g, ' $1').trim());

export const getCurrentDate = (): string => {
  return formatDateToMonthDayYear(new Date().toDateString());
};

export const cleanText = (s: string) => {
  return s
    .replace(/x000D/g, '')
    .replace(/_/g, '')
    .replace(/>\r\n/g, '')
    .replace(/ {2}/g, ' ');
};

// Replace line separators within the content so they are recognized as a linebreaks when exported to a CSV
// Copied from trials-ui/src/common/DownloadCSV/format-patients-for-csv.js
export const replaceLineSeparators = (content) => (content ? content.replace(/[\n|\r]/g, '\u2028') : content);

export const safelyParseYAML = (s: string) => {
  return YAML.parse(cleanText(s));
};

export const getBadgeWidth = (s: string) => {
  switch (s) {
    case TimeProgramSiteStatus.MATCHING:
    case STProgramSiteStatus.MATCHING:
      return '114px';
    case TimeProgramSiteStatus.UNDER_SITE_REVIEW:
      return '110px';
    case TimeProgramSiteStatus.ON_HOLD:
      return '90px';
    default:
      return 'min-content';
  }
};

export const useScrollLock = (isLocked) => {
  const scrollPosition = useRef(0);

  useEffect(() => {
    if (isLocked) {
      // save current scroll position
      scrollPosition.current = window.pageYOffset;

      // prevent scrolling
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollPosition.current}px`;
      document.body.style.width = '100%';
    } else {
      // restore scroll and position
      document.body.style.overflow = '';
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.width = '';
      window.scrollTo(0, scrollPosition.current);
    }

    return () => {
      document.body.style.overflow = '';
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.width = '';
    };
  }, [isLocked]);
};

import { Card } from '@tcl-boron-prefabs/card';
import typography from '@tcl-boron-styles/typography/dist/index.module.scss';
import { parseEscapedCharacters } from '@tempus/t-shared';
import cn from 'classnames';
import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { LinkOut } from 'tcl-v3/icons';

import { RootState } from '~/store';
import { openTrialMaterials } from '~/store/patientTrackerCommons/helpers';
import { PatientTrackingDetails } from '~/store/patientTrackerCommons/types';

import useStyles from './styles';

interface TrialMatchDetailsProps {
  ptd: PatientTrackingDetails;
}

export const TrialMatchDetails: React.FC<TrialMatchDetailsProps> = ({ ptd }) => {
  const classes = useStyles();
  const selectedUserSite = useSelector((state: RootState) => state.site.selectedUserSite);
  const [isMatchDetailsOpen, setIsMatchDetailsOpen] = useState(false);
  const screeningNote = parseEscapedCharacters(ptd.tempusToPortalLatestPortalPatientUpdate || 'N/A');
  const biomarkers = parseEscapedCharacters(ptd.biomarkers || 'N/A');

  return (
    <Card className={classes.matchDetailsCard}>
      <>
        {isMatchDetailsOpen ? (
          <>
            <div className={classes.detailsHeader}>About the match</div>
            <p>
              Updated{' '}
              {ptd.tempusToPortalLatestPortalUpdateModifiedDate
                ? moment(ptd.tempusToPortalLatestPortalUpdateModifiedDate)
                    .utc()
                    .format('MMM DD, YYYY')
                : 'date N/A'}
            </p>
            <div>
              <p>{screeningNote}</p>
            </div>
            <div className={classes.matchDetailsGrid}>
              <div className={classes.matchLabel}>Matching biomarkers</div>
              <div className={classes.value}>{biomarkers}</div>
              <div className={classes.matchLabel}>Phase</div>
              <div className={classes.value}>
                {ptd.armSummary?.summary?.phases ? ptd.armSummary.summary.phases.join(', ') : 'N/A'}
              </div>
              <div className={classes.matchLabel}>Agent</div>
              <div className={classes.value}>
                {ptd.armSummary?.summary?.agents
                  ? ptd.armSummary.summary.agents.map((agent) => agent.name).join(', ')
                  : 'N/A'}
              </div>
              <div className={classes.matchLabel}>Line of therapy</div>
              <div className={classes.value}>
                {ptd.armSummary?.summary?.linesOfTherapies ? ptd.armSummary.summary.linesOfTherapies.join(', ') : 'N/A'}
              </div>
            </div>
            <div className={classes.trialMaterials} onClick={() => openTrialMaterials(ptd.trial.id, selectedUserSite)}>
              <LinkOut />
              View trial materials
            </div>
          </>
        ) : (
          <div className={cn(classes.twoColumns, classes.hiddenDetails)}>
            <div className={classes.oneLineStatus}>{screeningNote}</div>
            <div
              className={cn(typography.link, classes.viewDetails)}
              onClick={() => setIsMatchDetailsOpen(!isMatchDetailsOpen)}>
              + View details
            </div>
          </div>
        )}
        {isMatchDetailsOpen && (
          <div className={cn(typography.link)} onClick={() => setIsMatchDetailsOpen(!isMatchDetailsOpen)}>
            - Hide match details
          </div>
        )}
      </>
    </Card>
  );
};

export default TrialMatchDetails;

import { Patient } from '@tempus/t-rules-engine/lib/src/types/patient/patient';
import { ValueSetOption, SiteArm, TrialArm, Summary, ProgramSite } from '@tempus/t-shared';
import { DropdownOption } from 'tcl-v3/models';

import { BiomarkerSelectOption } from '~/components/TrialSearchInput/biomarkers.utils';

export interface SelectOption extends ValueSetOption {
  value: string;
}

export enum SearchPrompt {
  NO_INPUT,
  NO_RESULTS,
}

export interface TrialSearchQuery {
  patient: Patient;
  //   leave this space for page, pageSize, sortBy, etc.
}

export interface Cohort {
  id: string;
  name: string;
  phase: string;
  agents: string[];
  status: boolean;
  programType: number;
  displayStatus: SiteArm['status'] | TrialArm['sponsorStatus'];
  summary: Summary;
  operationalFlag: string | null;
}

export interface TrialSearchResult {
  id: string;
  nctId: string;
  tempusTrialId: string;
  shortName: string | null;
  title: string | null;
  cohorts: Cohort[];
  programSites: Pick<ProgramSite, 'status' | 'trialId'>[];
}

export interface DiagnosisInput {
  disease: DropdownOption | null;
  diseaseStage: DropdownOption | null;
}

export interface PatientInput {
  age: number | null;
  dateOfBirth: string;
  diagnoses: DiagnosisInput[];
  tmbResults: number | null;
  variantOptions: BiomarkerSelectOption[];
}

export interface TrialSearchState {
  loading: boolean;
  searchPrompt: SearchPrompt;
  searchParams: TrialSearchQuery;
  trials: TrialSearchResult[];
  dropdowns: Record<string, SelectOption[]>;
  patientInput: PatientInput;
  showUnavailableTrialsButton: boolean;
}

// those are filters not shown on the UI for the user to change but are needed when matching to a trial
const FIXED_SEARCH_DEFAULTS = {
  patient: {
    biomarkers: null,
    treatments: null,
    medications: null,
    alteredSplicingEvents: null,
    comorbidConditions: null,
  },
  mutations: {
    origin: '',
    result: '',
    reported: true,
    vaf: 0,
    position: 0,
    mutation: '',
    alteration: '',
    codingExon: '',
    genomicExon: '',
    presentation: '',
    reportSection: '',
    vafFinalTumorPercentage: 0,
    copyNumber: null,
  },
  diseases: {
    status: 0,
    diagnosis: '',
    histology: '',
    cohortCancerType: '',
    cohortCancerSubtype: '',
    finalTumorPercentage: 0,
    tmoDiagnosisMart: '',
  },
};

export const initialState: TrialSearchState = {
  loading: false,
  searchPrompt: SearchPrompt.NO_INPUT,
  trials: [],
  dropdowns: {},
  patientInput: {
    age: null,
    dateOfBirth: '',
    diagnoses: [],
    tmbResults: null,
    variantOptions: [],
  },
  showUnavailableTrialsButton: false,
  searchParams: {
    patient: ({
      demographics: [
        {
          age: null,
          gender: null,
          deceased: null,
        },
      ],
      diseases: [
        {
          tmoDiagnosis: '',
          stageGrade: '',

          ...FIXED_SEARCH_DEFAULTS.diseases,
        },
      ],
      mutations: [
        {
          gene: '',
          pVarSingleChar: '',
          cVar: '',
          fusedGene: '',
          variantType: '',
          ...FIXED_SEARCH_DEFAULTS.mutations,
        },
      ],
      immunotherapyMarkers: [
        {
          tmb: null,
          msi: null,
        },
      ],

      ...FIXED_SEARCH_DEFAULTS.patient,
    } as unknown) as Patient,
  },
};

import { SnoozeDataType } from '@tempus/stateflow-types';
import { isEqual } from 'lodash';
import moment from 'moment';

import { PatientTrackingDetails } from '~/store/patientTrackerCommons/types';

export enum FieldStatus {
  DEFAULT = 'default',
  WARNING = 'warning',
  ERROR = 'error',
}

type DateValidateResult = {
  passed: boolean;
  status?: FieldStatus;
  message?: string;
};
type DateValidateRule = (date: string | undefined | null) => DateValidateResult;

export function getChangedValues(oldState, newState) {
  const changedValues = {};

  for (const key in oldState) {
    if (!isEqual(oldState[key], newState[key])) {
      changedValues[key] = newState[key];
    }
  }

  return changedValues;
}

export const dateRuleRequired = (date, warnOnly?: boolean) => {
  return {
    passed: !!date,
    status: warnOnly ? FieldStatus.WARNING : FieldStatus.ERROR,
    message: warnOnly ? 'Date is missing' : 'Date is required',
  };
};

export const dateRuleRequiredWarning = (date) => dateRuleRequired(date, true);

export const dateRuleNotInFuture = (date, warnOnly?: boolean) => {
  const passed = moment.utc(date).isSameOrBefore(moment(), 'day');
  return {
    passed,
    status: warnOnly ? FieldStatus.WARNING : FieldStatus.ERROR,
    message: warnOnly ? 'Date is in the future' : 'Date cannot be in the future',
  };
};

export const dateRuleNotInFutureWarning = (date) => dateRuleNotInFuture(date, true);

export const dateRuleInFuture = (date, warnOnly?: boolean) => {
  const passed = !date || moment.utc(date).isSameOrAfter(moment(), 'day');

  return {
    passed,
    status: warnOnly ? FieldStatus.WARNING : FieldStatus.ERROR,
    message: warnOnly ? 'Date is in the past' : 'Date must be in the future',
  };
};

export const dateRuleInFutureWarning = (date) => dateRuleInFuture(date, true);

export const validateDateByRules = (date: string | undefined | null, rules: DateValidateRule[]) => {
  for (const rule of rules) {
    const result = rule(date);
    if (!result.passed) {
      return result;
    }
  }

  return {
    passed: true,
    status: FieldStatus.DEFAULT,
  };
};

export const getFormattedDateForPicker = (date: string | Date) =>
  date ? moment.utc(date).format('YYYY-MM-DD HH:mm:ss.SSSSSS ZZ') : '';

export const getSnoozeDataFromPtd = (ptd: Pick<PatientTrackingDetails, 'snoozeData' | 'snoozeType' | 'snoozeDate'>) => {
  if (ptd.snoozeData && ptd.snoozeData.length) {
    return {
      type: ptd.snoozeData[0].type,
      ...(ptd.snoozeData[0].type === SnoozeDataType.SpecificDatePassed && {
        date: (ptd.snoozeData[0].data?.date as unknown) as string,
      }),
    };
  }

  return {
    type: ptd.snoozeType,
    date: ptd.snoozeDate,
  };
};

import { Button } from '@tcl-boron-prefabs/button';
import { Card } from '@tcl-boron-prefabs/card';
import { PatientCohortMatchWorkflowState, SnoozeDataType } from '@tempus/stateflow-types';
import cn from 'classnames';
import React from 'react';

import MatchFlags from '~/components/PatientTrackerCard/MatchFlags';
import MatchStatus from '~/components/PatientTrackerCard/MatchStatus';
import { trialStatusToDisplay } from '~/store/patientTrackerCommons/helpers';
import { PatientTrackingDetails } from '~/store/patientTrackerCommons/types';
import { getMatchFlags } from '~/store/patientTrackerV3/helpers';
import { formatDateForDisplay } from '~/utils/misc';

import { getSnoozeDataFromPtd } from '../utils';
import TrialMatchDetails from './TrialMatchDetails';
import useStyles from './styles';

interface TrialMatchCardV2Props {
  ptd: PatientTrackingDetails;
  setUpdatingTrialMatch: () => void;
}

export const TrialMatchCardV2: React.FC<TrialMatchCardV2Props> = ({ ptd, setUpdatingTrialMatch }) => {
  const classes = useStyles();
  let additionalStatusText = '';
  const snoozeData = getSnoozeDataFromPtd(ptd);
  const matchFlags = getMatchFlags(ptd);

  if (ptd.status === PatientCohortMatchWorkflowState.WATCHLIST) {
    if (snoozeData.type === SnoozeDataType.NextVisitDatePassed) {
      additionalStatusText = 'until after next visit';
    } else if (snoozeData.type === SnoozeDataType.SpecificDatePassed) {
      additionalStatusText = `until ${formatDateForDisplay(snoozeData.date, true)}`;
    }
  }

  return (
    <Card className={classes.trialMatchCard}>
      <div className={classes.trialDetailsSection}>
        <div className={classes.cardHeader}>{ptd.trial?.shortName}</div>
        <div className={classes.twoColumns}>
          <div>{ptd.arm?.name}</div>
          <i className={classes.armStatus}>{ptd.siteArm && trialStatusToDisplay(ptd.siteArm?.status)}</i>
        </div>
        <div className={cn(classes.matchStatusFlagsContainer, classes.twoColumns)}>
          <div className={classes.matchStatusFlags}>
            {matchFlags?.length ? <MatchFlags flags={matchFlags} /> : null}
            <MatchStatus status={ptd.status} additionalStatusText={additionalStatusText} />
          </div>
          <Button buttonType="secondary" small onClick={() => setUpdatingTrialMatch()} ariaLabel="Update">
            Update match
          </Button>
        </div>
        <TrialMatchDetails ptd={ptd} />
      </div>
    </Card>
  );
};

export default TrialMatchCardV2;

import typography from '@tcl-boron-styles/typography/dist/index.module.scss';
import { Activity, CohortOnHoldUpdate } from '@tempus/t-shared';
import cn from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '~/store';
import { StoreKeys } from '~/store/patientTrackerCommons/types';
import { getArmNameToDisplayInActivity } from '~/utils/arm';
import { getTrialDisplay } from '~/utils/trial';

import useStyles from './ActivityStatus.styles';

export const CohortOnHoldUpdateActivity = ({ storeKeys, activity }: { storeKeys: StoreKeys; activity: Activity }) => {
  const classes = useStyles();
  const content = activity.content as CohortOnHoldUpdate;

  const ptds = useSelector(
    ({ [storeKeys.store]: patientTracker }: RootState) =>
      patientTracker[storeKeys.stateMapKey].patient.data?.patientTrackingDetails,
  );

  return (
    <li key={activity.id} className={cn(typography.body, classes.li)}>
      <div className={classes.listItemContainer}>
        <div className={classes.updatesLeft}>
          Cohort on hold flag for{' '}
          <strong>
            {getTrialDisplay(content.trialId, ptds || [])}
            {getArmNameToDisplayInActivity(content.armId, ptds || [], ' -')}
          </strong>
          {` switched ${content.cohortOnHold ? 'on' : 'off'}.`}
        </div>
        <div>{activity.createdAt}</div>
      </div>
    </li>
  );
};

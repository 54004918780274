import { SingleDatePicker } from '@tcl-boron-prefabs/single-date-picker';
import { SingleSelectDropdown } from '@tcl-boron-prefabs/single-select-dropdown';
import cn from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { DropdownOption } from 'tcl-v3/models';

import InputFieldHelperText from '~/components/UpdateOverlay/DatePickerHelperText';
import {
  dateRuleInFutureWarning,
  dateRuleRequiredWarning,
  FieldStatus,
  getChangedValues,
  getFormattedDateForPicker,
  validateDateByRules,
} from '~/components/UpdateOverlay/utils';
import { TrialMatchUpdates } from '~/store/api/types';
import { getVisitTypeDropdownOptions } from '~/store/patientTrackerCommons/constants';
import { formatDateForDisplay } from '~/utils/misc';

import useStyles from './styles';

interface ImminentMatchPursuingActivationProps {
  nextVisitType: string;
  nextVisitDate: string;
  handleChanges: (changes: Partial<TrialMatchUpdates>) => void;
  setAreRequiredFieldsMissing: (areRequiredFieldsMissing: boolean) => void;
}

export const ImminentMatchPursuingActivation: React.FC<ImminentMatchPursuingActivationProps> = ({
  nextVisitType,
  nextVisitDate,
  handleChanges,
}) => {
  const classes = useStyles();

  const [selectedNextVisitType, setNextVisitType] = useState<DropdownOption | null>(
    nextVisitType ? { value: nextVisitType, label: nextVisitType } : null,
  );

  const [selectedNextVisitDate, setNextVisitDate] = useState<string | null>(nextVisitDate);
  const validateDate = (date: string) => validateDateByRules(date, [dateRuleRequiredWarning, dateRuleInFutureWarning]);
  const [nextVisitDateValidation, setNextVisitDateValidation] = useState(validateDate(selectedNextVisitDate || ''));

  const getInitialValues = () => {
    return {
      nextVisitType,
      nextVisitDate: formatDateForDisplay(nextVisitDate, true),
    };
  };

  useEffect(() => {
    const currentValues: Partial<TrialMatchUpdates> = {
      nextVisitType: selectedNextVisitType?.value || '',
      nextVisitDate: formatDateForDisplay(selectedNextVisitDate, true),
    };

    const changedValues: Partial<TrialMatchUpdates> = getChangedValues(getInitialValues(), currentValues);
    handleChanges(changedValues);
    const selectedNextVisitDateValidation = validateDate(selectedNextVisitDate || '');
    setNextVisitDateValidation(selectedNextVisitDateValidation);
  }, [selectedNextVisitType, selectedNextVisitDate]);

  return (
    <>
      <div>Review again after next visit</div>
      <div className={cn(classes.subsequentRow, classes.twoColumns)}>
        <div>
          <SingleSelectDropdown
            onChange={(opt) => setNextVisitType(opt)}
            value={selectedNextVisitType}
            label="Next visit type"
            options={getVisitTypeDropdownOptions()}
            escapeClippingArea
            status={!selectedNextVisitType ? 'warning' : 'default'}
          />
          <InputFieldHelperText
            status={!selectedNextVisitType ? FieldStatus.WARNING : FieldStatus.DEFAULT}
            message={'Type is missing'}
          />
        </div>
        <div>
          <SingleDatePicker
            label="Next visit date"
            status={nextVisitDateValidation.status}
            onChange={(date) => setNextVisitDate(getFormattedDateForPicker(date.dateString))}
            value={{ dateString: moment.utc(selectedNextVisitDate).format('MM/DD/YYYY') }}
          />
          <InputFieldHelperText status={nextVisitDateValidation.status} message={nextVisitDateValidation.message} />
        </div>
      </div>
    </>
  );
};

export default ImminentMatchPursuingActivation;

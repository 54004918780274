import { PROGRAM_TYPES_DEC, ArmUtils } from '@tempus/t-shared';

import { SearchArm } from '~/store/api/types';
import { PatientTrackingDetails } from '~/store/patientTrackerCommons/types';
import { Cohort } from '~/store/trialSearch/state';

export const formatCohortOptions = (cohorts: (Cohort | SearchArm)[]) =>
  cohorts.map(({ id, name, programType, operationalFlag }) => ({
    label: programType === PROGRAM_TYPES_DEC.ST ? ArmUtils.getCohortLabel(name, programType) : name,
    value: id,
    name,
    programType,
    operationalFlag,
  }));

export const getArmNameToDisplayInActivity = (
  armId: string | null,
  ptds: PatientTrackingDetails[],
  separator = ',',
) => {
  const arm = armId ? ptds?.find((ptd) => ptd.arm?.id === armId)?.arm : null;
  const armName = arm?.name || '';
  return armName ? `${separator} ${armName}` : '';
};

import cn from 'classnames';
import React, { useState, useEffect, useRef, ReactNode } from 'react';

import { useTooltipStyles } from './CustomTooltip.styles';

type Position = 'top' | 'right' | 'bottom' | 'left';

interface CustomTooltipProps {
  children: ReactNode;
  message: ReactNode;
  position?: Position;
  delay?: number;
}

export const CustomTooltip: React.FC<CustomTooltipProps> = ({ children, message, position = 'top', delay = 500 }) => {
  const classes = useTooltipStyles();
  const [isVisible, setIsVisible] = useState(false);
  const [coords, setCoords] = useState({ x: 0, y: 0 });
  const tooltipRef = useRef<HTMLDivElement>(null);
  const targetRef = useRef<HTMLDivElement>(null);

  const calculatePosition = () => {
    if (!targetRef.current || !tooltipRef.current) {
      return;
    }

    const targetRect = targetRef.current.getBoundingClientRect();
    const tooltipRect = tooltipRef.current.getBoundingClientRect();
    let x = 0;
    let y = 0;

    switch (position) {
      case 'top':
        x = targetRect.left + (targetRect.width - tooltipRect.width) / 2;
        y = targetRect.top - tooltipRect.height - 8;
        break;

      case 'bottom':
        x = targetRect.left + (targetRect.width - tooltipRect.width) / 2;
        y = targetRect.bottom + 8;
        break;

      case 'left':
        x = targetRect.left - tooltipRect.width - 8;
        y = targetRect.top + (targetRect.height - tooltipRect.height) / 2;
        break;

      case 'right':
        x = targetRect.right + 8;
        y = targetRect.top + (targetRect.height - tooltipRect.height) / 2;
        break;
    }

    setCoords({ x, y });
  };

  useEffect(() => {
    if (isVisible) {
      calculatePosition();
    }
  }, [isVisible]);

  let timer: ReturnType<typeof setTimeout>;

  const handleMouseEnter = () => {
    timer = setTimeout(() => {
      setIsVisible(true);
    }, delay);
  };

  const handleMouseLeave = () => {
    clearTimeout(timer);
    setIsVisible(false);
  };

  return (
    <div className={classes.container}>
      <div
        ref={targetRef}
        onMouseEnter={() => message && handleMouseEnter()}
        onMouseLeave={() => message && handleMouseLeave()}>
        {children}
      </div>

      {isVisible && (
        <div
          ref={tooltipRef}
          className={classes.content}
          style={{
            left: `${coords.x}px`,
            top: `${coords.y}px`,
          }}
          role="tooltip">
          {message}
          <div
            className={cn(classes.arrow, {
              [classes.arrowTop]: position === 'top',
              [classes.arrowBottom]: position === 'bottom',
              [classes.arrowLeft]: position === 'left',
              [classes.arrowRight]: position === 'right',
            })}
          />
        </div>
      )}
    </div>
  );
};

export default CustomTooltip;

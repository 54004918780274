import { PATIENT_TRACKER_STATUS_CATEGORIES } from '@tempus/t-shared/src/constants/patient-tracker';

import { PATIENT_TRACKER_V3_TAB } from '../patientTrackerCommons/constants';
import {
  GetPatientTrackerRecordsOpts,
  PatientRecordBrowsingState,
  PatientTrackerTabCounts,
  PatientTrackerFilters,
  TimePatient,
  Physician,
} from '../patientTrackerCommons/types';
import {
  GET_PATIENT_TRACKER_V2_RECORDS,
  SET_PATIENT_TRACKER_V2_RECORDS,
  PatientTrackerActionTypes,
  GET_PATIENT_TRACKER_V2_RECORDS_FAILED,
  GET_PATIENT_TRACKER_V2_RECORD,
  SET_TIME_PATIENT_DATA,
  GET_TIME_PATIENT_DATA_FAILED,
  CLEAR_PATIENT_TRACKER_V2_RECORD,
  SET_PATIENT_TRACKER_V2_TAB,
  GET_PATIENT_TRACKER_V2_CATEGORY_COUNTS,
  SET_PATIENT_TRACKER_V2_CATEGORY_COUNTS,
  SET_PATIENT_TRACKER_V2_UPDATE_MODAL,
  SET_PATIENT_TRACKER_V2_EDIT_OVERLAY,
  GET_PATIENT_TRACKER_V2_RECORDS_CSV,
  GET_PATIENT_TRACKER_V2_RECORDS_CSV_FAILED,
  SET_PATIENT_TRACKER_V2_RECORDS_CSV,
  SHOW_PATIENT_TRACKER_V2_ERROR_MESSAGE,
  SET_PATIENT_TRACKER_V2_FILTERS,
  SET_PATIENT_TRACKER_V2_PAGINATION,
  GET_INSTITUTION_PHYSICIANS_FAILED,
  SET_INSTITUTION_PHYSICIANS,
} from './types';

export const creators = {
  setPatientTrackerTab(
    id: string,
    tab: PATIENT_TRACKER_STATUS_CATEGORIES | PATIENT_TRACKER_V3_TAB,
  ): PatientTrackerActionTypes {
    return { type: SET_PATIENT_TRACKER_V2_TAB, id, tab: tab as PATIENT_TRACKER_STATUS_CATEGORIES };
  },
  getPatientTrackerRecords(id: string, siteId: string, opts?: GetPatientTrackerRecordsOpts): PatientTrackerActionTypes {
    return { type: GET_PATIENT_TRACKER_V2_RECORDS, id, siteId, opts };
  },
  setPatientTrackerRecords(id: string, patients: TimePatient[]): PatientTrackerActionTypes {
    return { type: SET_PATIENT_TRACKER_V2_RECORDS, id, patients };
  },
  getPatientTrackerRecordsFailed(id: string, error: unknown): PatientTrackerActionTypes {
    return { type: GET_PATIENT_TRACKER_V2_RECORDS_FAILED, id, error };
  },
  getPatientTrackerRecord(id: string, siteId: string, timePatientId: string): PatientTrackerActionTypes {
    return { type: GET_PATIENT_TRACKER_V2_RECORD, id, siteId, timePatientId };
  },
  setTimePatientData(id: string, patient: TimePatient): PatientTrackerActionTypes {
    return { type: SET_TIME_PATIENT_DATA, id, patient };
  },
  getTimePatientDataFailed(id: string, error: unknown): PatientTrackerActionTypes {
    return { type: GET_TIME_PATIENT_DATA_FAILED, id, error };
  },
  setInstitutionPhysicians(id: string, physicians: Physician[]): PatientTrackerActionTypes {
    return { type: SET_INSTITUTION_PHYSICIANS, id, physicians };
  },
  getInstitutionPhysiciansFailed(id: string, error: unknown): PatientTrackerActionTypes {
    return { type: GET_INSTITUTION_PHYSICIANS_FAILED, id, error };
  },
  clearPatientTrackerRecord(id: string): PatientTrackerActionTypes {
    return { type: CLEAR_PATIENT_TRACKER_V2_RECORD, id };
  },
  getPatientTrackerRecordsCsv(
    id: string,
    siteId: string,
    opts?: GetPatientTrackerRecordsOpts,
  ): PatientTrackerActionTypes {
    return { type: GET_PATIENT_TRACKER_V2_RECORDS_CSV, id, siteId, opts };
  },
  getPatientTrackerRecordsCsvFailed(id: string, error: unknown): PatientTrackerActionTypes {
    return { type: GET_PATIENT_TRACKER_V2_RECORDS_CSV_FAILED, id, error };
  },
  setPatientTrackerRecordsCsv(id: string, fileName: string, data: string): PatientTrackerActionTypes {
    return { type: SET_PATIENT_TRACKER_V2_RECORDS_CSV, id, fileName, data };
  },
  getPatientTrackerCategoryCounts(
    id: string,
    siteId: string,
    opts?: GetPatientTrackerRecordsOpts,
  ): PatientTrackerActionTypes {
    return { type: GET_PATIENT_TRACKER_V2_CATEGORY_COUNTS, id, siteId, opts };
  },
  setPatientTrackerCategoryCounts(id: string, categoryCounts: PatientTrackerTabCounts): PatientTrackerActionTypes {
    return { type: SET_PATIENT_TRACKER_V2_CATEGORY_COUNTS, id, categoryCounts };
  },
  setPatientTrackerUpdateModal(
    id: string,
    { selectedPatientRecord, isOpen }: Partial<PatientRecordBrowsingState>,
  ): PatientTrackerActionTypes {
    return { type: SET_PATIENT_TRACKER_V2_UPDATE_MODAL, id, selectedPatientRecord, isOpen };
  },
  setPatientTrackerEditOverlay(
    id: string,
    { selectedPatientRecord, isOpen }: Partial<PatientRecordBrowsingState>,
  ): PatientTrackerActionTypes {
    return { type: SET_PATIENT_TRACKER_V2_EDIT_OVERLAY, id, selectedPatientRecord, isOpen };
  },
  showPatientTrackerErrorMessage(id: string, error: unknown, defaultErrorMessage: string): PatientTrackerActionTypes {
    return { type: SHOW_PATIENT_TRACKER_V2_ERROR_MESSAGE, id, error, defaultErrorMessage };
  },
  setPatientTrackerFilters(id: string, filters: Partial<PatientTrackerFilters>): PatientTrackerActionTypes {
    return { type: SET_PATIENT_TRACKER_V2_FILTERS, id, filters };
  },
  setPatientTrackerPagination(
    id: string,
    numRecords: number,
    skip: number,
    totalRecords: number,
  ): PatientTrackerActionTypes {
    return { type: SET_PATIENT_TRACKER_V2_PAGINATION, id, numRecords, skip, totalRecords };
  },
};

import { Card } from '@tcl-boron-prefabs/card';
import { TextArea } from '@tcl-boron-prefabs/text-area';
import { PatientCohortMatchWorkflowState } from '@tempus/stateflow-types';
import React from 'react';

import useStyles from './styles';

const placeholders = {
  [PatientCohortMatchWorkflowState.WATCHLIST]:
    'How is the patient doing now? Have they had any recent scans or eligibility concerns? Have they changed treatments?',
  [PatientCohortMatchWorkflowState.PURSUING_ACTIVATION]: 'Is there a planned consent date?',
  [PatientCohortMatchWorkflowState.IMMINENT_CANDIDATE]:
    'Is the MD aware of the trial? Has the patient been talked to about the trial, and if so have they expressed interest in participating? Is there a planned consent date?',
  [PatientCohortMatchWorkflowState.CONSENTED]: 'Is there a planned cycle 1 day 1?',
};

interface WhyThisStatusProps {
  status: PatientCohortMatchWorkflowState;
  whyThisStatus: string;
  updateWhyThisStatus: (note: string) => void;
}

export const WhyThisStatus: React.FC<WhyThisStatusProps> = ({ status, whyThisStatus, updateWhyThisStatus }) => {
  const classes = useStyles();

  return (
    <Card className={classes.trialMatchCard}>
      <>
        <div className={classes.header}>Why the match is at this status</div>
        <Card className={classes.matchDetailsCard}>
          <TextArea
            textAreaType="primary"
            placeholder={placeholders[status]}
            value={whyThisStatus}
            onChange={(note) => updateWhyThisStatus(note)}
            label="Note"
            className={classes.whyThisStatus}
          />
        </Card>
      </>
    </Card>
  );
};

export default WhyThisStatus;

import { DocumentConstants, DocumentTagView, DocumentTagKey } from '@tempus/t-shared';
import { documentActions, storeActions } from '@tempus/t-shared/ui';
import { capitalize, isEqual, sortBy } from 'lodash';
import React, { ComponentProps, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from 'tcl-v3/foundations';
import { DropdownOption, InputTheme } from 'tcl-v3/models';
import { SingleSelectCombobox } from 'tcl-v3/prefabs';
import typography from 'tcl-v3/styles/typography.module.scss';

import { classificationField, displayName } from '~/components/AddDocuments/utils';
import { CreatableSingleSelectCombobox } from '~/components/CreatableSingleSelectCombobox';
import DocumentTagPicker from '~/components/DocumentTagPicker';
import TagValueSelect from '~/components/DocumentTagPicker/TagValueSelect';
import IssueText from '~/components/IssueText';
import { categoryOptionsByClassification } from '~/components/TherapiesDocumentListPage/rollup';
import { RootState } from '~/store';
import { creators as siteCreators } from '~/store/site/actions';
import { creators as trialCreators } from '~/store/trial/actions';
import {
  ProgramTypeOptions,
  getEffectiveProgramTypes,
  ProgramTypeDropdownOption,
  onlySupportsConnect,
} from '~/utils/program-type';

import DocumentTypeChangeConfirmationModal from '../DocumentTypeChangeConfirmationModal';
import { useDetailStyles } from '../styles';
import { DetailSectionProps, FileTypeDropdownOption } from '../types';
import useStyles from './styles';

const ERROR_TEXT = 'This document already exists for this trial/site combination.';

const DocumentDetails: React.FC<DetailSectionProps> = ({ document, persistChanges, setUnsavedChanges }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const detailClasses = useDetailStyles();

  const {
    site: { timeSites },
    trial: { allTrials: trials },
    user: { canWriteDocuments },
    document: { documents, classificationId: currentId, classification, loading },
  } = useSelector((state: RootState) => state);

  const field = classificationField(classification);
  const optionalClassification =
    classification === DocumentConstants.Classification.Trial
      ? DocumentConstants.Classification.Site
      : DocumentConstants.Classification.Trial;
  const optionalField = classificationField(optionalClassification);

  const documentOnlySupportsConnect = onlySupportsConnect(document.programType);

  const getRequiredTags = (fileType: string, tags: DocumentTagView[]): DocumentTagView[] => {
    const { requiredKeys } = DocumentConstants.getDocumentRollup(classification, fileType, document.category);
    if (!requiredKeys) {
      return [];
    }

    return requiredKeys.map((key) => tags.find((tag) => tag.key === key) || { key, value: '' });
  };

  const getExcludedKeys = (fileType: string): DocumentTagKey[] => {
    const { requiredKeys } = DocumentConstants.getDocumentRollup(classification, fileType, document.category);
    return requiredKeys ? requiredKeys : [];
  };

  const getProgramTypes = (fileType: string): DocumentConstants.NullableProgramType[] => {
    const { programTypes } = DocumentConstants.getDocumentRollup(classification, fileType, document.category);
    return getEffectiveProgramTypes(programTypes);
  };

  const containerRef = useRef<HTMLDivElement | null>(null);
  const [name, setName] = useState<string>(document.name);
  const [nameChange, setNameChange] = useState<string>(document.name);
  const [type, setType] = useState<string>(document.type);
  const [classificationId, setClassificationId] = useState<string | null>(document[field]);
  const [category, setCategory] = useState<string>(document.category);
  const [optionalClassificationId, setOptionalClassificationId] = useState<string | null>(document[optionalField]);
  const [tags, setTags] = useState<DocumentTagView[]>(document.tags);
  const [excludedKeys, setExcludedKeys] = useState<DocumentTagKey[]>(getExcludedKeys(document.type));
  const [requiredTags, setRequiredTags] = useState<DocumentTagView[]>(getRequiredTags(document.type, document.tags));
  const [typeChange, setTypeChange] = useState<FileTypeDropdownOption | null>(null);
  const [duplicateDoc, setDuplicateDoc] = useState<boolean>(false);
  const [programType, setProgramType] = useState<DocumentConstants.NullableProgramType>(document.programType);
  const [programTypes, setProgramTypes] = useState(getProgramTypes(document.type));

  const { requiredKeys, optionalClassificationRequired } = DocumentConstants.getDocumentRollup(
    classification,
    type,
    category,
  );

  const nameInUse = (): boolean => {
    if (!classificationId) {
      return false;
    }
    const classDocs = documents[classification][classificationId];
    return Boolean(
      classDocs.find((otherDocument) => {
        const matchingOptionalClassification =
          (!Boolean(otherDocument[optionalField]) && !Boolean(optionalClassificationId)) ||
          otherDocument[optionalField] === optionalClassificationId;
        return otherDocument.name === name && otherDocument.id !== document.id && matchingOptionalClassification;
      }),
    );
  };

  const tagsChanged = () => {
    if (tags.length !== document.tags.length) {
      return true;
    }
    return tags.reduce(
      (changed, { key: newKey, value: newValue }) =>
        changed || !Boolean(document.tags.find(({ key, value }) => key === newKey && value === newValue)),
      false,
    );
  };

  const requiredTagsChanged = () => {
    const sortOrder = (tag) => tag.key;
    return !isEqual(
      sortBy(getRequiredTags(document.type, document.tags), [sortOrder]),
      sortBy(requiredTags, [sortOrder]),
    );
  };

  const nameValid = Boolean(name.length);
  const requiredTagsValid =
    !requiredKeys || (requiredTags.length === requiredKeys.length && requiredTags.every((tag) => tag.value));
  const optionalClassificationValid = !optionalClassificationRequired || Boolean(optionalClassificationId);
  const categoryValid = Boolean(category.length);
  const programTypeValid = programTypes.includes(programType);
  const allFieldsValid =
    nameValid && requiredTagsValid && categoryValid && optionalClassificationValid && programTypeValid;
  const documentHasChanged =
    name !== document.name ||
    type !== document.type ||
    classificationId !== document[field] ||
    category !== document.category ||
    optionalClassificationId !== document[optionalField] ||
    requiredTagsChanged() ||
    programType !== document.programType ||
    tagsChanged();

  const duplicateName =
    document.type === DocumentConstants.DocumentRollupConstants.OTHER && document.name !== name && duplicateDoc;
  const duplicateClassification = currentId !== classificationId && duplicateDoc;
  const dupilcateOptionalClassification =
    duplicateDoc && currentId === classificationId && name === document.name && type === document.type;
  const duplicateFileType =
    ![document.type, DocumentConstants.DocumentRollupConstants.OTHER].includes(type) && !requiredKeys && duplicateDoc;
  const duplicateRequiredKey = requiredKeys && duplicateDoc;
  const showOptionalClassificationError =
    optionalClassificationValid && dupilcateOptionalClassification && !duplicateClassification;
  const showRequiredKeyError =
    requiredTagsValid && duplicateRequiredKey && !showOptionalClassificationError && !duplicateClassification;
  const showFileTypeError =
    duplicateFileType && !showOptionalClassificationError && !showRequiredKeyError && !duplicateClassification;

  const resetToStoreValues = () => {
    setName(document.name);
    setNameChange(document.name);
    setType(document.type);
    setClassificationId(document[field]);
    setCategory(document.category);
    setOptionalClassificationId(document[optionalField]);
    setTags(document.tags);
    setTypeChange(null);
    setRequiredTags(getRequiredTags(document.type, document.tags));
    setExcludedKeys(getExcludedKeys(document.type));
    setProgramType(document.programType);
    setProgramTypes(getProgramTypes(document.type));
  };

  useEffect(() => {
    dispatch(trialCreators.getAllTrials());
    dispatch(siteCreators.getAllSites());
  }, []);

  useEffect(() => {
    resetToStoreValues();
  }, [document.id]);

  useEffect(() => {
    if (classificationId && classificationId !== document[field] && !documents[classificationId]) {
      dispatch(documentActions.getDocuments({ classification, id: classificationId }));
    }
  }, [classificationId]);

  useEffect(() => {
    if (!documentHasChanged) {
      setUnsavedChanges(false);
      setDuplicateDoc(false);
    }
  }, [documentHasChanged]);

  const allOptions = {
    trial: trials.map(({ shortName, title, id, nctId, tempusTrialId }) => {
      return {
        value: id,
        label: `${nctId || tempusTrialId} - ${shortName || title}`,
      };
    }),
    site: timeSites.map(({ id: siteId, name: siteName, shortName }) => ({
      value: siteId,
      label: shortName || siteName,
    })),
  };
  const typeOptions: FileTypeDropdownOption[] = Object.values(DocumentConstants.RollupMappings[classification]).map(
    ({ fileType, category, requiredKeys }) => ({
      category,
      value: fileType,
      label: fileType,
      requiredKeys,
    }),
  );

  const programTypeOptions = ProgramTypeOptions.filter((o) => programTypes.includes(o.programType));

  const persistDocumentChanges = async () => {
    if (!documentHasChanged) {
      return;
    }

    if (!allFieldsValid || Boolean(loading)) {
      setUnsavedChanges(true);
      return;
    }

    const nameAlreadyUsed = nameInUse();
    setDuplicateDoc(nameAlreadyUsed);
    if (nameAlreadyUsed) {
      setUnsavedChanges(true);
      return;
    }

    const documentMoved = classificationId !== document[field];
    const updatedDocument = {
      name,
      category,
      type,
      programType,
    };
    updatedDocument[field] = classificationId;
    updatedDocument[optionalField] = optionalClassificationId;

    const result = await persistChanges({
      ...updatedDocument,
      tags: tags.map(({ key, value }) => [key, value]),
    });
    setUnsavedChanges(false);

    if (!result) {
      resetToStoreValues();
    } else if (documentMoved) {
      dispatch(storeActions.notification.showSuccessMessage(`Document moved to another ${classification}.`));
    }
  };

  useEffect(() => {
    persistDocumentChanges();
  }, [name, classificationId, category, type, optionalClassificationId, tags, loading, programType]);

  useEffect(() => {
    setNameChange(name);
  }, [name]);

  const changeProgramType = (newProgramType: DocumentConstants.NullableProgramType) => {
    const newName = displayName(type, name, requiredTags, classification, category, newProgramType);
    setName(newName);
    setProgramType(newProgramType);
  };

  useEffect(() => {
    if (programTypes.length === 1) {
      changeProgramType(programTypes[0]);
    }
  }, [JSON.stringify(programTypes)]);

  const requiredTagChanged = async (option: DropdownOption | null | undefined, requiredKey: DocumentTagKey) => {
    const tagsWithoutRequiredTags = tags.filter((tag) => !requiredKeys?.includes(tag.key as DocumentTagKey));

    const updatedTag: DocumentTagView = { key: requiredKey, value: option ? option.value : '' };
    const updatedRequiredTags = [...requiredTags];
    const tagIndex = updatedRequiredTags.findIndex((tag) => tag.key === requiredKey);
    updatedRequiredTags[tagIndex] = updatedTag;

    setRequiredTags(updatedRequiredTags);
    setName(displayName(type, name, updatedRequiredTags, classification, category, programType));
    setTags([...tagsWithoutRequiredTags, ...updatedRequiredTags.filter((rt) => rt.value)]);
  };

  const changeType = (option: FileTypeDropdownOption) => {
    const newName = displayName(option.value, '', null, classification, category, programType);
    setName(newName);
    setType(option.value);
    setExcludedKeys(getExcludedKeys(option.value));
    setProgramTypes(getProgramTypes(option.value));
    setRequiredTags(getRequiredTags(option.value, []));
    setTags([]);
    setCategory(option.category);
  };

  const programTypeChanged = (option: ProgramTypeDropdownOption) => changeProgramType(option.programType);

  const confirmTypeChange = () => {
    if (typeChange) {
      changeType(typeChange);
      setTypeChange(null);
    }
  };

  const typeChanged = (option: FileTypeDropdownOption) => {
    if (tags.length) {
      setTypeChange(option);
    } else {
      changeType(option);
    }
  };

  useEffect(() => {
    if (documentOnlySupportsConnect) {
      setOptionalClassificationId(null);
    }
  }, [documentOnlySupportsConnect]);

  const persistNameChangesOnBlur = () => setName(nameChange);
  const nameChanged = (e: React.ChangeEvent<HTMLInputElement>) => setNameChange(e.target.value);

  // Use a portal for the combobox dropdown list so it does not get cut off
  //   by the parent element's overflow property. The portal target is the
  //   parent of the root element provided by this component so that the
  //   z-index of the parent is used as a base for the z positioning.
  const comboboxInPortal: ComponentProps<typeof SingleSelectCombobox>['reactSelectProps'] = {
    menuPlacement: 'auto',
    menuPosition: 'fixed',
    menuPortalTarget: containerRef.current ? containerRef.current.parentElement : undefined,
  };

  // Support old categories for `Other`, before selection of fileTypeRollup
  //   was possible and the category was truly just the category.
  const normalizedCategory = DocumentConstants.normalizeCategoryAndFileTypeRollup(category).join(
    DocumentConstants.DocumentRollupConstants.OTHER_CATEGORY_DELIMITER,
  );

  return (
    <div ref={containerRef}>
      <DocumentTypeChangeConfirmationModal
        showModal={Boolean(typeChange)}
        cancel={() => setTypeChange(null)}
        confirm={confirmTypeChange}
      />

      <div className={`${detailClasses.row} ${typography.supportingBody} ${typography.gray}`}>
        Changes made here will be applied to all versions of this document.
      </div>

      <div className={detailClasses.row}>
        <Input
          label="Name"
          value={nameChange}
          onChange={nameChanged}
          disabled={!canWriteDocuments || type !== DocumentConstants.DocumentRollupConstants.OTHER}
          onBlur={persistNameChangesOnBlur}
          data-pendo-id="document-details-name-textbox"
          theme={
            (type === DocumentConstants.DocumentRollupConstants.OTHER && !nameValid) || duplicateName
              ? InputTheme.Error
              : InputTheme.Default
          }
        />
      </div>

      {duplicateName && (
        <IssueText
          classes={detailClasses}
          colorClass={typography.error}
          text={`${ERROR_TEXT} Please select a different name.`}
        />
      )}

      <div className={detailClasses.row}>
        <SingleSelectCombobox
          label={capitalize(classification)}
          clearable={false}
          options={allOptions[classification]}
          disabled={!canWriteDocuments}
          reactSelectProps={comboboxInPortal}
          onChange={(option) => (option ? setClassificationId(option.value) : undefined)}
          data-pendo-id={`document-details-${classification}-combobox`}
          value={allOptions[classification].find((o) => o.value === classificationId)}
          theme={duplicateClassification ? InputTheme.Error : InputTheme.Default}
        />
      </div>

      {duplicateClassification && (
        <IssueText
          classes={detailClasses}
          colorClass={typography.error}
          text={`${ERROR_TEXT} Please select a different ${classification}.`}
        />
      )}

      {type === DocumentConstants.DocumentRollupConstants.OTHER && (
        <div className={detailClasses.row}>
          <CreatableSingleSelectCombobox
            createDisabled
            clearable={false}
            label="Category"
            options={categoryOptionsByClassification[classification]}
            disabled={!canWriteDocuments}
            reactSelectProps={comboboxInPortal}
            onChange={(option) => (option ? setCategory(option.value) : undefined)}
            data-pendo-id="document-details-subcategory-combobox"
            value={categoryOptionsByClassification[classification].find((o) => o.value === normalizedCategory)}
            theme={category.length ? InputTheme.Default : InputTheme.Error}
          />
        </div>
      )}

      <div className={detailClasses.row}>
        <SingleSelectCombobox
          clearable={false}
          label="File type"
          options={typeOptions}
          disabled={!canWriteDocuments}
          onChange={(option) => typeChanged(option as FileTypeDropdownOption)}
          reactSelectProps={comboboxInPortal}
          value={typeOptions.find((o) => o.value.toLowerCase() === type.toLowerCase())}
          data-pendo-id="document-details-file-type-combobox"
          theme={
            duplicateFileType && !duplicateClassification && !showRequiredKeyError && !showOptionalClassificationError
              ? InputTheme.Error
              : InputTheme.Default
          }
        />
      </div>

      {programTypeOptions.length > 1 && (
        <div className={detailClasses.row}>
          <SingleSelectCombobox
            clearable={false}
            label="Program"
            options={programTypeOptions}
            disabled={!canWriteDocuments}
            onChange={(option) => programTypeChanged(option as ProgramTypeDropdownOption)}
            reactSelectProps={comboboxInPortal}
            value={programTypeOptions.find((o) => o.programType === programType)}
            data-pendo-id="document-details-program-type-combobox"
            theme={!programTypeValid ? InputTheme.Error : InputTheme.Default}
          />
        </div>
      )}

      {showFileTypeError && <IssueText classes={detailClasses} colorClass={typography.error} text={`${ERROR_TEXT}`} />}

      {requiredKeys &&
        requiredKeys.map((requiredKey) => {
          const tagValue = (requiredTags.find((tag) => tag.key === requiredKey) || {}).value;
          return (
            <div className={detailClasses.row} key={requiredKey}>
              <TagValueSelect
                tagKey={requiredKey}
                tagValue={tagValue}
                label={requiredKey}
                disabled={!canWriteDocuments}
                onChange={requiredTagChanged}
                theme={
                  !duplicateClassification && !showOptionalClassificationError && (!tagValue || duplicateRequiredKey)
                    ? InputTheme.Error
                    : InputTheme.Default
                }
              />
            </div>
          );
        })}

      {showRequiredKeyError && requiredKeys && (
        <IssueText
          classes={detailClasses}
          colorClass={typography.error}
          text={`${ERROR_TEXT} Please select a different value for one of the following: ${requiredKeys.join(', ')}.`}
        />
      )}

      {!documentOnlySupportsConnect && (
        <>
          <div className={detailClasses.row}>
            <SingleSelectCombobox
              clearable
              label={capitalize(optionalClassification)}
              options={allOptions[optionalClassification]}
              disabled={!canWriteDocuments}
              reactSelectProps={comboboxInPortal}
              onChange={(option: DropdownOption | null) => setOptionalClassificationId(option ? option.value : null)}
              data-pendo-id={`document-details-${optionalClassification}-combobox`}
              value={allOptions[optionalClassification].find((o) => o.value === optionalClassificationId)}
              theme={
                !duplicateClassification && (!optionalClassificationValid || dupilcateOptionalClassification)
                  ? InputTheme.Error
                  : InputTheme.Default
              }
            />
          </div>

          {showOptionalClassificationError && (
            <IssueText
              classes={detailClasses}
              colorClass={typography.error}
              text={`${ERROR_TEXT} Please select a different ${optionalClassification} value.`}
            />
          )}

          <div className={`${detailClasses.row} ${typography.supportingBody} ${typography.gray} note`}>
            Only users at this {optionalClassification} will see public versions of this document.
          </div>
        </>
      )}

      <div className={detailClasses.row} data-pendo-id="document-details-tags">
        <div>
          {/* `div` above breaks out of flex. */}

          <div className={classes.label}>Tags</div>
          <DocumentTagPicker
            tags={tags}
            onTagsChanged={async (tags) => {
              setTags(tags);
              return true;
            }}
            readOnly={!canWriteDocuments}
            excludedKeys={excludedKeys}
          />
        </div>
      </div>
    </div>
  );
};

export default DocumentDetails;

import { BrandPalette, GrayscalePalette } from '@tcl-boron-colors/colors';
import { createUseStyles } from 'react-jss';

const arrowPositions = {
  top: {
    bottom: 0,
    left: '50%',
    transform: 'translate(-50%, 50%) rotate(45deg)',
  },
  bottom: {
    top: 0,
    left: '50%',
    transform: 'translate(-50%, -50%) rotate(45deg)',
  },
  left: {
    right: 0,
    top: '50%',
    transform: 'translate(50%, -50%) rotate(45deg)',
  },
  right: {
    left: 0,
    top: '50%',
    transform: 'translate(-50%, -50%) rotate(45deg)',
  },
};

export const useTooltipStyles = createUseStyles({
  container: {
    position: 'relative',
    display: 'inline-block',
    width: 'fit-content',
  },
  content: {
    zIndex: 1000,
    padding: '8px 12px',
    fontSize: '14px',
    color: `${BrandPalette[700]}`,
    backgroundColor: `${GrayscalePalette[100]}`,
    borderRadius: '4px',
    borderColor: `${GrayscalePalette[300]}`,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
    position: 'fixed',
  },
  arrow: {
    position: 'absolute',
    width: 8,
    height: 8,
    backgroundColor: `${GrayscalePalette[100]}`,
    '&$arrowTop': arrowPositions.top,
    '&$arrowBottom': arrowPositions.bottom,
    '&$arrowLeft': arrowPositions.left,
    '&$arrowRight': arrowPositions.right,
  },
  arrowTop: {
    borderBottom: `1px solid ${GrayscalePalette[300]}`,
    borderRight: `1px solid ${GrayscalePalette[300]}`,
  },
  arrowBottom: {
    borderTop: `1px solid ${GrayscalePalette[300]}`,
    borderLeft: `1px solid ${GrayscalePalette[300]}`,
  },
  arrowLeft: {
    borderTop: `1px solid ${GrayscalePalette[300]}`,
    borderRight: `1px solid ${GrayscalePalette[300]}`,
  },
  arrowRight: {
    borderBottom: `1px solid ${GrayscalePalette[300]}`,
    borderLeft: `1px solid ${GrayscalePalette[300]}`,
  },
});

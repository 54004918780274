import { Badge, BadgeProps } from '@tcl-boron-prefabs/badge';
import { Button } from '@tcl-boron-prefabs/button';
import { MultiSelectDropdown } from '@tcl-boron-prefabs/multi-select-dropdown';
import { MultiSelectTypeahead } from '@tcl-boron-prefabs/multi-select-typeahead';
import { SingleSelectDropdown } from '@tcl-boron-prefabs/single-select-dropdown';
import { SingleSelectTypeahead } from '@tcl-boron-prefabs/single-select-typeahead';
import { TextInput } from '@tcl-boron-prefabs/text-input';
import typography from '@tcl-boron-styles/typography/dist/index.module.scss';
import { ROLES, TimeProgramSiteStatus, ValuesetCodes } from '@tempus/t-shared';
import cn from 'classnames';
import React, { useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { DropdownOption } from 'tcl-v3/models';

import { RootState } from '~/store';
import {
  getSortDropdownOptions,
  getTrialStatusDropdownOptions,
  getTrialTypeDropdownOptions,
  getMatchStatusDropdownOptions,
  MATCH_STATUS_FLAG_BADGE_VARIANTS,
  MATCH_STATUS_FLAGS,
} from '~/store/patientTrackerCommons/constants';
import { getPatientTrackerRecordsRequestOptions, getTrialNameLabel } from '~/store/patientTrackerCommons/helpers';
import {
  StoreKeys,
  PatientTrackerFilterField,
  PatientRecordMetadata,
  PatientTrackerStateId,
} from '~/store/patientTrackerCommons/types';
import { creators as patientTrackerV2Creators } from '~/store/patientTrackerV2';
import { defaultFilters as v2DefaultFilters } from '~/store/patientTrackerV2/reducer';
import { creators as patientTrackerV3Creators } from '~/store/patientTrackerV3';
import { defaultFilters as v3DefaultFilters } from '~/store/patientTrackerV3/reducer';
import { Trial } from '~/store/trial/types';

import { useFeatureEnabled } from '../FeatureGate';
import { useStyles } from './styles';

interface FiltersSidebarProps {
  storeKeys: StoreKeys;
  siteId: string;
  visibleFilters?: PatientTrackerFilterField[];
  patientsMetadata: PatientRecordMetadata;
}

export const FiltersSidebar: React.FC<FiltersSidebarProps> = ({
  storeKeys: { store, stateMapKey: stateId },
  siteId,
  visibleFilters,
  patientsMetadata,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isInternalUser = useSelector((state: RootState) =>
    state.user.effectiveRoles.includes(ROLES.T_SITE_INTERNAL_USER),
  );
  const filters = useSelector((state: RootState) => state[store][stateId].filters, shallowEqual);
  const { sortOrder, trialName, trialStatus, physician, indications, biomarker, trialType, matchStatus } = filters;
  const patientNameText = useSelector((state: RootState) => state[store][stateId].filters.patientName);
  const patientIdText = useSelector((state: RootState) => state[store][stateId].filters.patientId);
  const rnAssignmentText = useSelector((state: RootState) => state[store][stateId].filters.rnAssignment);
  const note = useSelector((state: RootState) => state[store][stateId].filters.note);
  const { fetchingRecords, tab } = useSelector(({ [store]: patientTracker }: RootState) => patientTracker[stateId]);
  const showTrialTypeFilter = useFeatureEnabled('show-trial-type-filter');
  const m3NurseReview = true;

  const allTrials: Trial[] = useSelector(({ trial }: RootState) => trial.allTrials);
  const trialNameFilterOptions = allTrials
    .filter((trial) => trial.status != TimeProgramSiteStatus.ACTIVATED_OUTSIDE_TIME_DO_NOT_MATCH)
    .map((trial) => ({ value: trial.id, label: getTrialNameLabel(trial) }));
  const [filteredTrialNameOptions, setFilteredTrialNameOptions] = useState(trialNameFilterOptions);

  const indicationsFilterOptions = useSelector(
    ({ trialSearch: { dropdowns } }: RootState) => dropdowns[ValuesetCodes.CANCER_COHORT] || [],
  );

  const [filteredIndicationOptions, setFilteredIndicationOptions] = useState(indicationsFilterOptions);

  const [trialNameInput, setTrialNameInput] = useState('');
  const [indicationsInput, setIndicationsInput] = useState('');

  const filterOptions = (
    input: string,
    options: DropdownOption[],
    setFilteredOptions: React.Dispatch<React.SetStateAction<DropdownOption[]>>,
  ) => {
    setFilteredOptions(options.filter((option) => option.label.toLowerCase().includes(input.toLowerCase())));
  };

  const patientTrackerCreators = store === 'patientTrackerV2' ? patientTrackerV2Creators : patientTrackerV3Creators;

  const updateSort = (opt: DropdownOption | null) => {
    dispatch(patientTrackerCreators.setPatientTrackerFilters(stateId, { sortOrder: opt! }));
    applyFilters();
  };

  const getMatchStatusWithFlagsDropdownOptions = (): DropdownOption[] => {
    const flagOpts: DropdownOption[] = Object.values(MATCH_STATUS_FLAGS).map((flag) => ({
      label: '',
      value: flag,
      subContent: (
        <Badge
          text={flag}
          small
          badgeType="secondary"
          badgeVariant={MATCH_STATUS_FLAG_BADGE_VARIANTS[flag] as BadgeProps['badgeVariant']}
          className={classes.matchStatusFlag}
        />
      ),
    }));

    const statusOpts = getMatchStatusDropdownOptions(m3NurseReview, isInternalUser);
    return flagOpts.concat(statusOpts);
  };

  const applyFilters = () => {
    dispatch(
      patientTrackerCreators.setPatientTrackerPagination(
        stateId,
        patientsMetadata.numRecords,
        0,
        patientsMetadata.totalRecords,
      ),
    );
    dispatch(
      patientTrackerCreators.getPatientTrackerCategoryCounts(
        stateId,
        siteId,
        getPatientTrackerRecordsRequestOptions(tab),
      ),
    );
    dispatch(
      patientTrackerCreators.getPatientTrackerRecords(stateId, siteId, getPatientTrackerRecordsRequestOptions(tab)),
    );
  };

  const defaultFilters = store === 'patientTrackerV2' ? v2DefaultFilters : v3DefaultFilters;

  const clearFilters = () => {
    let trialId = '';

    if (stateId === PatientTrackerStateId.MATERIALS_PAGE) {
      trialId = filters.trialName!.value;
    }

    dispatch(
      patientTrackerCreators.setPatientTrackerFilters(stateId, {
        ...defaultFilters,
        trialName: { label: '', value: trialId },
      }),
    );

    applyFilters();
  };

  return (
    <div className={classes.sidebarContainer}>
      <form>
        {visibleFilters?.includes(PatientTrackerFilterField.SORT_ORDER) && (
          <>
            <div className={cn(typography.subSectionHeader, typography.gray, classes.sectionHeader)}>Sort</div>
            <SingleSelectDropdown
              className={classes.dropdown}
              label="Sort order"
              data-testid="sort-order"
              options={getSortDropdownOptions(tab)}
              value={sortOrder}
              onChange={updateSort}
            />
          </>
        )}
        {isInternalUser && (
          <>
            <div className={cn(typography.subSectionHeader, typography.gray, classes.sectionHeader)}>
              Internal Filters
            </div>
            {visibleFilters?.includes(PatientTrackerFilterField.PATIENT_ID) && (
              <TextInput
                className={classes.dropdown}
                label="Patient IDs"
                data-testid="filter-patient-id"
                value={patientIdText as string}
                onChange={(input) =>
                  dispatch(patientTrackerCreators.setPatientTrackerFilters(stateId, { patientId: input }))
                }
              />
            )}
            {visibleFilters?.includes(PatientTrackerFilterField.RN_ASSIGNMENT) && (
              <TextInput
                className={classes.dropdown}
                label="RN Assignment"
                data-testid="filter-rn-assignment"
                value={rnAssignmentText as string}
                onChange={(input) =>
                  dispatch(patientTrackerCreators.setPatientTrackerFilters(stateId, { rnAssignment: input }))
                }
              />
            )}
          </>
        )}
        <div className={cn(typography.subSectionHeader, typography.gray, classes.sectionHeader)}>Filter</div>
        {visibleFilters?.includes(PatientTrackerFilterField.TRIAL_NAME) && (
          <SingleSelectTypeahead
            className={classes.dropdown}
            label="Trial name"
            data-testid="filter-trial-name"
            onChange={(opt) => dispatch(patientTrackerCreators.setPatientTrackerFilters(stateId, { trialName: opt! }))}
            options={filteredTrialNameOptions}
            value={trialName}
            inputValue={trialNameInput}
            onInputValueChange={(input) => setTrialNameInput(input)}
            onDebounceChange={(input: string) =>
              filterOptions(input, trialNameFilterOptions, setFilteredTrialNameOptions)
            }
          />
        )}
        {visibleFilters?.includes(PatientTrackerFilterField.PATIENT_NAME) && (
          <TextInput
            className={classes.dropdown}
            label="Patient name"
            data-testid="filter-patient-name"
            value={patientNameText}
            onChange={(input) =>
              dispatch(patientTrackerCreators.setPatientTrackerFilters(stateId, { patientName: input }))
            }
          />
        )}
        {visibleFilters?.includes(PatientTrackerFilterField.TRIAL_STATUS) && (
          <MultiSelectDropdown
            className={classes.dropdown}
            label="Trial status"
            data-testid="filter-trial-status"
            options={getTrialStatusDropdownOptions()}
            value={trialStatus}
            onChange={(opt) =>
              dispatch(patientTrackerCreators.setPatientTrackerFilters(stateId, { trialStatus: opt! }))
            }
          />
        )}
        {visibleFilters?.includes(PatientTrackerFilterField.MATCH_STATUS) && m3NurseReview ? (
          <MultiSelectDropdown
            className={classes.dropdown}
            label="Match status and flags"
            options={getMatchStatusWithFlagsDropdownOptions()}
            value={matchStatus}
            onChange={(opts) => {
              dispatch(patientTrackerCreators.setPatientTrackerFilters(stateId, { matchStatus: opts! }));
            }}
          />
        ) : (
          <MultiSelectDropdown
            className={classes.dropdown}
            label="Match status"
            data-testid="filter-match-status"
            options={getMatchStatusDropdownOptions(isInternalUser)}
            value={matchStatus}
            onChange={(opts) => {
              dispatch(patientTrackerCreators.setPatientTrackerFilters(stateId, { matchStatus: opts! }));
            }}
          />
        )}
        {visibleFilters?.includes(PatientTrackerFilterField.TRIAL_TYPE) && showTrialTypeFilter && (
          <SingleSelectDropdown
            className={classes.dropdown}
            label="Trial type"
            data-testid="filter-trial-type"
            clearable={false}
            options={getTrialTypeDropdownOptions()}
            value={trialType}
            onChange={(opt) => dispatch(patientTrackerCreators.setPatientTrackerFilters(stateId, { trialType: opt! }))}
          />
        )}
        {visibleFilters?.includes(PatientTrackerFilterField.PHYSICIAN) && (
          <TextInput
            className={classes.dropdown}
            label="Physician"
            data-testid="filter-physician"
            value={physician}
            onChange={(input) =>
              dispatch(patientTrackerCreators.setPatientTrackerFilters(stateId, { physician: input }))
            }
          />
        )}
        {visibleFilters?.includes(PatientTrackerFilterField.INDICATIONS) && (
          <MultiSelectTypeahead
            className={classes.dropdown}
            label="Indications"
            data-testid="filter-indications"
            onChange={(opts) =>
              dispatch(patientTrackerCreators.setPatientTrackerFilters(stateId, { indications: opts! }))
            }
            options={filteredIndicationOptions}
            value={indications}
            inputValue={indicationsInput}
            onInputValueChange={(input) => setIndicationsInput(input)}
            onDebounceChange={(input: string) =>
              filterOptions(input, indicationsFilterOptions, setFilteredIndicationOptions)
            }
          />
        )}
        {visibleFilters?.includes(PatientTrackerFilterField.BIOMARKER) && (
          <TextInput
            className={classes.dropdown}
            label="Biomarker"
            data-testid="filter-biomarker"
            value={biomarker}
            onChange={(input) =>
              dispatch(patientTrackerCreators.setPatientTrackerFilters(stateId, { biomarker: input }))
            }
          />
        )}
        {visibleFilters?.includes(PatientTrackerFilterField.NOTE) && (
          <TextInput
            className={classes.dropdown}
            label="Note text"
            data-testid="filter-note-text"
            value={note}
            onChange={(input) => dispatch(patientTrackerCreators.setPatientTrackerFilters(stateId, { note: input }))}
          />
        )}
        <div className={classes.filterButtons}>
          <Button
            small
            buttonType="secondary"
            onClick={clearFilters}
            data-testid="filter-clear-button"
            ariaLabel="Clear filters">
            Clear
          </Button>
          <Button
            small
            buttonType="primary"
            loading={fetchingRecords}
            onClick={applyFilters}
            ariaLabel="Filters patients"
            data-testid="filter-apply-button"
            type="submit">
            Apply
          </Button>
        </div>
      </form>
    </div>
  );
};

export default FiltersSidebar;

import { ErrorPalette, WarningPalette } from '@tcl-boron-colors/colors';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  warning: {
    marginBottom: '10px',
    fontSize: '12px',
    color: `${WarningPalette[700]}`,
    display: 'flex',
    alignItems: 'center',
  },
  warningIcon: {
    height: '12px',
    marginRight: '4px',
    marginBottom: '2px',
    color: `${WarningPalette[700]}`,
  },
  dateError: {
    marginTop: '4px',
    fontSize: '12px',
    color: `${ErrorPalette[700]}`,
    display: 'flex',
    alignItems: 'center',
  },
  errorIcon: {
    height: '16px',
    marginRight: '4px',
  },
});

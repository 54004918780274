import { documentReducer, contactReducer, CONTACT_REDUCER_KEY } from '@tempus/t-shared/ui';

import { reducer as documentEditReducer } from './documentEdit';
import { reducer as patientTrackerV2Reducer } from './patientTrackerV2';
import { reducer as patientTrackerV3Reducer } from './patientTrackerV3';
import { reducer as siteReducer } from './site';
import { reducer as trialReducer } from './trial';
import { reducer as trialSearchReducer } from './trialSearch';

export default {
  document: documentReducer,
  documentEdit: documentEditReducer,
  trial: trialReducer,
  site: siteReducer,
  patientTrackerV2: patientTrackerV2Reducer,
  patientTrackerV3: patientTrackerV3Reducer,
  trialSearch: trialSearchReducer,
  [CONTACT_REDUCER_KEY]: contactReducer,
};

import { GrayscalePalette } from '@tcl-boron-colors/colors';
import { PatientCohortMatchWorkflowState } from '@tempus/stateflow-types';
import {
  TrialType,
  LfsTrialStatus,
  TimePatientStatuses,
  TimePatientStatusesDetail,
  TimePatientStatusesOrder,
} from '@tempus/t-shared';
import {
  PATIENT_TRACKER_STATUS_ROLLUP,
  PATIENT_TRACKER_STATUSES,
  PATIENT_TRACKER_STATUS_CATEGORIES,
  Tab,
} from '@tempus/t-shared/src/constants/patient-tracker';
import React from 'react';
import { DropdownOption } from 'tcl-v3/models';

import { convertSnakeToSentenceCase } from '~/utils/misc';

import { TrialMatchUpdates } from '../api/types';
import { PatientTrackerStateId, PatientTrackingDetails } from './types';

export const PATIENT_TRACKER_CATEGORY_LOOKUP = Object.entries(PATIENT_TRACKER_STATUS_ROLLUP).reduce(
  (acc, [category, statusArray]) => {
    statusArray.forEach((status) => {
      acc[status] = category;
    });

    return acc;
  },
  {},
);

export enum VisitType {
  PROVIDER_VISIT = 'Provider Visit',
  SCAN = 'Scan',
  OTHER = 'Other',
}

export enum PATIENT_TRACKER_ACKNOWLEDGED_STATUS {
  NEEDS_REVIEW = 'Needs Review',
  NOT_APPROVED = 'Not Approved',
  APPROVED = 'Approved',
}
export enum PATIENT_TRACKER_V3_TAB {
  REVIEW_REQUIRED = 'Review required',
  WATCHLIST = 'Watchlist',
  CANDIDATE_NOW = 'Candidate now',
  SCREENING_QUEUE = 'Screening queue',
  ENROLLED = 'Enrolled',
  VISITING_THIS_WEEK = 'Visiting this week',
  TIME_SENSITIVE = 'Time sensitive patients',
  ALL = 'All',
}

export enum SORT_OPTION {
  URGENCY = 'Urgency',
  UPCOMING_VISIT_SOONEST_FIRST = 'Upcoming visit (soonest first)',
  UPCOMING_VISIT_FURTHEST_FIRST = 'Upcoming visit (furthest first)',
  OLDEST = 'Date added (oldest first)',
  NEWEST = 'Date added (newest first)',
}

export const getSortDropdownOptions = (
  tab: PATIENT_TRACKER_STATUS_CATEGORIES | PATIENT_TRACKER_V3_TAB,
): DropdownOption[] => {
  if (tab === PATIENT_TRACKER_V3_TAB.VISITING_THIS_WEEK) {
    return [
      { label: SORT_OPTION.UPCOMING_VISIT_SOONEST_FIRST, value: 'UPCOMING_VISIT_SOONEST_FIRST' },
      { label: SORT_OPTION.UPCOMING_VISIT_FURTHEST_FIRST, value: 'UPCOMING_VISIT_FURTHEST_FIRST' },
    ];
  }

  return Object.keys(SORT_OPTION).map((key) => ({
    label: SORT_OPTION[key as keyof typeof SORT_OPTION],
    value: key,
  }));
};

const trialStatusDisplayNames = {
  [LfsTrialStatus.ACTIVE]: 'Active at my site',
  [LfsTrialStatus.AVAILABLE_TO_ACTIVATE]: 'Available to activate',
  [LfsTrialStatus.ON_HOLD]: 'On hold',
};

export const getMatchStatusDropdownOptions = (
  isM3NurseReview: boolean,
  isInternal: boolean = false,
): DropdownOption[] => {
  let sortedMatchStatuses: PatientTrackingDetails['status'][] = [];

  if (isM3NurseReview) {
    sortedMatchStatuses = [
      PatientCohortMatchWorkflowState.PENDING_TEMPUS_REVIEW,
      PatientCohortMatchWorkflowState.PENDING_SITE_REVIEW,
      PatientCohortMatchWorkflowState.WATCHLIST,
      PatientCohortMatchWorkflowState.IMMINENT_CANDIDATE,
      PatientCohortMatchWorkflowState.PURSUING_ACTIVATION,
      PatientCohortMatchWorkflowState.CONSENTED,
      PatientCohortMatchWorkflowState.ENROLLED,
      PatientCohortMatchWorkflowState.NO_LONGER_A_CANDIDATE,
    ];
  } else {
    sortedMatchStatuses = [
      PATIENT_TRACKER_STATUSES.URGENT,
      PATIENT_TRACKER_STATUSES.PENDING_TEMPUS_REVIEW,
      PATIENT_TRACKER_STATUSES.PENDING_SITE_REVIEW,
      PATIENT_TRACKER_STATUSES.WATCHLIST,
      PATIENT_TRACKER_STATUSES.IMMINENT_MATCH,
      PATIENT_TRACKER_STATUSES.PURSUING_ACTIVATION,
      PATIENT_TRACKER_STATUSES.CONSENTED,
      PATIENT_TRACKER_STATUSES.ENROLLED,
      PATIENT_TRACKER_STATUSES.NO_LONGER_A_CANDIDATE,
      PATIENT_TRACKER_STATUSES.TRIAL_COHORT_ON_HOLD,
      PATIENT_TRACKER_STATUSES.SITE_ON_HOLD,
    ];
  }

  if (isInternal) {
    sortedMatchStatuses.push(
      isM3NurseReview ? PatientCohortMatchWorkflowState.INTERNAL_REVIEW : PATIENT_TRACKER_STATUSES.INTERNAL_REVIEW,
    );
  }

  return sortedMatchStatuses.map((status) => ({
    label: isM3NurseReview
      ? getPatientCohortMatchWorkflowStateToDisplay(status as PatientCohortMatchWorkflowState)
      : status,
    value: status,
  }));
};

export const getTrialStatusDropdownOptions = (): DropdownOption[] => {
  const options = [LfsTrialStatus.ACTIVE, LfsTrialStatus.AVAILABLE_TO_ACTIVATE];
  return options.map((opt) => ({
    label: trialStatusDisplayNames[opt],
    value: opt,
  }));
};

export const getVisitTypeDropdownOptions = (): DropdownOption[] =>
  Object.keys(VisitType).map((key) => ({
    label: VisitType[key],
    value: VisitType[key],
  }));

export const getTrialTypeDropdownOptions = (): DropdownOption[] =>
  Object.keys(TrialType).map((key) => ({
    label: TrialType[key],
    value: TrialType[key],
  }));

export const getPatientStatusDropdownOptions = (): DropdownOption[] =>
  Object.keys(TimePatientStatuses)
    .sort((statusA, statusB) => TimePatientStatusesOrder[statusA] - TimePatientStatusesOrder[statusB])
    .map((key) => ({
      label: TimePatientStatuses[key],
      value: TimePatientStatuses[key],
      subContent: React.createElement(
        'div',
        { style: { color: GrayscalePalette[600] } },
        TimePatientStatusesDetail[key],
      ),
    }));

export enum PATIENT_OVERLAY_TAB_ID {
  ACTIVITIES = 'activities',
  PATIENT_DETAILS = 'patientDetails',
  INACTIVE_MATCHES = 'inactiveMatches',
}

export const tabNamesForPage = {
  [PatientTrackerStateId.MATCH_REVIEW_PAGE]: [
    Tab.VISITING_THIS_WEEK,
    Tab.REVIEW_REQUIRED,
    Tab.CANDIDATE_NOW,
    Tab.TIME_SENSITIVE,
  ],
  [PatientTrackerStateId.ALL_PATIENTS_PAGE]: [Tab.WATCHLIST, Tab.ENROLLED, Tab.ALL],
  [PatientTrackerStateId.TEMPUS_NURSE_PAGE]: [Tab.SCREENING_QUEUE],
  [PatientTrackerStateId.MATERIALS_PAGE]: [
    Tab.VISITING_THIS_WEEK,
    Tab.REVIEW_REQUIRED,
    Tab.CANDIDATE_NOW,
    Tab.TIME_SENSITIVE,
    Tab.ENROLLED,
    Tab.ALL,
  ],
};

export enum MATCH_STATUS_FLAGS {
  NEW = 'New',
  URGENT = 'Urgent',
  COHORT_ON_HOLD = 'Cohort on hold',
  SITE_ON_HOLD = 'Site on hold',
  RESCREEN = 'Rescreen',
}

export const MATCH_STATUS_FLAG_BADGE_VARIANTS = {
  [MATCH_STATUS_FLAGS.NEW]: 1,
  [MATCH_STATUS_FLAGS.URGENT]: 8,
  [MATCH_STATUS_FLAGS.COHORT_ON_HOLD]: 4,
  [MATCH_STATUS_FLAGS.SITE_ON_HOLD]: 4,
  [MATCH_STATUS_FLAGS.RESCREEN]: 4,
};

export const getPatientCohortMatchWorkflowStateToDisplay = (state: PatientCohortMatchWorkflowState) => {
  switch (state) {
    case PatientCohortMatchWorkflowState.PENDING_TEMPUS_REVIEW:
      return 'Pending Tempus review';
    default:
      return convertSnakeToSentenceCase(state);
  }
};

export const relevantFieldsForStatus: Record<PatientCohortMatchWorkflowState, (keyof TrialMatchUpdates)[]> = {
  [PatientCohortMatchWorkflowState.INTERNAL_REVIEW]: [],
  [PatientCohortMatchWorkflowState.PENDING_TEMPUS_REVIEW]: [],
  [PatientCohortMatchWorkflowState.PENDING_SITE_REVIEW]: [],
  [PatientCohortMatchWorkflowState.WATCHLIST]: [
    'snoozeType',
    'snoozeDate',
    'nextVisitType',
    'nextVisitDate',
    'preconsentDate',
  ],
  [PatientCohortMatchWorkflowState.IMMINENT_CANDIDATE]: ['nextVisitType', 'nextVisitDate', 'preconsentDate'],
  [PatientCohortMatchWorkflowState.PURSUING_ACTIVATION]: ['nextVisitType', 'nextVisitDate', 'preconsentDate'],
  [PatientCohortMatchWorkflowState.CONSENTED]: [
    'patientConsentedDate',
    'dateConsentRecognizedByTempus',
    'nextVisitType',
    'nextVisitDate',
  ],
  [PatientCohortMatchWorkflowState.ENROLLED]: ['firstTreatmentDate'],
  [PatientCohortMatchWorkflowState.NO_LONGER_A_CANDIDATE]: ['reasonNotAMatch', 'reasonNotAMatchDetails'],
  [PatientCohortMatchWorkflowState.NOT_A_MATCH]: [],
  [PatientCohortMatchWorkflowState.PRE_MATCH]: [],
};

export const internalControlFields = [
  'tempusToPortalLatestPortalPatientUpdate',
  'biomarkers',
  'urgentFlag',
  'rescreenFlag',
  'noCredit',
  'dateRnClearedForNotification',
  'dateNotificationSentToSite',
  'patientConsentedDate',
  'dateConsentRecognizedByTempus',
  'firstTreatmentDate',
  'internalRnToScreeningRnNote',
  'preconsentDate',
  'preconsentRecognizedDate',
];

export enum COHORT_MATCH_CHANGE_SOURCE_COMPONENTS {
  MatchStatusUpdate = 'MatchStatusUpdate',
  PreconsentSection = 'PreconsentSection',
  WhyThisStatus = 'WhyThisStatus',
  InternalControls = 'InternalControls',
}

import { AccentPalette, GrayscalePalette } from '@tcl-boron-colors/colors';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  viewLink: {
    cursor: 'pointer',
    color: AccentPalette[100],
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: '4px',
    },
  },
  activityStatusContainer: {
    borderLeft: `1px solid ${GrayscalePalette[300]}`,
    padding: '12px 12px',
    marginBottom: '16px',
  },
  ul: {
    paddingLeft: '16px',
  },
  li: {
    listStyleType: 'disc',
    marginBottom: '8px',
  },
  listItemContainer: {
    display: 'flex',
  },
  updatesLeft: {
    flex: 1,
  },
  viewMore: {
    paddingBottom: '16px',
  },
  additionalDetails: {
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    padding: '16px 16px 16px 16px',
    marginTop: '5px',
    marginBottom: '16px',
    backgroundColor: 'white',
    boxShadow: '0px 1px 3px 1px rgba(0, 0, 0, 0.1)',
    borderRadius: '10px',
  },
  noteBody: {
    display: 'flex',
    flexDirection: 'column',
  },
  noteText: {
    marginBottom: '5px',
  },
  tooltipParent: {
    '& > div': {
      width: '100%',
    },
    '&:hover': {
      // to pad hover background without messing up other styles
      margin: '-4px -4px 0 -4px',
      padding: '4px 4px 0 4px',
      backgroundColor: '#E8F3FF',
    },
  },
});

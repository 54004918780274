import { GrayscalePalette } from '@tcl-boron-colors/colors';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  container: {
    height: '100%',
    padding: '24px',
    backgroundColor: GrayscalePalette[200],
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
    overflowY: 'auto',
    fontSize: '14px',
  },
  containerInner: {
    height: '100%',
  },
  detailGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '24px',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  label: {
    fontWeight: 700,
    marginBottom: '6px',
  },
  buttonLabel: {
    display: 'inline-block',
    marginLeft: '8px',
  },
  physicianContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  physicianDropdown: {
    width: '100%',
  },
  clearPhysician: {
    marginLeft: '10px',
    marginTop: '24px',
    cursor: 'pointer',
  },
});

import { Overlay } from '@tcl-argon-prefabs/overlay';
import { Alert } from '@tcl-boron-prefabs/alert';
import Loader from '@tcl-boron-prefabs/loader';
import React, { useEffect } from 'react';
import { Styles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryParams, StringParam } from 'use-query-params';

import { RootState } from '~/store';
import { StoreKeys } from '~/store/patientTrackerCommons/types';
import { creators as patientTrackerV2Creators } from '~/store/patientTrackerV2';
import { creators as patientTrackerV3Creators } from '~/store/patientTrackerV3';

import useStyles, { overlayOverrideClasses, overlayStyles } from './UpdateOverlay.styles';
import { UpdateOverlayContentV2 } from './UpdateOverlayContentV2';

type SyncUpdateOverlayProps = {
  storeKeys: StoreKeys;
  siteId: string;
};

export const UpdateOverlay = ({ storeKeys, siteId }: SyncUpdateOverlayProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const stateId = storeKeys.stateMapKey;
  const { data: timePatient, loading, error } = useSelector(
    ({ [storeKeys.store]: patientTracker }: RootState) => patientTracker[stateId].patient,
  );

  const [queryParams, setQueryParams] = useQueryParams(
    {
      timePatientId: StringParam,
    },
    { includeAllParams: true },
  );

  const timePatientId = queryParams.timePatientId;
  const patientTrackerCreators =
    storeKeys.store === 'patientTrackerV2' ? patientTrackerV2Creators : patientTrackerV3Creators;

  const refreshData = () => {
    if (timePatientId) {
      dispatch(patientTrackerCreators.getPatientTrackerRecord(stateId, siteId, timePatientId));
    }
  };

  const onCloseOverlayHandler = () => {
    setQueryParams({ timePatientId: undefined });
  };

  useEffect(() => {
    if (timePatientId) {
      refreshData();
    } else {
      dispatch(patientTrackerCreators.clearPatientTrackerRecord(stateId));
    }
  }, [timePatientId]);

  return (
    <Overlay
      style={overlayStyles as Styles}
      openDirection="right"
      isOpen={!!timePatientId}
      className={classes.overlay}
      classes={overlayOverrideClasses()}>
      {!timePatient && loading && (
        <div className={classes.overlayLoader}>
          <Loader size="large" />
          <div>Loading patient</div>
        </div>
      )}
      {error && (
        <Alert status="error" className={classes.overlayError}>
          {{
            title: 'Error',
            content: error,
          }}
        </Alert>
      )}
      {timePatient ? (
        <UpdateOverlayContentV2
          storeKeys={storeKeys}
          timePatient={timePatient}
          siteId={siteId}
          isOpen={!!timePatientId}
          onClose={onCloseOverlayHandler}
          onDataRefresh={() => refreshData()}
        />
      ) : null}
    </Overlay>
  );
};

export default UpdateOverlay;

import { SingleDatePicker } from '@tcl-boron-prefabs/single-date-picker';
import { SingleSelectDropdown } from '@tcl-boron-prefabs/single-select-dropdown';
import { SnoozeDataType } from '@tempus/stateflow-types';
import cn from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { DropdownOption } from 'tcl-v3/models';

import InputFieldHelperText from '~/components/UpdateOverlay/DatePickerHelperText';
import {
  dateRuleInFuture,
  dateRuleRequired,
  getChangedValues,
  getFormattedDateForPicker,
  FieldStatus,
  validateDateByRules,
} from '~/components/UpdateOverlay/utils';
import { TrialMatchUpdates } from '~/store/api/types';
import { getVisitTypeDropdownOptions } from '~/store/patientTrackerCommons/constants';
import { SnoozeData } from '~/store/patientTrackerCommons/types';
import { formatDateForDisplay } from '~/utils/misc';

import useStyles from './styles';

const watchlistUntilOpts = [
  { value: 'After next visit', label: 'After next visit' },
  { value: 'After a date', label: 'After a date' },
];

interface WatchlistProps {
  snoozeData: SnoozeData;
  nextVisitType: string;
  nextVisitDate: string;
  handleChanges: (changes: Partial<TrialMatchUpdates>) => void;
  setAreRequiredFieldsMissing: (areRequiredFieldsMissing: boolean) => void;
}

export const Watchlist: React.FC<WatchlistProps> = ({
  snoozeData,
  nextVisitType,
  nextVisitDate,
  handleChanges,
  setAreRequiredFieldsMissing,
}) => {
  const classes = useStyles();

  const watchlistUntilVal = snoozeData.type === SnoozeDataType.SpecificDatePassed ? 'After a date' : 'After next visit';

  const [selectedWatchlistUntil, setWatchlistUntil] = useState<DropdownOption>({
    value: watchlistUntilVal,
    label: watchlistUntilVal,
  });

  const [selectedWatchlistUntilDate, setWatchlistUntilDate] = useState<string | null>(
    snoozeData.type === SnoozeDataType.SpecificDatePassed ? getFormattedDateForPicker(snoozeData.date || '') : null,
  );

  const [selectedNextVisitType, setNextVisitType] = useState<DropdownOption | null>(
    nextVisitType ? { value: nextVisitType, label: nextVisitType } : null,
  );

  const [selectedNextVisitDate, setNextVisitDate] = useState<string | null>(nextVisitDate);
  const validateDate = (date: string) => validateDateByRules(date, [dateRuleRequired, dateRuleInFuture]);

  const [watchlistUntilDateValidation, setWatchlistUntilDateValidation] = useState(
    validateDate(selectedWatchlistUntilDate || ''),
  );

  const [nextVisitDateValidation, setNextVisitDateValidation] = useState(validateDate(selectedNextVisitDate || ''));

  const getInitialValues = () => {
    return {
      snoozeType: snoozeData.type,
      snoozeDate: snoozeData.type === SnoozeDataType.SpecificDatePassed ? new Date(snoozeData.date as string) : null,
      nextVisitType,
      nextVisitDate: formatDateForDisplay(nextVisitDate, true),
    };
  };

  useEffect(() => {
    const currentValues: Partial<TrialMatchUpdates> = {
      snoozeType:
        selectedWatchlistUntil?.value === 'After a date'
          ? SnoozeDataType.SpecificDatePassed
          : SnoozeDataType.NextVisitDatePassed,
      snoozeDate:
        selectedWatchlistUntil?.value === 'After a date' && selectedWatchlistUntilDate
          ? new Date(selectedWatchlistUntilDate)
          : snoozeData.type === SnoozeDataType.SpecificDatePassed
          ? snoozeData.date
            ? new Date(snoozeData.date)
            : undefined
          : null,
      nextVisitType:
        selectedWatchlistUntil?.value === 'After next visit' ? selectedNextVisitType?.value : nextVisitType,
      nextVisitDate:
        selectedWatchlistUntil?.value === 'After next visit'
          ? formatDateForDisplay(selectedNextVisitDate, true)
          : formatDateForDisplay(nextVisitDate, true),
    };

    const changedValues: Partial<TrialMatchUpdates> = getChangedValues(getInitialValues(), currentValues);
    handleChanges(changedValues);
    const selectedWatchlistUntilDateValidation = validateDate(selectedWatchlistUntilDate || '');
    setWatchlistUntilDateValidation(selectedWatchlistUntilDateValidation);
    const selectedNextVisitDateValidation = validateDate(selectedNextVisitDate || '');
    setNextVisitDateValidation(selectedNextVisitDateValidation);

    const areRequiredFieldsMissing =
      (currentValues.snoozeType === SnoozeDataType.SpecificDatePassed &&
        !selectedWatchlistUntilDateValidation.passed) ||
      (currentValues.snoozeType === SnoozeDataType.NextVisitDatePassed &&
        (!currentValues.nextVisitType || !selectedNextVisitDateValidation.passed));

    setAreRequiredFieldsMissing(areRequiredFieldsMissing);
  }, [selectedWatchlistUntil, selectedWatchlistUntilDate, selectedNextVisitType, selectedNextVisitDate]);

  return (
    <>
      <div className={classes.twoColumns}>
        <SingleSelectDropdown
          onChange={(opt) => setWatchlistUntil(opt!)}
          value={selectedWatchlistUntil}
          label="Watchlist until"
          options={watchlistUntilOpts}
          escapeClippingArea
          status={!selectedWatchlistUntil ? 'error' : 'default'}
        />
      </div>
      {selectedWatchlistUntil?.value === 'After a date' && (
        <div className={classes.twoColumns}>
          <div>
            <SingleDatePicker
              label="Date"
              className={classes.subsequentRow}
              status={watchlistUntilDateValidation.status}
              onChange={(date) => setWatchlistUntilDate(getFormattedDateForPicker(date.dateString))}
              value={{ dateString: moment.utc(selectedWatchlistUntilDate).format('MM/DD/YYYY') }}
            />
            <InputFieldHelperText
              status={watchlistUntilDateValidation.status}
              message={watchlistUntilDateValidation.message}
            />
          </div>
        </div>
      )}
      {selectedWatchlistUntil?.value === 'After next visit' && (
        <div className={cn(classes.subsequentRow, classes.twoColumns)}>
          <div>
            <SingleSelectDropdown
              onChange={(opt) => setNextVisitType(opt)}
              value={selectedNextVisitType}
              label="Next visit type"
              options={getVisitTypeDropdownOptions()}
              escapeClippingArea
              status={!selectedNextVisitType ? 'error' : 'default'}
            />
            <InputFieldHelperText
              status={!selectedNextVisitType ? FieldStatus.ERROR : FieldStatus.DEFAULT}
              message={'Type is required.'}
            />
          </div>

          <div>
            <SingleDatePicker
              label="Next visit date"
              status={nextVisitDateValidation.status}
              onChange={(date) => setNextVisitDate(getFormattedDateForPicker(date.dateString))}
              value={{ dateString: moment.utc(selectedNextVisitDate).format('MM/DD/YYYY') }}
            />
            <InputFieldHelperText status={nextVisitDateValidation.status} message={nextVisitDateValidation.message} />
          </div>
        </div>
      )}
    </>
  );
};

export default Watchlist;
